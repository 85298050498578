import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'


const ConcursoService = {
  
    get: (): Promise<any> => {
        return  Api.get(OperacoesWebAPI.Concurso).then((res) => { return res.data })
    },
    getbycpfjulgador : async (cpfjulgador:number):Promise<any> => {    
        return await Api.get(`/${OperacoesWebAPI.Concurso}/getbycpfjulgador?cpfjulgador=${cpfjulgador}`)
        .then((res:any)=>  {        
            return res.data});
    },
    getbyconcursoid : async (concursoID:number):Promise<any> => {    
        return await Api.get(`/${OperacoesWebAPI.Concurso}/getbyconcursoid?concursoid=${concursoID}`)
        .then((res:any)=>  {        
            return res.data});
    },
    
    postcomfiltros:async(filtros:any):Promise<any> => {
        return await Api.post(`/${OperacoesWebAPI.Concurso}/postcomFiltros`,filtros)
        .then((res:any)=>  {        
            return res.data});
    },

    post:async(concurso:any):Promise<any> => {
        return  await Api.post(`/${OperacoesWebAPI.Concurso}`, concurso)
        .then((res:any) => {
            return res.data});
    },
    put:async(concurso:any):Promise<any> => {
        return  await Api.put(`/${OperacoesWebAPI.Concurso}`, concurso)
        .then((res:any) => {
            return res.data});
    },
}

export  default ConcursoService;