import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Input, message, DatePicker, Row, Col, Select, ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import ModuloService from '../../../services/Modulo-Service/modulo-service';
import { moduloModalState, setModalEditar, setModalCadastrar } from '../../../features/slices/modulo/moduloModal-slice';
import {  } from '../../../features/slices/modulo/moduloModal-slice';
import { useAppDispatch, useAppSelector } from '../../../features/hooks';

const ModalModulo: React.FC<any> = (prop) => {
    let modulo = useAppSelector(moduloModalState);
    const dispatch = useAppDispatch();
    let visible = modulo.visibleModalCadastrar || modulo.visibleModalEditar;

    const [form] = Form.useForm();
    const [moduloID, setModuloID] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        if (modulo.visibleModalEditar) {

            form.setFieldsValue({
                nome: modulo.modulo.nome,
                ativo: modulo.modulo.ativo ? '1' : '0',
                navegarURL: modulo.modulo.navegarURL,
                possuiMenu: modulo.modulo.possuiMenu ? '1' : '0'

            })
            setModuloID(modulo.modulo.moduloID);
        }

    }, [modulo])

    const handleCancel = () => {
        if (modulo.visibleModalCadastrar) {
            dispatch(setModalCadastrar({ visibleModalCadastrar: false, visibleModalEditar: false }))
        }
        else
            dispatch(setModalEditar({ visibleModalCadastrar: false, visibleModalEditar: false }))
    };

    const onFinish = async (values: any) => {
        if (modulo.visibleModalCadastrar) {
            setLoading(true);
            ModuloService.post({
                moduloID: 0,
                nome: values.nome,
                ativo: values.ativo === '1' ? true : false,
                possuiMenu: values.possuiMenu === '1' ? true : false,
                navegarURL: values.navegarURL
            })
                .then((response) => {
                    message.success('Modulo salvo com sucesso')
                    dispatch(setModalCadastrar({ visibleModalCadastrar: false, visibleModalEditar: false }))
                })
                .catch((err) => {
                    message.error('Falha na requisição')

                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else if (modulo.visibleModalEditar) {

            ModuloService.put({
                moduloID: moduloID,
                nome: values.nome,
                ativo: values.ativo === '1' ? true : false,
                possuiMenu: values.possuiMenu === '1' ? true : false,
                navegarURL: values.navegarURL
            })
                .then((response) => {
                    message.success('Modulo salvo com sucesso')

                    ModuloService.getPorPerfil(sessionStorage.getItem('grupoID')).then((response) => {
                        sessionStorage.setItem('modulos', JSON.stringify(response.data));
                    })
                })
                .catch((err) => {
                    message.error('Falha na requisição')

                })
                .finally(() => {
                    setLoading(false);
                    setTimeout(() => {
                        dispatch(setModalEditar({ visibleModalCadastrar: false, visibleModalEditar: false }))
                    });
                })
        }

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Modal
            visible={visible}
            title="Modulo"
            onCancel={handleCancel}
            footer={null}
            className="NoneUpperCase"
        >
            <Form
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Nome"
                            name="nome"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, insira um nome!',
                                }
                            ]}
                        >
                            <Input placeholder="Digite o Nome do Modulo" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Ativo"
                            name="ativo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, informe este campo!',
                                }
                            ]}
                        >
                            <Select placeholder="Selecione">
                                <Select.Option value="1">Ativo</Select.Option>
                                <Select.Option value="0">Inativo</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Possui Menu"
                            name="possuiMenu"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, informe este campo!',
                                }
                            ]}
                        >
                            <Select placeholder="Selecione">
                                <Select.Option value="1">Sim</Select.Option>
                                <Select.Option value="0">Não</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="URL"
                            name="navegarURL"
                        >
                            <Input placeholder="Digite a URL" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button className="button-pre-cadastro" type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    );
}

export default ModalModulo;