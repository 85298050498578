import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';
import Style from './not-found-page.module.less';

const NotFoundPage: React.FC<any> = (prop) => {
    let location = useLocation();
    return (
        <>
            <div className={Style.Centro}>
                <div className={Style.Titulo}>
                    Página não encontrada
                </div>
                <br />
                <strong>A página {location.pathname} não existe</strong>
            </div>
        </>
    )
}

export default NotFoundPage;
