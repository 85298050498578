import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { CategoriaModel } from '../../../features/Models/criterio-model';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import { RespostaWebAPI } from '../../../features/Models/respostawebapi-model';
import CriterioService from '../../../services/Criterio-Service/criterio-service';
import { formatarFiltrosUnderlineParaObjeto } from '../../../services/Filtro-service/filtro-service';
import ConsultaComponent from '../../components/Consulta/consulta-component';
import { FiltroCategoria } from '../../components/FiltroCategoria/filtroCategoria-component';
import { FiltroConcurso } from '../../components/FiltroConcurso/filtroConcurso-component';
import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';

const CriteroPage: React.FC<any> = () => {

    const [dados, setDados] = useState<any[]>([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    let colunas: any = [

        {
            title: 'Nome', dataIndex: 'nome', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Pontuação', dataIndex: 'pontuacao', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Categoria', dataIndex: 'categoria', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
            render: (text: any, record: any) => (record?.categoria?.nome),
        },
        {
            title: 'Ativo', dataIndex: 'ativo', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
            render: (text: any, record: any) => (record?.ativo == true ? 'Sim' : 'Não'),
        },
        {
            title: 'Ações', dataIndex: 'acoes', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
            render: (text: any, record: any) => (<><Button type='primary'>Editar</Button><Button type='primary' style={{ marginLeft: '5px', width: '81px', backgroundColor: record?.ativo == true ? 'green' : 'red' }}>{record?.ativo == true ? 'Inativar' : 'Ativar'}</Button></>),
        },
        {
            title: 'Subcritério', dataIndex: 'subcriterio', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
            render: (text: any, record: any) => (<Button type='primary'>Cadastrar Subcritério</Button>),
        },
    ]


    const opcoesDeFiltros: FiltroConsultaModel[] = [
        {
            name: 'nome',
            label: 'Nome',
            componentChild: <Input placeholder="Informe o nome participante" ></Input>,
            required: false
        },
        {
            name: 'categoriaID', label: 'Categoria', componentChild: <></>,
            reactElementHtml: <FiltroCategoria
                key={24} label="Categoria" name="categoriaID" required={false} />, required: false
        },
    ]


    const pesquisar = (categoria: CategoriaModel) => {

        var obj = {
            nome: "", categoria
        };
        setLoading(true);
        CriterioService.postComFiltros(obj).then((response: RespostaWebAPI<any>) => {
            if (response.success) {
                setDados(response.data)
            }
            else {
                setDados([])
            }
        }).finally(() => setLoading(false))
    }

    const btnNovoCriteiro = () => {
        return <Button type='primary' style={{ marginBottom: '15px' }} onClick={() => setModal(true)} >Novo Critério</Button>
    }

    return (
        <>
            <Header />
            <ConsultaComponent
                colunasTable={colunas}
                dadosTable={dados}
                scrollTable={{ x: 100, y: 400 }}
                pageSizeTable={15}
                rowKeyTable={"criterioID"}
                filtros={opcoesDeFiltros}
                pesquisar={pesquisar}
                form={form}
                loading={loading}
                indicesFiltrosPreSelecionados={[0, 1]}
                legendaStatus={btnNovoCriteiro()}
            />
            <Footer />

        </>
    )
}

export default CriteroPage;

function setModal(arg0: boolean): void {
    throw new Error('Function not implemented.');
}

