import { AxiosResponse } from 'axios';
import { OperacoesWebAPI } from '../../utils/global';
import Api from '../index'
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';

const JulgadorService = {
    
    getJulgadores : async ():Promise<any> => {    
        return await Api.get(`/julgador/getjulgadores`).then((res:any)=>  {        
            return res.data});
    },

    postFiltroJulgador: async(model:any): Promise<any> => {
        return Api.post(`${OperacoesWebAPI.Julgador}/postFiltroJulgador`,model)
        .then((axiosResponse:AxiosResponse<RespostaWebAPI<any>>) => {        
            return axiosResponse.data
        })    
    },
   
}

export default JulgadorService;