import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse, AxiosError } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'
import { EPerfilCodigo } from '../../enum/perfil.enum';

interface ILoginModel {
    dominio: string,
    usuario: string,
    senha: string,
}
const UserService = {

    login: async (loginModel: ILoginModel): Promise<RespostaWebAPI<any>> => {
        return Api.post(`${OperacoesWebAPI.Login}/validarusuario?`, loginModel)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                if (axiosResponse.data.data.authenticated != false) {
                    sessionStorage.setItem("usuarioLogado", JSON.stringify(axiosResponse.data.data));
                }
                return axiosResponse.data
            })
            .catch((error) => {
                throw error.response

            })
    },
    getPermissoes: async (usuarioID: number): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Login}/getpermissoes?usuarioID=${usuarioID}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data;
            })
    },
    getByID: async (user: any): Promise<any> => {
        return await Api.get(`user/getbyid?userID=${user.userID}`)
    },
    getDominios: async (): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Login}/getdominios`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    ehComissaoJulgadora: (grupoID: string): boolean => {
        return grupoID == EPerfilCodigo.ComissaoJulgadoraDesenvolvimento.toString() ||
            grupoID == EPerfilCodigo.ComissaoJulgadoraHomologacao.toString() ||
            grupoID == EPerfilCodigo.ComissaoJulgadoraProducao.toString()
    },
    ehAdministrador: (grupoID: string): boolean => {
        return grupoID == EPerfilCodigo.AdministradorGeralDesenvolvimento.toString() ||
            grupoID == EPerfilCodigo.AdministradorGeralHomologacao.toString() ||
            grupoID == EPerfilCodigo.AdministradorGeralProducao.toString()
    }
}

export default UserService;
export type { ILoginModel }