import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';
import ConsultaComponent from '../../components/Consulta/consulta-component';
import { useState, useEffect } from 'react';
import ConcursoService from '../../../services/Concurso-Service/concurso-service'
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Spin } from 'antd';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import { FiltroSelectEnumComponent } from '../../components/FiltroSelectEnum/filtroSelectEnum-component';
import moment from 'moment';
import EnumeradorService from '../../../services/Enumerador-Service/enumerador-service';
import { convertCompilerOptionsFromJson } from 'typescript';
import FormItem from 'antd/lib/form/FormItem';
import { BsJustify } from 'react-icons/bs';
import MaskedInput from 'react-input-mask';
import Styles from './concurso-page.module.scss'


const ConcursoPage: React.FC<any> = () => {

  const [dados, setDados] = useState<any>()

  const [concursoSelecionado, setConcursoSelecionado] = useState<any>()

  const [loading, setLoading] = useState<boolean>(false)

  const [modal, setModal] = useState<boolean>(false)

  const [ehEdicao, setEhEdicao] = useState<boolean>()

  const [formModalCadastro] = Form.useForm()

  const [classificacaoPorNota, setClassificacaoPorNota] = useState<boolean>()

  useEffect(() => {
    EnumeradorService.get('ESituacaoConcurso').then((resp) => { setSelectEnumSituacao(resp.data) })
    ConcursoService.get().then((resp) => { setDados(resp.data) })
  }, [])

  const onFinish = (values: any) => {
    setLoading(true)

    if (ehEdicao == true) {
      let concurso = {
        concursoID: concursoSelecionado.concursoID,
        titulo: values.titulo,
        dataHoraInclusao: concursoSelecionado.dataHoraInclusao,
        dataInscricaoInicio: moment(values.dataInscricaoInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataInscricaoFim: moment(values.dataInscricaoFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataClassificacaoInicio: moment(values.dataClassificacaoInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataClassificacaoFim: moment(values.dataClassificacaoFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataAvaliacaoInicio: moment(values.dataAvaliacaoInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataAvaliacaoFim: moment(values.dataAvaliacaoFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataVotacaoPopularInicio: moment(values.dataVotacaoPopularInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataVotacaoPopularFim: moment(values.dataVotacaoPopularFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        situacao: values.situacao,
        classificacaoPorNota: values.classificacaoPorNota,
        quantidadeClassificadoPopular: values.quantidadeClassificadoPopular
      }

      ConcursoService.put(concurso).then(() => {
        setLoading(true)
        setModal(false)
        message.success('Concurso atualizado com sucesso')
        pesquisar({})
        formModalCadastro.resetFields()
      }).catch((error) =>
        message.error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor'))
        .finally(() => setLoading(false))
    } else {
      let concurso = {
        titulo: values.titulo,
        dataInscricaoInicio: moment(values.dataInscricaoInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataInscricaoFim: moment(values.dataInscricaoFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataClassificacaoInicio: moment(values.dataClassificacaoInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataClassificacaoFim: moment(values.dataClassificacaoFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataAvaliacaoInicio: moment(values.dataAvaliacaoInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataAvaliacaoFim: moment(values.dataAvaliacaoFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataVotacaoPopularInicio: moment(values.dataVotacaoPopularInicio, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        dataVotacaoPopularFim: moment(values.dataVotacaoPopularFim, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'),
        situacao: values.situacao,
        classificacaoPorNota: values.classificacaoPorNota,
        quantidadeClassificadoPopular: values.quantidadeClassificadoPopular
      }
      ConcursoService.post(concurso)
        .then(() => {
          setLoading(true)
          setModal(false)
          message
            .success('Concurso Cadastrado com Sucesso')
          pesquisar({})
          formModalCadastro.resetFields()
        })
        .catch((error) => {
          message
            .error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor');
        })
        .finally(() => setLoading(false))
    }

  }

  const [selectEnumSituacao, setSelectEnumSituacao] = useState<any>([])

  const pesquisarConcurso = (concursoID: number) => {
    setLoading(true)

    ConcursoService.getbyconcursoid(concursoID).then((resp) => {
      setLoading(true)
      setConcursoSelecionado(resp)
      onFill(resp)
    }).finally(() => setLoading(false))
  }

  const onFill = (concurso: any) => {        
    formModalCadastro.setFieldsValue({
      concursoID: concurso.concursoID,
      titulo: concurso.titulo,
      dataHoraInclusao: concurso.dataHoraInclusao,
      dataInscricaoInicio: moment(concurso.dataInscricaoInicio).format('DD/MM/YYYY'),
      dataInscricaoFim: moment(concurso.dataInscricaoFim).format('DD/MM/YYYY'),
      dataClassificacaoInicio: moment(concurso.dataClassificacaoInicio).format('DD/MM/YYYY'),
      dataClassificacaoFim: moment(concurso.dataClassificacaoFim).format('DD/MM/YYYY'),
      dataAvaliacaoInicio: moment(concurso.dataAvaliacaoInicio).format('DD/MM/YYYY'),
      dataAvaliacaoFim: moment(concurso.dataAvaliacaoFim).format('DD/MM/YYYY'),
      dataVotacaoPopularInicio: moment(concurso.dataVotacaoPopularInicio).format('DD/MM/YYYY'),
      dataVotacaoPopularFim: moment(concurso.dataVotacaoPopularFim).format('DD/MM/YYYY'),
      situacao: concurso.situacao,
      classificacaoPorNota: concurso.classificacaoPorNota,
      quantidadeClassificadoPopular: concurso.quantidadeClassificadoPopular
    })
    setClassificacaoPorNota(concurso.classificacaoPorNota)
  }


  let colunas: any = [
    {
      title: 'Título', dataIndex: 'titulo', width: 200, align: 'left',
    },
    {
      title: 'Data Inscrição Início', dataIndex: 'dataInscricaoInicio', width: 200, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataInscricaoInicio).format('DD/MM/YYYY'))
    },
    {
      title: 'Data Inscrição Fim', dataIndex: 'dataInscricaoFim', width: 170, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataInscricaoFim).format('DD/MM/YYYY'))
    },
    {
      title: 'Data Classificação Início', dataIndex: 'dataClassificacaoInicio', width: 170, align: 'center',
      render: (text: any, record: any) => ((moment(record?.dataClassificacaoInicio).format('DD/MM/YYYY')))
    },
    {
      title: 'Data Classificação Fim', dataIndex: 'dataClassificacaoFim', width: 170, align: 'center',
      render: (text: any, record: any) => ((moment(record?.dataClassificacaoFim).format('DD/MM/YYYY')))
    },
    {
      title: 'Data Avaliação Início', dataIndex: 'dataAvaliacaoInicio', width: 170, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataAvaliacaoInicio).format('DD/MM/YYYY'))
    },
    {
      title: 'Data Avaliação Fim', dataIndex: 'dataAvaliacaoFim', width: 170, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataAvaliacaoFim).format('DD/MM/YYYY'))
    },
    {
      title: 'Data Votação Popular Início', dataIndex: 'dataVotacaoPopularInicio', width: 170, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataVotacaocaoInicio).format('DD/MM/YYYY'))
    },
    {
      title: 'Data Votação Popular Fim', dataIndex: 'dataVotacaoPopularFim', width: 170, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataAvaliacaoFim).format('DD/MM/YYYY'))
    },
    {
      title: 'Data Inclusão', dataIndex: 'dataInclusao', width: 170, align: 'center',
      render: (text: any, record: any) => (moment(record?.dataInclusao).format('DD/MM/YYYY'))
    },
    {
      title: 'Situação', dataIndex: 'situacao', width: 170, align: 'center',
      render: (text: any, record: any) => (record?.situacao == 'EmAndamento' ? 'Em Andamento' : record?.situacao)
    },
    {
      title: 'Classificação por Nota', dataIndex: 'classificacaoPorNota', width: 170, align: 'center',
      render: (text: any, record: any) => (record?.classificacaoPorNota ? 'Sim' : 'Não')
    },
    {
      title: 'Quantidade de classificados popular', dataIndex: 'quantidadeClassificadoPopular', width: 170, align: 'center',
      render: (text: any, record: any) => (record?.quantidadeClassificadoPopular ? record?.quantidadeClassificadoPopular : 0 )
    },    
    {
      title: 'Ações', dataIndex: '', width: 120, align: 'center',
      render: (text: any, record: any) => (
        <Button type='primary' onClick={() => { setEhEdicao(true); setModal(true); pesquisarConcurso(record?.concursoID) }}>Editar</Button>
      ),
      fixed: 'right' as 'right',
    },

  ]

  const htmlLegendaStatus = (
    <Button type='primary' style={{ marginBottom: '15px' }} onClick={() => setModal(true)} >Novo Concurso</Button>
  )

  const [form] = Form.useForm();

  const opicoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'titulo',
      label: 'Título',
      componentChild: <Input placeholder="Informe o Título do Concurso" ></Input>,
      required: false
    },
    {
      name: 'situacao',
      label: 'Situação',
      componentChild: <></>,
      reactElementHtml: <FiltroSelectEnumComponent name="situacao" label="Situação" tipoEnumerador={"ESituacaoConcurso"} />,
      required: false
    },

  ]

  const pesquisar = (values: any) => {
    setLoading(true);
    ConcursoService.postcomfiltros(values).then((response) => {
      setDados(response.data)
    })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Header />
      <ConsultaComponent
        onExpand={"Concurso"}
        legendaStatus={htmlLegendaStatus}
        form={form}
        colunasTable={colunas}
        dadosTable={dados}
        filtros={opicoesDeFiltros}
        loading={loading}
        pageSizeTable={15}
        pesquisar={pesquisar}
        rowKeyTable={"consursoID"}
        scrollTable={{ x: 100, y: 400 }}
        indicesFiltrosPreSelecionados={[0, 1, 2, 3, 4, 5, 6]}
      />
      <Modal visible={modal} onCancel={() => { setModal(false); formModalCadastro.resetFields(); setEhEdicao(false) }}
        footer={[

        ]} title={ehEdicao == true ? 'Editar Concurso' : 'Novo Concurso'} width={'50%'}>
        <Spin spinning={loading}>
          <Form form={formModalCadastro} onFinish={onFinish}>
            <Form.Item name="titulo" wrapperCol={{ span: 24 }} label='Título' rules={[{ required: true, message: 'O campo Título é obrigatorio' }]}>
              <Input placeholder='Informe o Título do Concurso' />
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Form.Item name="dataInscricaoInicio" label='Data Inscrição Início' rules={[{ required: true, message: 'O campo Data Inscrição Início é obrigatorio' }]} style={{ width: "50%", marginRight: '10px' }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
              <Form.Item name="dataInscricaoFim" label='Data Inscrição Fim' rules={[{ required: true, message: 'O campo Data Inscrição Fim é obrigatorio' }]} style={{ width: "50%" }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput} ></MaskedInput>
              </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
              <Form.Item name="dataClassificacaoInicio" label='Data Classificação Início' rules={[{ required: true, message: "O campo Data Classificação Início é obrigatorio" }]} style={{ width: "50%", marginRight: '10px' }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
              <Form.Item name="dataClassificacaoFim" label='Data Classificação Fim' rules={[{ required: true, message: "O campo Data Classificação Fim é obrigatorio" }]} style={{ width: "50%" }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
              <Form.Item name="dataAvaliacaoInicio" label='Data Avaliação Início' rules={[{ required: true, message: "O campo Data Avaliação Início é obrigatorio" }]} style={{ width: "50%", marginRight: '10px' }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
              <Form.Item name="dataAvaliacaoFim" label='Data Avaliação Fim' rules={[{ required: true, message: "O campo Data Avaliação Fim é obrigatorio" }]} style={{ width: "50%" }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
              <Form.Item name="dataVotacaoPopularInicio" label='Data Votação Popular Início' rules={[{ required: true, message: "O campo Data Votação Popular Início é obrigatorio" }]} style={{ width: "50%", marginRight: '10px' }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
              <Form.Item name="dataVotacaoPopularFim" label='Data Votação Popular Fim' rules={[{ required: true, message: "O campo Data Votação Popular Fim é obrigatorio" }]} style={{ width: "50%" }}>
                <MaskedInput placeholder='__/__/____' mask="99/99/9999" className={Styles.maskedInput}></MaskedInput>
              </Form.Item>
            </div>
            <Row style={{ 'justifyContent': 'space-between' }}>
              <Col span={24} style={{ 'paddingLeft': '10px' }}>                
                  <Form.Item name="quantidadeClassificadoPopular" label={<div style={{wordBreak:'break-all'}}                  
                  >Quantidade de classificados para votação popular</div>}
                  rules={[{ required: true, message: "O campo é obrigatorio" }]}
                  >
                    <InputNumber  
                    title="Quantidade de classificados para votação popular"                  
                      onKeyDown={(event) => {
                        if (event.keyCode === 8 || event.keyCode === 9 ||
                          event.keyCode === 46 ? false : isNaN(Number(event.key))) {
                          event.preventDefault()
                        }
                      }}
                       ></InputNumber>
                  </Form.Item>                
              </Col>              
            </Row>
            <Row>
            <Col span={24} style={{ 'paddingLeft': '10px' }}>
                <div>
                  <Form.Item name="classificacaoPorNota" label='Classificação Por Nota' valuePropName="checked">
                    <Checkbox checked={classificacaoPorNota} > </Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row style={{ 'justifyContent': 'space-between' }}>
              <Col span={24}>
                <Form.Item name="situacao" label='Situação' rules={[{ required: true, message: 'Situação do Concurso é Obrigatoria' }]}>
                  <Select placeholder='Selecione a Situação do Concurso'>
                    {selectEnumSituacao.map((situacao: any, index: any) => {
                      return <Select.Option key={index} value={situacao.enum}> {situacao.texto}
                      </Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
        
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <FormItem>
                <Button onClick={() => { setModal(false); formModalCadastro.resetFields(); setEhEdicao(false) }} style={{ marginRight: '15px' }}>Voltar</Button>
              </FormItem>
              <FormItem>
                <Button htmlType='submit' type='primary' >{ehEdicao == true ? 'Salvar' : 'Cadastrar'}</Button>
              </FormItem>
            </div>
          </Form> </Spin>
      </Modal>
      <Footer />
    </>
  )
}


export default ConcursoPage;
