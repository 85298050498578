import Style from './login-page.module.less'
import logoFinal from '../../../assets/img/logoConcurso.png'
import { Form, Input, Button, Select, message, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import Footer from '../../components/Footer/footer-component';
import UserService, { ILoginModel } from '../../../services/User-Service/user-service';
import Token from '../../../services/Token-Service/token-service';
import { useAppDispatch } from '../../../features/hooks';
import ModuloService from '../../../services/Modulo-Service/modulo-service';
import logoGov from '../../../assets/img/Logo_governo_azul.svg'

interface LoginProps {
  location: any;
}
const LoginPage: React.FC<LoginProps> = (prop) => {

  const dispatch = useAppDispatch();
  let history = useHistory();
  const { Option } = Select;
  const [dominios, setDominios] = useState<any[]>();
  const [perfis, setPerfis] = useState<any[]>();
  const [usuarioLogado, setUsuarioLogado] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>();
  const [tokenAPI, setTokenAPI] = useState<any>();
  const [usuarioAutenticado, setUsuarioAutenticado] = useState<Boolean>();
  const [modalVisivel, setModalVisivel] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)
  const [loadingDominios, setLoadingDominios] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  let usuario: any;
  const [form] = Form.useForm();

  useEffect(() => {
    setDisabled(false);
    setUsuarioLogado(undefined);
    Token().then((response: any) => {
      sessionStorage.setItem('Token', response);
      setTokenAPI(response);
      getDominios();
    })
    if (prop.location.state != undefined) {
      message.error(prop.location.state.message)
    }
  }, [])

  const getDominios = () => {
    setLoadingDominios(true)

    UserService.getDominios().then((response) => setDominios(response.data))
      .finally(() => setLoadingDominios(false))
  }

  const onFinish = (values: any) => {
    if (usuarioLogado == undefined) {
      setLoading(true)
      UserService.login(values).then((response) => {
        if (response?.data.authenticated != false) {
          usuario = response.data;
          setUsuarioLogado(usuario);
          sessionStorage.setItem("usuarioLogado", JSON.stringify(usuario));
        }
        else {
          message.error(response.data.message)
        }
      })
        .catch(error => {
          message.error(error?.data?.error[0] != undefined ? error?.data?.error[0] : "Erro na conexão com o servidor");
        })
        .finally(() => {
          setLoading(false)
          if (usuario !== undefined) {
            UserService.getPermissoes(usuario.usuarioID)
              .then((response) => {
                setPerfis(response.data)
              });
            setDisabled(true);
            setUsuarioAutenticado(true);
          }

        })
    }
    else {
      let perfilSelecionado = perfis?.find(p => p.perfilID == values.perfil);
      sessionStorage.setItem("geoEstrutura", perfilSelecionado?.siglaGeoEstrutura);
      sessionStorage.setItem("grupo", perfilSelecionado?.nomeGrupo);
      sessionStorage.setItem("grupoID", perfilSelecionado?.grupoID);
      ModuloService.getPorPerfil(perfilSelecionado?.grupoID).then((response) => {
        sessionStorage.setItem('modulos', JSON.stringify(response.data));
      }).finally(() => {
        history.push(`/home`)
      });
    }
  }

  const onFinishFailed = (errorInfo: any) => {

  };

  const voltarAutenticar = () => {
    setUsuarioLogado(null);
    sessionStorage.clear();
    setPerfis([]);
    form.resetFields();
    setDisabled(false)
    setUsuarioAutenticado(false);
  }

  const onOk = () => {
    setModalVisivel(false)

  }

  const onCancel = () => {
    setModalVisivel(false)
  }

  const textoModal = <div style={{ textAlign: 'justify', padding: '20px' }}><p></p><p style={{ fontWeight: 'bold' }}>Instruções para solicitar o acesso a área administrativa</p>

    <p> Entrar em contato com técnico do sistema concurso de desenho (placeholder, será validado com usuário e analista negócio)</p>
    <p>Telefone: (xx) xxxxx-xxxx</p>
  </div>




  return (
    <>
      <header>
        <img className={Style.logo_msgov} src={logoGov} />
      </header>
      <div className={Style.wrapper}>
        <div className={`${Style.container_padrao} ${Style.logo}`}>
          <img className={Style.logo_img} src={logoFinal} />
        </div>
        <div className={`${Style.container_padrao} ${Style.login}`}>
          <Form
            form={form}
            onFinish={onFinish}
            className={Style.formulario}
            onFinishFailed={onFinishFailed}
          >
            <Spin
              spinning={loading}>
              <p className={Style.sisTitulo}>
                <span className={`${Style.titulo}`}>Concurso Educação Fiscal - ADM</span>
                {/* <span className={`${Style.titulo} ${Style.ms}`}>-MS</span>            */}
              </p>
              <label className={Style.formTitulo}>Autenticação do Usuário</label>
              <Form.Item
                className="input-container"
                name="usuario"
                rules={[
                  {
                    required: true,
                    message: 'Favor, informe seu usuário.',
                  },
                ]}
              >
                <Input
                  placeholder="Usuário"
                  className={Style.input}
                  disabled={disabled}
                  id="usuario"

                />
              </Form.Item>

              <Form.Item
                name="senha"
                className="input-container"
                rules={[
                  {
                    required: true,
                    message: 'Favor, informe sua senha.',
                  },
                ]}
              >
                <Input.Password
                  className={Style.input}
                  disabled={disabled}
                  id="senha"
                  placeholder="Senha"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Spin indicator={antIcon} spinning={loadingDominios}>
                <Form.Item
                  name="dominio"
                  className="input-container"
                  initialValue={""}
                  rules={[
                    {
                      required: true,
                      message: 'Favor, informe o domínio.',
                    },
                  ]}

                >
                  <Select className={Style.select} placeholder="Dominio" disabled={disabled} showSearch optionFilterProp="children">
                    <Option className={Style.opt} value="">Selecione um Dominio</Option>
                    {dominios?.map((dominio, key) => (
                      <Option className={Style.opt} key={key} value={dominio.value}>{dominio.text}</Option>
                    ))}
                  </Select>

                </Form.Item>
              </Spin>

              {
                usuarioAutenticado ?
                  <>
                    <Form.Item
                      name="perfil"
                      className="input-container"
                      initialValue={""}
                      rules={[
                        {
                          required: true,
                          message: 'Favor, informe o perfil.',
                        },
                      ]}

                    >
                      <Select className={Style.select} placeholder="Perfil">
                        <Option className={Style.opt} value="" >Selecione um Perfil</Option>
                        {perfis?.map((perfil, key) => (
                          <Option className={Style.opt} key={key} value={perfil.perfilID}>({perfil.siglaGeoEstrutura}){perfil.nomeGrupo}</Option>
                        ))}
                      </Select>

                    </Form.Item>
                    <Form.Item >
                      <div className={Style.formButton}>
                        <Button onClick={() => { voltarAutenticar() }} danger htmlType="button" id="selecionarPerfil" className={`${Style.warming_button} ${Style.login_btn}`}>
                          Voltar
                        </Button>
                        <Button type="primary" htmlType="submit" id="selecionarPerfil" className={`${Style.confirm_button} ${Style.login_btn}`}>
                          Selecionar Perfil
                        </Button>
                      </div>
                    </Form.Item>
                  </>
                  :
                  <>
                    <Form.Item >
                      <Button type="primary" htmlType="submit" id="logar" className={`${Style.confirm_button} ${Style.login_btn}`}>
                        Acessar
                      </Button>
                    </Form.Item>
                    <label className={Style.naoPossuoAcesso} onClick={() => setModalVisivel(true)}>Não possuo acesso</label>
                  </>
              }
            </Spin>
          </Form>

          <Modal
            bodyStyle={{ height: '40vh', overflowY: 'scroll', overflowX: 'scroll' }}
            title={null}
            className={Style.modalInstrucao}
            visible={modalVisivel}
            okText={"Ok"}
            cancelText={"Voltar"}
            maskClosable={true}
            closable={false}
            cancelButtonProps={{ danger: true }}
            onOk={onOk}
            onCancel={onCancel}>
            <div className={Style.btnCloseModal} onClick={() => setModalVisivel(false)}>x</div>
            {textoModal}
          </Modal>
        </div>
      </div>
      <Footer></Footer>
      <div className={Style.background}>
        <div className={Style.ripple}></div>
      </div>
    </>
  )
}
export default LoginPage;