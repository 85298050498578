import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import NotFoundPage from './secure/pages/Not-Found/not-found-page';
// import globalStyle from './styles/globalStyle.module.scss';
import ConsultaExemploPage from './secure/pages/Exemplo/consulta/consulta-exemplo-page';
import HomePage from './secure/pages/Home/home-page';
import SecurePage from './secure/secure-page';
import LoginPage from './public/pages/Login/login-page';
import ErrorPage from './secure/pages/Error/error-page';
// import consultaInscricaoPage from './secure/pages/Consulta-Inscricao/consulta-inscricao-page';
import InscricaoConsultarPage from './secure/pages/Inscricao/Consultar/inscricao-consultar-page';
import ModuloPage from './secure/pages/Modulo/modulo-page';
import PermissaoPage from './secure/pages/Permissao/permissao-page';
import InscricaoVisualizarPage from './secure/pages/Inscricao/Visualizar/inscricao-visualizar-page';
import SelecaoPage from './secure/pages/Seleção/selecao-page';
import ListagemAvaliacao from './secure/pages/Listagem Avaliacao/listagem-avaliacao';
import JulgadoresPage from './secure/pages/Julgadores/julgadores-page';
import DesenhosAvaliadosPage from './secure/pages/Desenhos Avaliados/desenhos-avaliados';
import AvalicaoPage from './secure/pages/Avaliacao/avaliacao-page';
import ModalidadePage from './secure/pages/Modalidade/modalidade-page';
import CriterioPage from './secure/pages/Criterio/criterio-page';
import Categorias from './secure/pages/Categorias/categorias';
import ConcursoPage from './secure/pages/Concurso/concurso-page';
import MotivoDesclassificacaoPage from './secure/pages/MotivoDesclassificacao/motivo-desclassificacao-page';

const Routes = (props) => {

    const isLoggedIn = () => !!sessionStorage.getItem('usuarioLogado');

    const SecuredRoute = ({ component: Component, ...rest }) => (

        <Route
            {...rest}
            render={props =>
                isLoggedIn() === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { message: "É necessario realizar o Login!" }
                        }}
                    />
                )
            }
        />
    );

    return (

        <BrowserRouter>   
                
                <Switch >
                    <Route exact path="/" component={LoginPage} />
                    <Route path="/error" component={ErrorPage} />                                        
                    <Route path="/login" component={LoginPage}/>                   
                    {/* <Route path="/consulta-exemplo" component={ConsultaExemploPage} /> */}
                    <SecuredRoute path="/inscricao/consultar" component={InscricaoConsultarPage} />
                    <SecuredRoute path="/admin"  component={SecurePage} />                    
                    <SecuredRoute path="/home" component={HomePage} />
                    <SecuredRoute path="/inscricao/visualizar/:id" component={InscricaoVisualizarPage} />   
                    <SecuredRoute path="/modulo" component={ModuloPage}/>
                    <SecuredRoute path="/listagem-avaliacao/:concursoID/julgador/:julgadorID" component={ListagemAvaliacao}/>
                    <SecuredRoute path="/desenhos-avaliados" component={DesenhosAvaliadosPage}/>
                    <SecuredRoute path="/permissao" component={PermissaoPage}/>
                    <SecuredRoute path="/julgadores" component={JulgadoresPage}/>
                    <SecuredRoute path="/selecao/:id" component={SelecaoPage}/>
                    <SecuredRoute path="/modalidade" component={ModalidadePage}/>               
                    <SecuredRoute path="/motivo-desclassificacao" component={MotivoDesclassificacaoPage}/>
                    <SecuredRoute path="/criterio" component={CriterioPage}/>           
                    <SecuredRoute path="/categorias" component={Categorias}/>
                    <SecuredRoute path="/avaliacao/desenho/:desenhoID" component={AvalicaoPage}/>
                    <SecuredRoute path="/concurso" component={ConcursoPage} />                  
                    {/* Route NotFound sempre em ultimo */}
                    <Route path="*" component={NotFoundPage} />
                                      
                   
                </Switch>
     
        </BrowserRouter>

    );
};

export default Routes;