import { Button, Form, Input, message, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';
import Consulta from '../../components/Consulta/consulta-component'
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import { FiltroConcurso } from '../../components/FiltroConcurso/filtroConcurso-component';
import MotivoDesclassificacaoService from '../../../services/MotivoDesclassificacao-Service/motivoDesclassificacao-service';
import TextArea from 'antd/lib/input/TextArea';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import { MotivoDesclassificacaoModel } from '../../../features/Models/motivoDesclassificacao-model';

const MotivoDesclassificacaoPage: React.FC<any> = () => {

  const [dados, setDados] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [formModal] = Form.useForm();
  const [ehEdicao, setEhEdicao] = useState<boolean>()
  const [motivoDesclassificacao, setMotivoDesclassificacao] = useState<Array<MotivoDesclassificacaoModel>>([]);
  const [concurso, setConcurso] = useState<any>([]);
  const [MotivoSelecionado, setMotivoSelecionado] = useState<any>()


  useEffect(() => {
    setLoading(true);
    ConcursoService.get().then((answ) => setConcurso(answ.data))
    MotivoDesclassificacaoService.get()
      .then((answ) => { setMotivoDesclassificacao(answ.data) })
      .finally(() => setLoading(false))
  }, [])



  let colunas: any = [

    {
      title: 'Descrição', dataIndex: 'descricao', width: 200, align: 'center',
      render: (text: any, record: any) => (record?.descricao)
    },
    {
      title: 'Concurso', dataIndex: 'concurso', width: 200, align: 'center',
      render: (text: any, record: any) => (record?.concurso?.titulo)
    },
    {
      title: 'Desclassificação por dados', dataIndex: 'desclassificacaoPorDados', width: 200, align: 'center',
      render: (text: any, record: any) => (record?.desclassificacaoPorDados == true ? 'Sim' : 'Não')
    },
    {
      title: 'Ativo', dataIndex: 'ativo', width: 200, align: 'center',
      render: (text: any, record: any) => (record?.ativo == true ? 'Sim' : 'Não')
    },
    {
      title: 'Ações', dataIndex: 'acoes', width: 200, align: 'center',
      render: (text: any, record: any) => <>
        <Button type='primary' style={{ marginBottom: '20px' }} onClick={() => { setEhEdicao(true); setModal(true); pesquisarMotivoDesclassificacao(record?.motivoDesclassificacaoID) }}>Editar</Button>
        {record?.ativo == true ?
          <>
            <Button onClick={() => (null)} style={{ width: '80px', background: 'red', color: 'white' }} >Inativar</Button>
          </>
          :
          <Button onClick={() => (null)} style={{ width: '80px', background: 'green', color: 'white' }} >Ativar</Button>
        }
      </>
    },
  ]


  const opcoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'descricao', label: 'Descrição', componentChild: <Input placeholder="Informe a descrição do motivo desclassificação" ></Input>,
      required: false
    },
    {
      name: 'concurso', label: 'Concurso', componentChild: <></>,
      reactElementHtml: <FiltroConcurso
        key={23} label="Concurso" name="concursoID" required={false} />, required: false
    },
    {
      name: 'desclassificacaoPorDados', label: 'Desclassificacao Por Dados',
      componentChild:
        <Select loading={loading} placeholder="Selecione" >
          <Select.Option value={true}>Sim</Select.Option>
          <Select.Option value={false}>Não</Select.Option>
          <Select.Option value={null}>Ambos</Select.Option>

        </Select>,
      required: false
    },

  ]


  const botaoCadastrar = (
    <Button type='primary' style={{ marginBottom: '20px' }} onClick={() => setModal(true)}>Novo Motivo de Desclassificação</Button>
  )

  const pesquisar = (values: any) => {

    setLoading(true);
    MotivoDesclassificacaoService.postComFiltro(values).then((response: any) => {
      if (response.success) {
        setMotivoDesclassificacao(response.data)
      } else {
        setMotivoDesclassificacao([])
      }
    })
      .finally(() => setLoading(false))
  }

  const pesquisarMotivoDesclassificacao = (motivoDesclassificacaoID: number) => {
    setLoading(true)
    MotivoDesclassificacaoService.get().then((resp) => {
      let motivoDesclassificacaoID = resp.motivoDesclassificacaoID.data
      onFill(motivoDesclassificacaoID)
      setMotivoSelecionado(resp.data)
    })
      .finally(() => setLoading(false))
  }

  const onFinish = (values: any) => {
    if (ehEdicao == true) {
      let motivoDesclassificacao = {
        motivoID: MotivoSelecionado.motivoDesclassificacoID,
        nome: values.descricacao,
        concursoID: values.concursoID,
        desclassificacaoPorDados: values.desclassificacaoPorDados,
        dataHoraInclusao: MotivoSelecionado.dataHoraInclusao,
        dataHoraAlteracao: MotivoSelecionado.dataHoraAlteracao,
        ativo: MotivoSelecionado.ativo,
      }
      MotivoDesclassificacaoService.put(motivoDesclassificacao).then(() => {
        setLoading(true)
        setModal(false)
        message.success('Concurso atualizado com sucesso')
        pesquisar({})
        formModal.resetFields()
      }).catch((error) =>
        message.error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor'))
        .finally(() => setLoading(false))
    }
    else {
      MotivoDesclassificacaoService.put(values)
        .then(() => {
          setLoading(true)
          setModal(false)
          message
            .success('Motivo de Desclassificação Cadastrado com Sucesso')
          pesquisar({})
          formModal.resetFields()
        })
        .catch((error) => {
          message
            .error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor');
        })
        .finally(() => setLoading(false))
    }
  }

  const onFill = (motivoDesclassificacao: any) => {
    formModal.setFieldsValue({
      descricao: motivoDesclassificacao.descricao,
      motivoDesclassificacaoID: motivoDesclassificacao.motivoDesclassificacaoID,
      concursoID: motivoDesclassificacao.concursoID,
    })
  }


  return (
    <>
      <Header />
      <Consulta
        legendaStatus={botaoCadastrar}
        colunasTable={colunas}
        dadosTable={motivoDesclassificacao}
        scrollTable={{ x: 100, y: 400 }}
        pageSizeTable={15}
        rowKeyTable={"inscricaoID"}
        filtros={opcoesDeFiltros}
        pesquisar={pesquisar}
        form={form}
        loading={loading}
        indicesFiltrosPreSelecionados={[0, 1, 2, 3]}
      />

      <Modal onCancel={() => { setModal(false); formModal.resetFields(); setEhEdicao(false) }} visible={modal} footer={[]} title={ehEdicao == true ? 'Editar Categoria' : 'Novo Motivo de Desclassificação'}>
        <Form onFinish={onFinish} form={formModal}>
          <Form.Item name='descricao' wrapperCol={{ span: 24 }} label='Descrição' rules={[{ required: true, message: 'Campo descrição é obrigatório' }]}>
            <TextArea rows={4} placeholder='Digite a descrição' />
          </Form.Item>
          <Form.Item name='concursoID' wrapperCol={{ span: 24 }} label='Concurso' rules={[{ required: true, message: 'Campo concurso é obrigatório' }]}>
            <Select placeholder='Selecione'>
              {concurso.map((concurso: any, index: any) => {
                return <Select.Option key={index} value={concurso.concursoID}>{concurso.titulo}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item>
              <Button onClick={() => { setModal(false); formModal.resetFields() }} style={{ marginRight: '15px' }}>Voltar</Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' type='primary' >{ehEdicao == true ? 'Editar' : 'Cadastrar'}</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Footer />
    </>
  )
}

export default MotivoDesclassificacaoPage;


