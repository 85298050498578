import { Col, Collapse, Form, Row, Spin, Image, Input, Button, Popconfirm, Modal, message, InputNumber, Card } from "antd"
import Styles from './avaliacao-page.module.less'
import { BaseSyntheticEvent, useEffect, useState } from "react";
import Header from "../../components/Header/header-component";
import Footer from "../../components/Footer/footer-component";
import DesenhoService from "../../../services/Desenho-Service/desenho-service";
import { useHistory, useParams } from "react-router-dom";
import { DesenhoModel } from "../../../features/Models/desenho-model";
import { Document, Page } from "react-pdf";
import { useStore } from "react-redux";
import { pdfjs } from "react-pdf";
// import {pdfjsWorker} from "pdfjs-dist/build/pdf.worker.entry.js"
import pdf from '../../../../public/pdf.worker'
import CriterioService from "../../../services/Criterio-Service/criterio-service";
import { CaretRightOutlined, EyeOutlined } from "@ant-design/icons";
import HistoricoProcessoService from "../../../services/HistoricoProcesso-Service/historicoProcesso-service";
import { validateLocaleAndSetLanguage } from "typescript";
import './pdf.css';

const AvalicaoPage: React.FC<any> = () => {

    const [loadingLegenda, setLoadingLegenda] = useState(false);
    const [desenho, setDesenho] = useState<DesenhoModel>()
    const [criterios, setCriterios] = useState<any[]>()
    const [avaliacaoCriterio, setAvaliacaoCriterio] = useState<Array<any>>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [numPages, setNumPages] = useState()
    const [modalDiarioVisible, setModalDiarioVisible] = useState<boolean>(false)
    const [modalDesenhoVisible, setModalDesenhoVisible] = useState<boolean>(false)
    const [diarioDeBordo, setDiarioDeBordo] = useState<any>()
    const [file, setFile] = useState('');
    const history = useHistory();
    let { desenhoID } = useParams<any>();
    let { julgadorID } = useParams<any>();
    const { Panel } = Collapse;
    const { Meta } = Card;
    const concurso = sessionStorage.getItem("concursoID")
    // const julgador = sessionStorage.getItem("julgadorID")
    var julgador = sessionStorage.getItem('julgadorID') || JSON.parse("usuarioLogado").julgadorID
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    useEffect(() => {
        HistoricoProcessoService.getByDesenhoID(desenhoID).then((resp) => {
            if (resp.data?.situacaoAvaliacaoENUM?.toUpperCase() == 'AVALIADO') {
                message.error('O desenho ja foi avaliado.')
                history.push(`/listagem-avaliacao/${concurso}/julgador/${julgador}`)
            }
        }).finally(() => {

        })

        DesenhoService.getByDesenhoID(desenhoID).then((resp) => {
            setDesenho(resp.data)
            setFile(`data:application/pdf;base64,${resp?.data?.base64}`)
            if (resp.data?.lote.julgadorID != parseInt(julgador)) {
                message.error('O desenho está atribuido a outro julgador.')
                history.push(`/listagem-avaliacao/${concurso}/julgador/${julgador}`)
            }
            else {
                CriterioService.getbycategoriaid(resp.data.inscricao.categoriaID).then((respCategoria: any) => {
                    setCriterios(respCategoria.data)
                    let avalCriterio = new Array<any>();
                    respCategoria.data?.map((c: any) => {
                        c.subCriterios?.map((s: any) => {
                            avalCriterio.push({
                                notaCriterio: 0,
                                criterioID: s.criterioID,
                                subCriterioID: s.subCriterioID,
                                desenhoID: desenhoID,
                                julgadorID: parseInt(julgador),
                            })
                        })
                    })
                    setAvaliacaoCriterio(avalCriterio);
                }).finally(() => setLoading(false))
            }

        }).finally(() => { setLoading(false) })

        DesenhoService.downloadDiarioBase64ById(desenhoID).then((resp) => {
            setDiarioDeBordo(resp.result)
        })

    }, [])

    const onDocumentLoadSuccess = (n: any) => {
        setNumPages(n._pdfInfo.numPages)
    }
    const finalizarAvaliacao = () => {
        DesenhoService.postAvaliarDesenho(avaliacaoCriterio).then((resp: any) => {
            if (resp.success) {
                message.success('Desenho avaliado com sucesso!')
            }
            else {
                message.error(resp.error[0])
            }
        }).finally(() => {
            history.push(`/listagem-avaliacao/${concurso}/julgador/${julgador}`)
        }).catch((data) => {
            message.error(data.response?.data.error[0] != undefined ? data.response.data.error[0] : "Falha na conexão com o servidor")
        });
    }

    const onChange = (key: string | string[]) => {
    };

    const calculaValorCriterio = (value: any) => {
        let somaCriterio = 0;
        avaliacaoCriterio.map(a => {
            if (a.criterioID == value.criterioID) {
                somaCriterio += a.notaCriterio;
            }
        })
        return somaCriterio;
    }

    const calculaNotaFinal = () => {
        let somaCriterio = 0;
        avaliacaoCriterio.map(a => {
            somaCriterio += a.notaCriterio;
        })
        return somaCriterio;
    }

    const onChangeNotaSubcriterio = (values: any, subcriterio: any) => {
        let i = avaliacaoCriterio.findIndex(a => a.subCriterioID == subcriterio.subCriterioID)
        if (i != -1) {
            setAvaliacaoCriterio(avaliacaoCriterio.splice(i, 1))
        }

        let avalCriterio = new Array<any>();
        avalCriterio.push({
            notaCriterio: parseFloat(values),
            criterioID: subcriterio.criterioID,
            subCriterioID: subcriterio.subCriterioID,
            desenhoID: desenhoID,
            julgadorID: parseInt(julgador),
        })

        setAvaliacaoCriterio(avaliacaoCriterio?.concat(avalCriterio))
        avalCriterio.concat(avaliacaoCriterio)
    };

    return (
        <>
            <div className={Styles.body}>
                <Header />
                <Row className={Styles.tela}>
                    <Col className={Styles.desenho} span={16}>
                        <Spin spinning={loading} >
                            <div className={desenho?.contentType == 'application/pdf' ? Styles.desenhoPDF : Styles.desenhoPNG}>
                                <Card
                                    hoverable
                                    cover={desenho?.contentType == 'application/pdf' ?
                                        <>
                                            <Document
                                                file={file}
                                                options={{ workerSrc: './pdf.worker.js' }}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                <Page onClick={() => setModalDesenhoVisible(true)} className={Styles.pdf} pageNumber={1} />
                                            </Document>

                                            <Modal className={Styles.modal} title="Desenho" visible={modalDesenhoVisible} onCancel={() => setModalDesenhoVisible(false)} onOk={() => setModalDesenhoVisible(false)}
                                                footer={null}>
                                                <div className={Styles.pdfContainer} id="pdfContainer" >
                                                    <Document
                                                        file={file}
                                                        options={{ workerSrc: './pdf.worker.js' }}
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                    >
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                </div>
                                            </Modal>
                                        </>

                                        :
                                        <Image
                                            preview={{ mask: <><EyeOutlined />&nbsp;Visualizar</> }}
                                            className={Styles.pdf}
                                            src={`data:${desenho?.contentType};base64,${desenho?.base64}`}
                                        />

                                    }
                                >
                                    <Meta title={<>
                                        <div className={Styles.infoDesenho}>
                                            <p><b>Modalidade:</b> {desenho?.modalidadeDesenho?.descricao}</p>
                                            <p><b>Categoria:</b> {desenho?.inscricao?.categoria == "ESTUDANTE" ? "Estudante" : "Sociedade Civil"}</p>
                                        </div>
                                        <div className={Styles.diarioDeBordo}>
                                            <Button onClick={() => setModalDiarioVisible(true)} className={Styles.btnDiario} style={{ height: '60px' }}>Visualizar Diário de Bordo</Button>
                                            <Modal className={Styles.modal} title="Diário de Bordo" visible={modalDiarioVisible} onCancel={() => setModalDiarioVisible(false)} onOk={() => setModalDiarioVisible(false)}
                                                footer={[
                                                    <Button
                                                        key="link"
                                                        type="primary"
                                                        loading={loading}
                                                        onClick={() => setModalDiarioVisible(false)}
                                                    >
                                                        OK
                                                    </Button>,
                                                ]}>
                                                <div className={Styles.pdfContainer} id="pdfContainer" >
                                                    <Document
                                                        file={`data:application/pdf;base64,${diarioDeBordo}`}
                                                        options={{ workerSrc: './pdf.worker.js' }}
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                    >
                                                        {Array.from(new Array(numPages), (el, index) => (
                                                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                                        ))}

                                                    </Document>
                                                </div>
                                            </Modal>
                                        </div>
                                    </>} />
                                </Card>
                            </div>
                        </Spin>

                    </Col>

                    <Col span={8}>
                        <Collapse defaultActiveKey={['title']} className={Styles.collapseCriterio}>
                            <Panel collapsible="header" showArrow={false} className={Styles.collapseSubcriterio} header="AVALIAÇÃO" key="title">
                                <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: 'white' }} />} onChange={onChange}>
                                    {
                                        criterios?.map((criterio: any) => {
                                            return <Panel className={Styles.headerCriterio} style={{ color: "white" }} header={<><div className={Styles.contentCriterio}><p className={Styles.linhaCriterio}>{criterio.nome + " (" + criterio.pontuacao + ")"}:</p> <InputNumber value={calculaValorCriterio(criterio)} className={Styles.inputCriterio} disabled type={"number"}></InputNumber></div></>} key={criterio.criterioID}>
                                                {
                                                    criterio.subCriterios.map((subcriterio: any) => {
                                                        return <><div className={Styles.contentSubcriterio}><p className={Styles.linhaCriterio}>{subcriterio.nome + " (" + subcriterio.pontuacao + ")"}:</p><InputNumber onChange={(e) => onChangeNotaSubcriterio(e, subcriterio)} name={subcriterio.nome} type={"number"} step={0.01} min={0} max={parseFloat(subcriterio.pontuacao)} defaultValue={0} controls={true} className={Styles.inputSubcriterio} ></InputNumber> </div></>
                                                    })
                                                }
                                            </Panel>
                                        })
                                    }
                                </Collapse>
                            </Panel>
                        </Collapse>

                        <Collapse defaultActiveKey={['title']} className={Styles.collapseNota}>
                            <Panel collapsible="header" showArrow={false} header="NOTA FINAL" key="title">
                                <><div className={Styles.contentNotaFinal}><p>Nota Final</p> <InputNumber disabled className={Styles.inputSubcriterio} value={calculaNotaFinal()} type={"number"}></InputNumber></div></>
                            </Panel>
                        </Collapse>

                        <Row className={Styles.buttons} align="middle" >
                            <Button onClick={() => { history.push('/listagem-avaliacao/' + sessionStorage.getItem("concursoID") + "/julgador/" + sessionStorage.getItem("julgadorID")) }} size="large" className={Styles.botao}> Voltar
                            </Button>
                            <Popconfirm placement="top" title={<div> <p>Atenção</p><p> Você deseja finalizar a avaliação do participante ?</p> </div>} okText="Finalizar" cancelText="Cancelar" onConfirm={(e) => { finalizarAvaliacao() }}>
                                <Button size="large" loading={loading} className={Styles.botao} htmlType="submit" type="primary"> Avaliar
                                </Button>
                            </Popconfirm>
                        </Row>
                    </Col>
                </Row>


                <Footer />
            </div>
        </>
    )

}

export default AvalicaoPage;
