import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, InputNumber, message, Spin, Modal, Checkbox } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import Styles from '../root.module.scss';
import { Document, Page } from 'react-pdf';



interface FormEndereco {
    parentHandle: any,
    inscricao: any
}

const FormEndereco: React.FC<FormEndereco> = (props: FormEndereco) => {
    let formRef = React.createRef<FormInstance>();
    const [loading, setLoading] = useState(false);
    const [erros, setErros] = useState<any>();
    const [errorStyle] = useState<Array<Object>>([{}]);
    const [form] = Form.useForm();
    // let responseSlice = useAppSelector(inscricaoState);
    // let inscricao = responseSlice.primeiraInscricao;
    const [mask1, setMask1] = useState("(99) 99999-9999");
    const [mask2, setMask2] = useState("(99) 99999-9999");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [base64Edital, setBase64Edital] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [disable, setDisable] = useState(false);
    const [visibleCheck, setVisibleCheck] = useState(false);
    const [check, setCheck] = useState(false);

    useEffect(() => {
        if (props.inscricao?.telefone1.replace("_", "").length === 10)
            setMask1("(99) 9999-9999");
        if (props.inscricao?.telefone2.replace("_", "").length === 10)
            setMask2("(99) 9999-9999");
        form.setFieldsValue({
            telefone1: props.inscricao?.telefone1,
            telefone2: props.inscricao?.telefone2,
            cep: props.inscricao?.cep,
            numero: props.inscricao?.numero,
            bairro: props.inscricao?.bairro,
            endereco: props.inscricao?.endereco,
            complemento: props.inscricao?.complemento,
            municipio: props.inscricao?.municipio,
            uf: props.inscricao?.uf
        })
    }, [])

    const onFinish = (values: any) => {
        props.parentHandle("3");
    };

    const voltarTab = () => {
        props.parentHandle("1");
    }

    const onReset = () => {
        formRef.current!.resetFields();
    };


    const onChangeCepMask = () => {

        var cep = formRef.current?.getFieldValue('cep')
        cep = cep.replace(/D/g, "")
        cep = cep.replace(/^(\d{5})(\d)/, "$1-$2")
        formRef.current?.setFieldsValue({ cep: cep })
    }


    const showModal = () => {
        setCheck(false)
        setIsModalVisible(true)
    };

    const onDocumentLoadSuccess = (n: any) => {
        setNumPages(n._pdfInfo.numPages)
    }

    const aceitar = () => {
        setIsModalVisible(false)
    };

    const handleCancel = () => {
        if (!check) {
            message.error("Leia o edital para realizar o aceite.")
        }
        else {
            setIsModalVisible(false);
        }
    };

    const onScrolll = (e: any) => {
        var target = e.target
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setVisibleCheck(true)
            setDisable(false)
        }
        else {
            setVisibleCheck(false)
            setDisable(true)
        }
    }

    const aceiteEdital = (e: any) => {
        setDisable(!e.target.checked)
        setCheck(e.target.checked)
    }

    return (
        <Spin spinning={loading}>
            <Form
                autoComplete="off"
                className={Styles.formulario}
                ref={formRef}
                form={form}
                name="control-ref"
                onFinish={onFinish}
                style={{ padding: '35px', marginBottom: '5em' }}
            >
                <fieldset >

                    <div className={Styles.groupItem}>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '50%' }}
                            name="telefone1"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Telefone 1"

                            shouldUpdate={true}
                        >
                            <MaskedInput className={Styles.inputDisabled} alwaysShowMask={false} mask={mask1}
                                onBlur={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask1("(99) 9999-9999");
                                    }
                                }}
                                onFocus={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask1("(99) 99999-9999");
                                    }
                                }}
                                disabled />
                        </Form.Item>

                        <Form.Item

                            style={{ marginTop: '-10px', width: '50%' }}
                            name="telefone2"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Telefone 2"
                        >

                            <MaskedInput className={Styles.inputDisabled} alwaysShowMask={false} mask={mask2}
                                onBlur={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask2("(99) 9999-9999");
                                    }
                                }}
                                onFocus={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask2("(99) 99999-9999");
                                    }
                                }}
                                disabled />
                        </Form.Item>

                    </div>

                    <div className={Styles.groupItem}>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '60%' }}
                            name="cep"
                            label="CEP"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            {/* {(props)=>{                          
                            return(
                                <Form.Item name="other">
                                <Input />
                              </Form.Item>
                            );
                        }} */}
                            <MaskedInput mask="99999-999" className={Styles.inputDisabled}
                                onChange={e =>
                                    onChangeCepMask()}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: '-10px', width: '40%' }}
                            name="numero"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Número"

                            initialValue="0"
                        >
                            <Input type="number" className={Styles.inputDisabled} disabled />
                        </Form.Item>

                    </div>

                    <Form.Item
                        style={{ marginTop: '-10px' }}
                        name="bairro"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Bairro"

                    >
                        <Input className={Styles.inputDisabled} disabled />
                    </Form.Item>

                    <Form.Item
                        style={{ marginTop: '-10px' }}
                        name="endereco"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Endereço"

                    >
                        <Input className={Styles.inputDisabled} disabled />
                    </Form.Item>

                    <Form.Item
                        style={{ marginTop: '-10px' }}
                        name="complemento"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Complemento"

                    >
                        <Input className={Styles.inputDisabled} disabled />
                    </Form.Item>

                    <div className={Styles.groupItem}>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '60%' }}
                            name="municipio"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Município"

                        >
                            <Input className={Styles.inputDisabled} disabled />
                        </Form.Item>

                        <Form.Item
                            style={{ marginTop: '-10px', width: '40%' }}
                            name="uf"
                            label="UF"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                        >
                            <Input className={Styles.inputDisabled} disabled />
                        </Form.Item>

                    </div>

                    {/* <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="aceiteEdital"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    valuePropName="checked"
                    rules={[{ validator: validarCheckbox, message: "O campo aceite é obrigatório", validateTrigger: "onChange" }]}
                >
                    <Checkbox disabled={check} checked={check} onChange={(e) => { showModal() }}> Termo do edital para aceite</Checkbox>
                </Form.Item> */}
                </fieldset>
                <div className={Styles.buttonsArea}>
                    <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                        Voltar
                    </Button>
                    <Button type="primary" className={Styles.proximo} htmlType="submit">
                        Próximo
                    </Button>
                </div>

            </Form>

            <Modal className={Styles.modal} title="Edital" visible={isModalVisible} onCancel={handleCancel}
                footer={[
                    <div className={Styles.buttonsAceite}>
                        <Checkbox disabled={!visibleCheck} onChange={(e) => { aceiteEdital(e) }}> Declaro ter ciência e estar de acordo com o termo acima</Checkbox>,
                        <Button
                            type="primary"
                            onClick={aceitar}
                            disabled={disable}
                        >
                            Aceitar
                        </Button>
                    </div>
                ]}>
                <div className={Styles.pdfContainer} id="pdfContainer" onScroll={(e) => { onScrolll(e) }}>

                    <Document
                        className={Styles.pdf}
                        file={base64Edital}
                        options={{ workerSrc: "./pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>

                </div>

            </Modal>
        </Spin>

    )
}

export default FormEndereco;
