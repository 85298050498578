import React from 'react';
import globalStyle from './styles/globalStyle.module.scss';
import Routes from './Routes';
import "./styles/App.less";


function App() {

  return (
    <div className={`App ${globalStyle.globalStyle}`}>
      <Routes />
    </div>
  );
}
export default App;
