import { AxiosResponse } from 'axios';
import { HistoricoProcessoModel } from '../../features/Models/historicoProcesso-model';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'

const MotivoDesclassificacaoService = {
    getByConcursoID: async (concursoID: number): Promise<any> => {
        return await Api.get(`/motivoDesclassificacao/getbyconcursoid?concursoID=${concursoID}`).then((res: any) => {
            return res.data
        });
    },
    getByMotivoDesclassificacaoPorDados: async (concursoID: number, porDados: boolean): Promise<any> => {
        return await Api.get(`/motivoDesclassificacao/getbymotivodesclassificacaopordados?concursoID=${concursoID}&porDados=${porDados}`).then((res: any) => {
            return res.data
        });

    },
    get: async (): Promise<any> => {
        return await Api.get(`/MotivoDesclassificacao`).then((res: any) => {
            return res.data
        });
    },
    post: async (motivoDesclassificacao: any): Promise<any> => {
        return await Api.post(`/MotivoDesclassificacao`, motivoDesclassificacao).then((res: any) => {
            return res.data
        });
    },
    put: async (motivoDesclassificacao:any): Promise<any> => {
        return await Api.put(`/MotivoDesclassificacao`, motivoDesclassificacao).then((res:any) => { return res.data })
    },
    delete: async (desenhoID: string): Promise<any> => {
        return await Api.delete(`historicoProcesso?id=${parseInt(desenhoID)}`).then((res) => { return res.data })
    },

     postComFiltro: async (filtros: any): Promise<any> => {
        return await Api.post(`MotivoDesclassificacao/postcomfiltros`, filtros).then((res) => { return res.data })
    },

}

export default MotivoDesclassificacaoService;