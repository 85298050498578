import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, notification, Row, Col, Popover, Modal, Checkbox, Tooltip, Empty } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import { Upload, Popconfirm, message, Spin, Table } from 'antd';
import { HistoryOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import style from './FormUpload-component.module.scss';
import Styles from '../root.module.scss'
import ModalidadeDesenhoService from '../../../../../services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import DesenhoService from '../../../../../services/Desenho-Service/desenho-service';
import { DesenhoModel } from '../../../../../features/Models/desenho-model'
import pdfPlaceHolder from '../../../../../assets/img/pdfPlaceHolder.png'

interface FormUpload {
    parentHandle: any,
    inscricao: any
}

const FormUpload: React.FC<FormUpload> = (props: FormUpload) => {

    const [form] = Form.useForm();
    const [fileDesenho, setFileDesenho] = useState<any>();
    const [fileDiarioDeBordo, setFileDiarioDeBordo] = useState<any>();
    const [loadingButtonUpload, setLoadingButtonUpload] = useState(false);
    const [loadingArquivos, setLoadingArquivos] = useState(false);
    const [modalidadeDesenho, setModalidadeDesenho] = useState<any>([]);
    const [modalidadeDesenhoSelecionada, setModalidadeDesenhoSelecionada] = useState<any>();
    const [typePermitido, setTypePermitido] = useState(false);
    let { id } = useParams<any>();
    const [inscricaoID, setInscricaoID] = useState(0);
    let history = useHistory();
    const [textoUpload, setTextoUpload] = useState<any>();
    const [textoDiario, setTextoDiario] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)
    const [dados, setDados] = useState<{ key: number, descricaoModalidade: string, diarioDeBordo: any, desenho: DesenhoModel }[]>([]);
    const [desenho, setDesenho] = useState<any>();
    const [arquivos, setArquivos] = useState<{ key: number, descricaoModalidade: string, diarioDeBordo: any, desenho: DesenhoModel }[]>([]);

    useEffect(() => {
        setLoading(true)
        DesenhoService.getDesenhosComBase64(props.inscricao?.inscricaoID)

            .then(resp => {
                let arquivosResponse: any[] = [];
                resp.data.map((d: any) => {

                    arquivosResponse.push({
                        key: d.desenhoID,
                        descricaoModalidade: d.modalidadeDesenho.descricao,
                        diarioDeBordo: d.diarioDeBordo,
                        desenho: d,

                    })

                })
                setDados(arquivosResponse)

            }).finally(() => {
                setLoading(false)
            })
    }, [])

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    let formRef = React.createRef<FormInstance>();

    const voltarTab = () => {
        props.parentHandle("3");

    }

    const textToolTipBaixar = <p>Clique no desenho para baixa-lo</p>

    const colunas = [
        {
            title: 'Modalidade',
            dataIndex: 'descricaoModalidade',
            key: 'descricaoModalidade',
            width: '70%'
        },
        {
            title: 'Desenho',
            dataIndex: '',
            key: 'x',
            render: (text: any, record: any) => (
                <>
                    {record.desenho.contentType == "application/pdf" ?
                        <>
                            <Tooltip placement='top' overlay={textToolTipBaixar}>
                                <img
                                    src={pdfPlaceHolder}
                                    width={100}
                                    height={100}
                                    style={{ marginLeft: '20%', cursor: 'pointer', width: '100px' }}
                                    onClick={(e: any) => { baixarArquivoDesenho(record.key, record.desenho.contentType, record.desenho.nomeArquivo) }} />
                            </Tooltip>
                        </> :
                        <>
                            <Tooltip placement='top' overlay={textToolTipBaixar}>
                                <img
                                    src={`data:${record.desenho.contentType};base64,${record.desenho.base64}`}
                                    width={100}
                                    height={100}
                                    onClick={(e: any) => { baixarArquivoDesenho(record.key, record.desenho.contentType, record.desenho.nomeArquivo) }}
                                    style={{ cursor: 'pointer', marginLeft: '20%' }} />
                            </Tooltip>
                        </>
                    }
                    <Button onClick={(e: any) => { baixarArquivoDiarioDeBordo(record.key, record.diarioDeBordo.contentType, record.diarioDeBordo.nomeArquivo) }} style={{ marginTop: '10px' }}>Baixar diário de bordo </Button>

                </>
            ),
            width: '30%'
        }
    ]

    const baixarArquivoDesenho = (desenhoID: number, contentType: string, nomeArquivo: string) => {
        setLoadingArquivos(true);

        DesenhoService.downloadDesenhoBase64ById(desenhoID)
            .then((response: any) => {

                if (response !== null && contentType != null) {
                    var link = document.createElement('a');
                    link.innerHTML = 'Download file';
                    link.download = nomeArquivo;
                    link.href = 'data:' + contentType + ';base64,' + response?.result
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err: any) => {
                message.error('Falha na requisição')

            }).finally(() => {
                setLoadingArquivos(false);
            })

    }

    const baixarArquivoDiarioDeBordo = (diarioID: number, contentType: string, nomeArquivo: string) => {
        setLoadingArquivos(true);

        DesenhoService.downloadDiarioBase64ById(diarioID)
            .then((response: any) => {

                if (response !== null && contentType != null) {
                    var link = document.createElement('a');
                    link.innerHTML = 'Download file';
                    link.download = nomeArquivo;
                    link.href = 'data:' + contentType + ';base64,' + response?.result
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err: any) => {
                message.error('Falha na requisição')

            }).finally(() => {
                setLoadingArquivos(false);
            })

    }

    const handleUploadDesenho = (e: any) => {
        setFileDesenho(e.fileList[0]);
    }

    const handleUploadDiarioDeBordo = (e: any) => {
        setFileDiarioDeBordo(e.fileList[0]);
    }

    const onDelete = async (record: any) => {
        setLoadingArquivos(true);
        DesenhoService.delete(record.key)
            .then(res => {

                setArquivos(state => state.filter((item) => { return item.key !== record.key })
                )
                message.success("Removido com sucesso");
            })
            .catch(err => {
                message.error("Falha na remoção");
            }).finally(() => {

                setLoadingArquivos(false);
            })

    }

    const onFinish = (values: any) => {

        // InscricaoService.post(values).then((res)=> {        
        // })
    };

    const selecionarModalidade = (modalidadeID: any) => {
        var modalidade = modalidadeDesenho.find((e: any) => e.modalidadeDesenhoID == modalidadeID)

        setModalidadeDesenhoSelecionada(modalidade);

        let ContentTypes = modalidade.contentTypes;

        let arquivo = ContentTypes.split("/");

        setTextoDiario(<span style={{ fontSize: '15px' }}>&#8226; Instrumento utilizado para registrar os acontecimentos mais importantes no desenvolvimento de
            suas atividades.  Diário de bordo, é um documento com reflexões e comentários sobre o andamento do trabalho que se está desenvolvendo. (Arquivo no formato PDF)</span>)
        setTextoUpload(<span style={{ fontSize: '15px' }}>&#8226; Formato de arquivo permitido: {arquivo[1]}</span>)

    };


    return (
        <Spin spinning={loading}>
            <div className={Styles.formulario} style={{ padding: '35px', marginBottom: '5em' }}>
                <h4><b>Envio dos desenhos e diário de bordo</b></h4>

                <hr />

                <Form

                    form={form}
                    {...layout}
                    ref={formRef}
                    name="control-ref" >

                    <Form.Item name="modalidadeDesenho"
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}

                        style={{ fontWeight: "bold" }}
                    >


                    </Form.Item>

                    {modalidadeDesenhoSelecionada ? <Row className={`${Styles.flex} ${Styles.justifyContentSpaceBetween} ${Styles.alignItemsCenter}`}>
                        <Col span={8}>
                            <Form.Item name="arquivo"
                                rules={[{ required: true, message: 'Por favor anexe uma arquivo com o desenho!' }]}
                            >
                                <Upload beforeUpload={(e => {
                                    var valid = e.type != "" && modalidadeDesenhoSelecionada.contentTypes.includes(e.type);
                                    if (!valid) {
                                        message.error(`${e.name} não esta em um formato permitido`)
                                        return Upload.LIST_IGNORE;
                                    }

                                    setTypePermitido(valid)
                                    return !valid;
                                })}
                                    showUploadList={fileDesenho ? true : false} onChange={(e) => { handleUploadDesenho(e) }} name="arquivo" maxCount={1} className={style.UploadName}>
                                    <Tooltip overlay={textoUpload} placement="bottomLeft">
                                        <Button ghost type="primary" icon={<UploadOutlined />}>Anexar desenho</Button>
                                    </Tooltip>
                                </Upload>
                            </Form.Item>
                        </Col>

                        <Col span={10} offset={6}>
                            <Form.Item
                                rules={[{ required: true, message: 'Por favor anexe uma arquivo com o diário de bordo!', }]}
                                name="diarioDeBordo" >
                                <Upload beforeUpload={e => {
                                    var valid = e.type != "" && e.type == 'application/pdf';
                                    if (!valid) {
                                        message.error(`${e.name} não esta em um formato permitido`)
                                        return Upload.LIST_IGNORE;
                                    }

                                    return !valid;
                                }}
                                    showUploadList={fileDiarioDeBordo ? true : false} onChange={(e) => handleUploadDiarioDeBordo(e)} name="diarioDeBordo" maxCount={1} className={style.UploadName}>
                                    <Tooltip overlay={textoDiario} placement="bottomLeft">
                                        <Button ghost type="primary" icon={<UploadOutlined />}>Anexar diário de bordo</Button>
                                    </Tooltip>
                                </Upload>
                            </Form.Item>
                        </Col>

                    </Row> : null}


                </Form>
                <Spin spinning={loadingArquivos}>
                    <Table
                        locale={{ emptyText: <Empty description="Sem registro!" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty> }}
                        dataSource={dados}
                        columns={colunas}
                        pagination={{ pageSize: 1 }}
                        scroll={{ x: 100 }}
                    />
                </Spin>
                <div className={Styles.buttonsArea}>
                    <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                        Voltar
                    </Button>

                    <Button loading={loading} onClick={() => { history.push('/inscricao/Consultar') }} type="primary" className={Styles.finalizar} htmlType="submit">
                        Concluir
                    </Button>

                </div>
                <br />

            </div>
        </Spin>
    )
}

export default FormUpload;
