import React, { useState, useEffect } from 'react';
import { Empty, Select } from 'antd';
import 'antd/dist/antd.css';

import FiltroConsulta from '../FiltroConsulta/filtroConsulta-component';
import ModalidadeDesenhoService from '../../../services/ModalidadeDesenho-Service/modalidadeDesenho-service';

interface FiltroModalidadeProps {
    removerFiltro?: any
    label: string,
    name: string,    
    required: boolean,
    key:number

}

export const FiltroModalidadePorConcurso: React.FC<FiltroModalidadeProps> = (props) => {
    const [modalidades, setModalidades] = useState<any>()
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      document.addEventListener("concursoID", (e: any) => {
        setLoading(true);
        ModalidadeDesenhoService.getByConcursoID(e?.detail?.concursoID).then((response) => {
          setModalidades(response.data)
        }).finally(() => setLoading(false))
      })
    }, [])

    return (
      <>
        <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={props.required} key={props.key}
          name={props.name}
          component={
            <Select
              notFoundContent={<Empty style={{ color: 'black' }}
                description={<p>Selecione algum valor no filtro <b>'Concurso'</b> para aparecer as opções desse filtro". Caso o concurso não tenha modalidades esse filtro não tera valores</p>} />}
              loading={loading} placeholder="Selecione">
              {modalidades?.map((item: any, index: number) => {
                return <Select.Option key={index} value={item?.modalidadeDesenhoID}>{item?.descricao}</Select.Option>
              })}
            </Select>
          } />
      </>
    );
  }