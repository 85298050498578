import React from 'react'

const ErrorPage: React.FC<any> = () => {
  return (
    <>
     
    </>
  )
}

export default ErrorPage;
