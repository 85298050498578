import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import { OperacoesWebAPI } from '../../utils/global';
import Api from '../index'

const ModalidadeDesenhoService = {

    getModalidadeDesenho: async (): Promise<any> => {
        return await Api.get(`/modalidadeDesenho`).then((res: any) => {
            return res.data
        });
    },
    getModalidadeDesenhoByConcursoID: async (concursoID: number) => {
        return await Api.get(`/modalidadeDesenho/getbyconcursoid?concursoID=${concursoID}`).then((res: any) => {
            return res.data
        })
            .catch((err) => { return false })
    },
    getByConcursoID: async (concursoID: number): Promise<any> => {
        return await Api.get(`/modalidadeDesenho/getbyconcursoid?concursoid=${concursoID}`).then((res: any) => {
            return res.data
        });
    },
    postcomfiltros: async (model: any): Promise<any> => {
        return Api.post(`ModalidadeDesenho/postcomfiltros`, model)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    
    post:async(modalidade:any):Promise<any> => {
        return  await Api.post('/ModalidadeDesenho', modalidade)
        .then((res:any) => {
            return res.data});
    },

    put:async(modalidade:any):Promise<any> => {
        return  await Api.put('/ModalidadeDesenho', modalidade)
        .then((res:any) => {
            return res.data});
    },

    getByModalidadeID: async (modalidadeID: number): Promise<any> => {
        return await Api.get(`/modalidadeDesenho/getbymodalidadeid?modalidadeID=${modalidadeID}`).then((res: any) => {
            return res.data
        });
    },
    
}



export default ModalidadeDesenhoService;