import { Tabs, Spin, Form, Input, Row, Col } from 'antd';
import Style from './secure-page.module.less'
import GlobalStyles from '../styles/globalStyle.module.scss'
import { Button } from 'antd';
import Header from './components/Header/header-component';
import Footer from './components/Footer/footer-component';

const SecurePage: React.FC<any> = () => {

  const { TabPane } = Tabs;

  return (
    <>
        <Header />
        <br />
        <div className={GlobalStyles.container}>
          <fieldset className={Style.fieldsetConsulta}>

            <Spin spinning={false}>
              <Tabs type="card">
                <TabPane tab="Tab 1" key="1">
                  <div className={Style.container}>
                    <Spin spinning={false}>

                      <Form
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        size="middle"
                      >
                        <Row >
                          <Col span={24}>
                            <Form.Item name="campo1" label="campo 1" >
                              <Input placeholder="Informe alguma informação"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name="campo2" label="campo 2" >
                              <Input placeholder="Informe alguma informação"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item name="campo3" label="campo 3" >
                              <Input placeholder="Informe alguma informação"
                              />
                            </Form.Item>
                          </Col>

                        </Row>

                        <br />
                        <Row >
                          <Col span={24}>
                            <Form.Item>
                              <Button type="primary" htmlType="submit">Salvar</Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>

                </TabPane>
                <TabPane tab="Tab 2" key="2">
                </TabPane>
                <TabPane tab="Tab 3" key="3">
                </TabPane>
              </Tabs>

            </Spin>

          </fieldset>

        </div>
        <Footer />
    </>

  );
}


export default SecurePage;