
 
 
 
 export enum EPerfilCodigo {
    AdministradorGeralDesenvolvimento = 1392,
    AdministradorGeralHomologacao = 1396,
    AdministradorGeralProducao = 1397,
    ComissaoOrganizadoraDesenvolvimento = 1399,
    ComissaoOrganizadoraHomologacao = 1400,
    ComissaoOrganizadoraProducao = 1401,
    ComissaoJulgadoraDesenvolvimento = 1402,
    ComissaoJulgadoraHomologacao = 1403,
    ComissaoJulgadoraProducao =	1404,

}

 const EPerfilDescricao = new Map<string, string>([
    ['AdministradorGeralDesenvolvimento', "Administrador Geral - Desenvolvimento"],
    ['AdministradorGeralHomologacao', "Administrador Geral - Homologação"],
    ['AdministradorGeralProducao', "Administrador Geral - Produção"],
    ['ComissaoOrganizadoraDesenvolvimento','Comissão Organizadora - Desenvolvimento'],
    ['ComissaoOrganizadoraHomologacao','Comissão Organizadora - Homologação'],
    ['ComissaoOrganizadoraProducao','Comissão Organizadora - Produção'],
    ['ComissaoJulgadoraDesenvolvimento','Comissão Julgadora - Desenvolvimento'],
    ['ComissaoJulgadoraHomologacao','Comissão Julgadora - Homologação'],
    ['ComissaoJulgadoraProducao','Comissão Julgadora - Produção	'],
    
]);


const PerfisArray = [
   
    {
        codigo:EPerfilCodigo['AdministradorGeralDesenvolvimento'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1392])
    },
    
    {
        codigo:EPerfilCodigo['AdministradorGeralHomologacao'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1396])
    },
    {
        codigo:EPerfilCodigo['AdministradorGeralProducao'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1397])
    },
    {        
        codigo:EPerfilCodigo['ComissaoOrganizadoraDesenvolvimento'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1399])
        
    },
    {
        codigo:EPerfilCodigo['ComissaoOrganizadoraHomologacao'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1400])                   
    },
    {        
        codigo:EPerfilCodigo['ComissaoOrganizadoraProducao'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1401])                                            
    },
    {
        codigo:EPerfilCodigo['ComissaoJulgadoraDesenvolvimento'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1402])                                                            
    },
    {
        codigo:EPerfilCodigo['ComissaoJulgadoraHomologacao'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1403])                                                                              
    },
    {
        codigo:EPerfilCodigo['ComissaoJulgadoraProducao'],
        nome:EPerfilDescricao.get(EPerfilCodigo[1404])                                                            
    },

    

   
]
export default  PerfisArray;