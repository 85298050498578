import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ModuloModel } from "../../Models/modulo-model";
import { RootState } from '../../store';

interface ModuloModal {
    modulo: ModuloModel,
    visibleModalEditar: boolean,
    visibleModalCadastrar: boolean
}

const initialState = {
    modulo: {
        moduloID: 0,
        nome: '',
        navegarURL: '',
        ativo: true,
        possuiMenu: true
    },
    visibleModalEditar: false,
    visibleModalCadastrar: false
} as ModuloModal;


export const moduloModalSlice = createSlice({
    name: "moduloModal",
    initialState,
    reducers: {

        setModalEditar: (state, action) => {
            state.modulo.moduloID = action.payload.moduloID;
            state.modulo.nome = action.payload.nome;
            state.modulo.navegarURL = action.payload.navegarURL;
            state.modulo.possuiMenu = action.payload.possuiMenu;
            state.modulo.ativo = action.payload.ativo;
            state.visibleModalEditar = action.payload.visibleModalEditar;

        },
        setModalCadastrar: (state, action) => {
            state.visibleModalCadastrar = action.payload.visibleModalCadastrar;
        },
    },
    extraReducers: {}


});

export const { setModalEditar, setModalCadastrar } = moduloModalSlice.actions;

export const moduloModalState = (state: RootState) => state.moduloModal;

export default moduloModalSlice.reducer;