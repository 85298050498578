import React from 'react'
import Styles from '../Footer/footer-component.module.scss'
// import logoSETDIG from '../../../assets/img/Logomarca_SETDIG_Branco.png'
// import logoGoverno from '../../../assets/img/GOV-MS-horizontal-BRANCA.png'
import logo from '../../../assets/img/logoConcurso.png'
import logoSetdig from '../../../assets/img/Logo SETDIG Negativa.svg'


const FooterComponent: React.FC<any> = () => {
  return (
    <>
      <footer className={Styles.footer}>
        <img src={logo} className={Styles.img}></img>
        <div className={Styles.logosGrid}>
          {/* <img src={logo1} className={Styles.img1}></img> */}
          <img src={logoSetdig} className={Styles.setdig}></img>
          {/* <img src={logoGoverno} className={Styles.img3}></img> */}
        </div>
      </footer>
    </>
  )
}

export default FooterComponent;
