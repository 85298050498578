import React, { useEffect, useState } from 'react'

import Styles from './visualizar.module.less'
import Header from '../../../components/Header/header-component';
import Footer from '../../../components/Footer/footer-component';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import FormUpload from './FormUpload/FormUpload-component';
import { Spin } from 'antd';
import FormContato from './FormContato/formContato-component';
import InscricaoService from '../../../../services/Inscricao-Service/inscricao-service';
import FormCategoria from './FormCategoria/formCategoria-component';
import FormDados from './FormDados/formDados-component';


const InscricaoVisualizarPage: React.FC<any> = () => {
  const [tab, setTab] = useState<string>("1");
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState(Object)
  let { id } = useParams<any>();
  const [inscricao, setInscricao] = useState(Object)


  useEffect(() => {
    InscricaoService.getByInscricaoID(id)
      .then((response) => {
        setInscricao(response.data)
      }).finally(()=>{
        setLoading(false)
      })

  }, [])

  const { TabPane } = Tabs;
  const handleTab = (childData: string) => {
    setTab(childData);
    return tab;
  }



  return (
    <>
          
      <Spin spinning={loading}>
        <div className={Styles.content}>
          <div className={Styles.tab} >
            <Tabs activeKey={tab} centered size="large" tabBarStyle={{ borderBottom: 'none' }} className={Styles.TabHeader} onChange={handleTab}>
              <TabPane tab="Dados" key="1" >
                <div className={Styles.content}>
                  <FormDados parentHandle={handleTab} inscricao={inscricao} />
                </div>
              </TabPane>
              <TabPane tab="Contato" key="2">
                <div className={Styles.content}>
                  <FormContato parentHandle={handleTab} inscricao={inscricao} />
                </div>
              </TabPane>
              <TabPane tab="Categoria" key="3">
                <div className={Styles.content}>
                  <FormCategoria parentHandle={handleTab} inscricao={inscricao} />
                </div>
              </TabPane>
              <TabPane tab="Desenhos" key="4" >
                <FormUpload parentHandle={handleTab} inscricao={inscricao} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Spin>
       
    </>
  )

}

export default InscricaoVisualizarPage