import React, { useState, useEffect } from 'react';
import { Empty, Select } from 'antd';
import 'antd/dist/antd.css';
import FiltroConsulta from '../FiltroConsulta/filtroConsulta-component';
import CategoriaService from '../../../services/Categoria-Service/categoria-service';

interface FiltroCategoriaPorConcursoProps {
    removerFiltro?: any
    label: string,
    name: string,    
    required: boolean,
    key:number
}


export const FiltroCategoriaPorConcurso: React.FC<FiltroCategoriaPorConcursoProps> = (props) => {
  const [categorias, setCategorias] = useState<any>()
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.addEventListener("concursoID", (e: any) => {
      setLoading(true);
      CategoriaService.getByConcursoID(e?.detail?.concursoID).then((response) => {
        setCategorias(response.data)
      }).finally(() => setLoading(false))
    })
  }, [])
  

  return (
    <>
      <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={props.required} key={props.key}
        name={props.name}
        component={
          <Select
            notFoundContent={<Empty style={{ color: 'black' }}
              description={<p>Selecione algum valor no filtro <b>'Concurso'</b> para aparecer as opções desse filtro. Caso o concurso não tenha categorias esse filtro não tera valores</p>} />}
            loading={loading} placeholder="Selecione"
            onSelect={(categoriaID: any) => {
              document.dispatchEvent(new CustomEvent('categoriaID', { detail: { categoriaID: categoriaID } }))
            }}

          >
            {categorias?.map((item: any, index: number) => {
              return <Select.Option key={index} value={item?.categoriaID}>{item?.nome}</Select.Option>
            })}
          </Select>
        } />
    </>
  );
  }