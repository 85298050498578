import Consulta from '../../components/Consulta/consulta-component'
import { useState, useEffect } from 'react';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import { Form, Button, Input, Popover, Tag, Collapse, Spin, Row, Col, Tooltip, message, Popconfirm } from 'antd';
import Header from '../../components/Header/header-component';
import Footer from '../../components/Footer/footer-component';
import { useHistory } from 'react-router-dom';
import { RespostaWebAPI } from '../../../features/Models/respostawebapi-model';
import DesenhoService from '../../../services/Desenho-Service/desenho-service';
import CrieterioService from '../../../services/Criterio-Service/criterio-service';
import { FiltroConcurso } from '../../components/FiltroConcurso/filtroConcurso-component';
import { FiltroModalidadePorConcurso } from '../../components/FiltroModalidadePorConcurso/filtroModalidadePorConcurso-component';
import { FiltroCategoriaPorConcurso } from '../../components/FiltroCategoriaPorConcurso/filtroCategoriaPorConcurso-component';
import { formatarFiltrosUnderlineParaObjeto, useFiltroServiceFactory } from '../../../services/Filtro-service/filtro-service';
import style from './desenhos-avaliados.module.css'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import moment from 'moment';
import ModalClassificarPorNotaComponent from '../../components/ModalClassificarPorNota/modalClassificarPorNota-component';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import { FiltroClassificadoPopularPorConcurso } from '../../components/FiltroClassificadoPopularPorConcurso/filtroClassificadoPopularPorConcurso-component';
import { createUnparsedSourceFile } from 'typescript';
import HistoricoProcessoService from '../../../services/HistoricoProcesso-Service/historicoProcesso-service';
import { BsCircleFill } from 'react-icons/bs';
import { InfoCircleOutlined } from '@ant-design/icons';

const DesenhosAvaliadosPage: React.FC<any> = (props) => {

  const { Panel } = Collapse;
  const [dados, setDados] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [colunas, setColunas] = useState<any>([]);
  const [modalClassificarPorNota, setModalClassificarPorNota] = useState(false);
  const [concursoSelecionado, setConcursoSelecionado] = useState<any>()
  const [visivelConfirm, setVisivelConfirm] = useState<boolean>(false)
  const [ehPeriodoVotacao, setEhPeriodoVotacao] = useState<boolean>(false)

  useEffect(() => {
    esperarSelecaoFiltroConcurso();
  }, [])

  useEffect(() => {
    esperarSelecaoFiltroCategoria();
  }, [concursoSelecionado])

  const esperarSelecaoFiltroCategoria = () => {
    document.addEventListener("categoriaID", (e: any) => {
      setDados([])
      concursoSelecionado?.classificacaoPorNota ? inserirColunasCriteriosPorCategoriaID(e?.detail?.categoriaID) : inserirColunasClassificacaoComissao();
    })
  }

  const validaDataVotacao = () => {
    var hoje = new Date();
    var dataFim = new Date(moment(concursoSelecionado?.dataVotacaoPopularFim).toString());
    var dataInicio = new Date(moment(concursoSelecionado?.dataVotacaoPopularInicio).toString());
    return dataFim > hoje && dataInicio < hoje;
  }

  const esperarSelecaoFiltroConcurso = () => {
    document.addEventListener("concursoID", (e: any) => {
      setLoading(true);
      ConcursoService.getbyconcursoid(e?.detail?.concursoID).then((response) => {
        setConcursoSelecionado(response)
      }).finally(() => setLoading(false))
    })
  }

  const htmlLegendaStatusClassificacaoPorNota = (
    <div className={style.status}>
      <Collapse
        collapsible="disabled"
        bordered={false}
        expandIconPosition='right'
        defaultActiveKey={['1']}
        style={{ borderRadius: '10px', backgroundColor: 'white', border: '1px solid #024135', width: '100%' }}>
        <Panel header={""} key="1">
          <Row>
            <ul className={style.listaHeader}>
              <li className={style.itemLista}>Classificado por Nota: <BsCircleFill className={style.ClassificadoPopularPorNota} />   </li>
            </ul>
          </Row>
        </Panel>
      </Collapse>
    </div>
  )

  const htmlLegendaStatusClassificacaoPorComissao = (
    <div className={style.status}>
      <Collapse
        collapsible="disabled"
        bordered={false}
        expandIconPosition='right'
        defaultActiveKey={['1']}
        style={{ borderRadius: '10px', backgroundColor: 'white', border: '1px solid #024135', width: '100%' }}>
        <Panel header={""} key="1">
          <Row>
            <ul className={style.listaHeader}>
              <li className={style.itemLista}>Classificado pela Comissão Organizadora: <BsCircleFill className={style.ClassificadoPopularPorComissao} />   </li>
            </ul>
          </Row>
        </Panel>
      </Collapse>
    </div>
  )

  const inserirColunasClassificacaoComissao = () => {
    let colunasSet = [
      {
        title: 'Nome do Participante', dataIndex: 'nome', width: 200, align: 'left',
        render: (text: any, record: any) => (record?.inscricao?.user?.nome),
        fixed: 'left' as 'left',
      },
      {
        title: 'CPF do Participante', dataIndex: 'cpf', width: 200, align: 'center',
        render: (text: any, record: any) => (formatarCpf(record?.inscricao?.user?.cpf)),
      },
      {
        title: 'Município do Participante', dataIndex: 'municipio', width: 200, align: 'left',
        render: (text: any, record: any) => (record?.inscricao?.municipio),
      },
      {
        title: 'Votos Popular', dataIndex: 'qtdVotos', width: 150, align: 'center',
        render: (text: any, record: any) => (record?.quantidadeVotos),
      },
      {
        title: 'Classificar/Desclassificar', dataIndex: 'acoes', width: 150, align: 'center',
        render: (text: any, record: any) => (
          <Popconfirm
            disabled={true}
            title={<p>Deseja realmente <b>{record.historicoProcessos[0]?.classificadoPopularPorComissao ? "Desclassificar" : "Classificar"}</b> o participante para Votação Popular?</p>}
            placement="top"
            okText="Sim"
            cancelText="Não"
            onConfirm={() => { setClassificadoPopularPorComissao(record) }}
            onCancel={() => { setVisivelConfirm(false) }}>
            {validaDataVotacao() ?
              <Tooltip trigger="hover" placement='bottomRight' title='Não é possível Classificar/Desclassificar dentro do período de Votação!'>
                <Button disabled={true} type='primary' danger={record.historicoProcessos[0]?.classificadoPopularPorComissao} onClick={() => { setVisivelConfirm(true) }} >{record.historicoProcessos[0]?.classificadoPopularPorComissao ? "Desclassificar" : "Classificar"}</Button>
              </Tooltip>
              :
              <Button disabled={true} type='primary' danger={record.historicoProcessos[0]?.classificadoPopularPorComissao} onClick={() => { setVisivelConfirm(true) }} >{record.historicoProcessos[0]?.classificadoPopularPorComissao ? "Desclassificar" : "Classificar"}</Button>
            }
          </Popconfirm>
        ),
        fixed: 'right' as 'right',
      }
    ]
    setColunas(colunasSet)
  }

  const inserirColunasCriteriosPorCategoriaID = (categoriaID: any) => {
    setLoading(true);
    CrieterioService.getbycategoriaid(categoriaID).then((response) => {
      let colunasSet = [
        {
          title: 'Classificação Avaliação', dataIndex: 'classificacao', width: 100, align: 'center',
          render: (text: any, record: any, index: any) => (record.historicoProcessos[0]?.posicaoAvaliacao + "º"),
          fixed: 'left' as 'left',
        },
        {
          title: 'Nome do Participante', dataIndex: 'nome', width: 200, align: 'left',
          render: (text: any, record: any) => (record?.inscricao?.user?.nome),
          fixed: 'left' as 'left',
        }
        , ...retornarColunasCriterios(response),
        {
          title: 'Total de Pontos', dataIndex: 'totalDePontos', width: 150, align: 'center',
          render: (text: any, desenho: any) => (desenho.avaliacaoCriterios
            .reduce((total: any, avaliacaoCriterio: any) => avaliacaoCriterio?.notaCriterio + total, 0)),
          fixed: 'right' as 'right',
        },
        {
          title: 'Votos Popular', dataIndex: 'qtdVotos', width: 150, align: 'center',
          render: (text: any, record: any) => (record?.quantidadeVotos),
          fixed: 'right' as 'right',
        },
      ]
      setColunas(colunasSet)

    }).finally(() => setLoading(false))
  }

  const setClassificadoPopularPorComissao = (record: any) => {
    HistoricoProcessoService.postClassificarDesclassificarParticipantePorComissao(record.historicoProcessos[0]?.historicoProcessoID).then(() => {
    }).finally(() => {
      let link = document.getElementById('pesquisar');
      link?.click();
    }).catch((error) =>
      message.error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor')
    )
  }

  const setStyleRowClassificado = (record: any) => {

    if (concursoSelecionado?.classificacaoPorNota && record?.historicoProcessos[0]?.classificadoPopularPorNota == true) {
      return style.tableRowClasificadoPopularPorNota
    }
    if (!concursoSelecionado?.classificacaoPorNota && record?.historicoProcessos[0]?.classificadoPopularPorComissao == true) {
      return style.tableRowClasificadoPopularPorComissao
    }
  }

  const retornarColunasCriterios = (criteriosResponse: any) => {
    return criteriosResponse?.data?.map((avaliacaoCriterioColuna: any) => {
      return {
        title: avaliacaoCriterioColuna?.nome, dataIndex: avaliacaoCriterioColuna?.nome,
        width: 200, align: 'center',
        render: (text: any, linhaTabela: any) => (
          <Popover title={<b>Composição da Nota:</b>}
            content={<div style={{ display: 'flex', flexDirection: 'column' }}>{retornarNotasSubcriterios(linhaTabela, avaliacaoCriterioColuna.criterioID)}</div>}>
            <div style={{ padding: 0, width: '100%', height: '100%', cursor: 'pointer' }}>
              {
                retornarNotaCriterio(linhaTabela, avaliacaoCriterioColuna.criterioID)
              }
            </div>
          </Popover>
        )
      }
    })
  }

  const retornarNotaCriterio = (desenho: any, criterioID: any) => {
    return desenho?.avaliacaoCriterios?.reduce((total: number, avaliacaoCriterioLinha: any) => {
      if (avaliacaoCriterioLinha?.criterioID == criterioID) {
        return avaliacaoCriterioLinha?.notaCriterio + total
      }
      return total
    }, 0)
  }

  const retornarNotasSubcriterios = (desenho: any, criterioID: any) => {
    if (desenho == null) return null;
    if (desenho.avaliacaoCriterios == null) return null;
    return desenho.avaliacaoCriterios.map((avaliacaoCriterio: any, index: number) => {
      if (avaliacaoCriterio?.criterioID == criterioID) {
        return <Tag key={index} style={{ width: "100%" }} color="geekblue"><>{avaliacaoCriterio?.subCriterio?.nome} : {avaliacaoCriterio?.notaCriterio}</></Tag>
      }
    })
  }

  const opcoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'desenho_inscricao_Concurso_ConcursoID', label: 'Concurso', componentChild: <></>,
      reactElementHtml: <FiltroConcurso
        key={23} label="Concurso" name="desenho_inscricao_Concurso_ConcursoID" required={true} />, required: false
    },
    {
      name: 'desenho_modalidadeDesenho_ModalidadeDesenhoID', label: 'Modalidade', componentChild: <></>,
      reactElementHtml: <FiltroModalidadePorConcurso
        key={24} label="Modalidade" name='desenho_modalidadeDesenho_ModalidadeDesenhoID' required={true} />, required: true
    },
    {
      name: 'desenho_inscricao_categoria1_categoriaID', label: 'Categoria', componentChild: <></>,
      reactElementHtml: <FiltroCategoriaPorConcurso key={25} label="Categoria"
        name='desenho_inscricao_categoria1_categoriaID' required={true} />, required: true
    },
    {
      name: concursoSelecionado != undefined ? concursoSelecionado.classificacaoPorNota ? `classificadoPopularPorNota` : `classificadoPopularPorComissao` : "autoName"
      , label: 'Classificado para votação popular', componentChild: <></>,
      reactElementHtml: <FiltroClassificadoPopularPorConcurso key={26}
        //     label={<>Classificado para votação popular &nbsp; <Tooltip trigger="click" placement='bottomRight' title="Caso o concurso esteja configurado para que os classificados sejam escolhidos pelo total de pontos da avaliação (nota), a opção 'sim' retornará os melhores avaliados. Caso contrário, a opção 'sim' retornará os classificados pela comissão organizadora.">            
        //     <InfoCircleOutlined style={{ fontSize: '15px' }} />
        //   </Tooltip> </>}
        label={"Classificado para votação popular "}
        name='' required={false} />, required: true
    },
    {
      name: 'desenho_inscricao_User_Nome',
      label: 'Nome do participante',
      componentChild: <Input placeholder="Informe o nome participante" ></Input>,
      required: false
    },
  ]

  const botoesClassificacaoPorComissao = [
    {
      botao:
        <Button style={{ marginLeft: 0 }}
          disabled={dados.length == 0}
          onClick={() => { imprimirTabelaClassificacaoPorComissao() }}
          htmlType="button">
          Imprimir tabela
        </Button>,

      colSpan: 3,
    },
  ]

  const botoesClassificacaoPorNota = [
    {
      botao:
        <Button style={{ marginLeft: 0 }}
          disabled={dados.length == 0}
          onClick={() => { imprimirTabelaClassificacaoPorNota() }}
          htmlType="button">
          Imprimir tabela
        </Button>,

      colSpan: 3,
    },
    {
      botao:
        <Button style={{ marginLeft: 0 }}
          onClick={() => { setModalClassificarPorNota(true) }}
          htmlType="button">
          Classificar por Nota
        </Button>,

      colSpan: 4,
    },
  ]

  const formatarCpf = (cpf: string) => {
    let cpfCortadoPrimeiraParte, cpfCortadoSegundaParte, cpfFormatado;
    cpfCortadoPrimeiraParte = cpf.slice(3, 6)
    cpfCortadoSegundaParte = cpf.slice(6, 9)
    cpfFormatado = "***." + cpfCortadoPrimeiraParte + "." + cpfCortadoSegundaParte + "-**";
    return cpfFormatado;
  }

  const imprimirTabelaClassificacaoPorNota = () => {
    if (dados.length == 0) {
      message.error('Não é possível imprimir a tabela sem dados.')
    } else {
      let dadosTabela: any[] = [];
      let totalVotos: number = 0;

      //Calcula o total de votos
      dados.map((d, index) => {
        totalVotos += d.quantidadeVotos
      })

      dados.map((d, index) => {
        dadosTabela.push({
          posicao: d.historicoProcessos[0]?.posicaoAvaliacao,
          classificacaoAvaliacao: d.historicoProcessos[0]?.posicaoAvaliacao + "º",
          nomeParticipante: d.inscricao?.user?.nome,
          cpfParticipante: formatarCpf(d.inscricao?.user?.cpf),
          totalPontos: d.avaliacaoCriterios.reduce((total: any, avaliacaoCriterio: any) => avaliacaoCriterio?.notaCriterio + total, 0).toFixed(3),
          aprovado: d.historicoProcessos[0]?.desenho?.inscricao?.concurso?.classificacaoPorNota ? d.historicoProcessos[0]?.classificadoPopularPorNota ? 'Sim' : 'Não' : d.historicoProcessos[0]?.classificadoPopularPorComissao ? 'Sim' : 'Não',
          quantidadeVotos: d.quantidadeVotos,
          percentualVotos: ((parseInt(d.quantidadeVotos) / totalVotos) * 100).toFixed(2) + "%",
        })
      })

      //Ordena por quantidade de votos e classifica
      dadosTabela.sort(function (a, b) { return b.quantidadeVotos - a.quantidadeVotos })

      dadosTabela.map((d, index) => {
        d.classificacaoVotacao = index + 1 + "º"
      })

      //Retorna a ordenação por avaliação
      dadosTabela.sort(function (a, b) { return parseInt(a.posicao) - parseInt(b.posicao) })

      const doc = new jsPDF({ orientation: 'landscape', });
      let dataImpressao = moment(new Date()).format('DD_MM_YYYY');
      let dataHoraImpressao = moment(new Date()).format('DD/MM/YYYY HH:mm');

      doc.setTextColor(100, 100, 100);
      //Cabeçalho
      let tituloDoc, cabecalhoDoc1, cabecalhoDoc2, cabecalhoDoc3;
      cabecalhoDoc1 = "Concurso: " + dados[0]?.historicoProcessos[0]?.desenho.inscricao.concurso.titulo
      cabecalhoDoc2 = "Modalidade: " + dados[0]?.historicoProcessos[0]?.desenho.modalidadeDesenho.descricao
      cabecalhoDoc3 = "Categoria: " + dados[0]?.historicoProcessos[0]?.desenho.inscricao.categoria1.nome
      doc.setFontSize(10);
      doc.text(cabecalhoDoc1, 15, 10, undefined, { fontSize: 2 });
      doc.text(cabecalhoDoc2, 15, 15, undefined, { fontSize: 2 });
      doc.text(cabecalhoDoc3, 15, 20, undefined, { fontSize: 2 });

      //Titulo
      tituloDoc = "Desenhos Avaliados e Classificados_" + dataImpressao
      doc.setFontSize(20);
      doc.text("Desenhos Avaliados e Classificados", doc.internal.pageSize.width - 200, 30);

      //Tabela
      moment.locale('pt-br');
      autoTable(doc, {
        margin: { vertical: 35 },
        theme: 'grid',
        styles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
        },
        head: [['Classificação Avaliação', 'Nome Participante', 'CPF Participante', 'Total Pontos', 'Aprovado para Votação', 'Classificação Votação', 'Votos Popular', 'Percentual de Votos']],
        body: dadosTabela,
        columns: [
          { dataKey: 'classificacaoAvaliacao' },
          { dataKey: 'nomeParticipante' },
          { dataKey: 'cpfParticipante' },
          { dataKey: 'totalPontos' },
          { dataKey: 'aprovado' },
          { dataKey: 'classificacaoVotacao' },
          { dataKey: 'quantidadeVotos' },
          { dataKey: 'percentualVotos' },
        ],
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 60, halign: 'left' },
          2: { cellWidth: 30 },
          3: { cellWidth: 30 },
          4: { cellWidth: 30 },
          5: { cellWidth: 30 },
          6: { cellWidth: 30 },
          7: { cellWidth: 30 },
        },

      })

      //Footer
      doc.setFontSize(15);
      doc.text('Documento impresso ' + dataHoraImpressao, doc.internal.pageSize.width - 200, doc.internal.pageSize.height - 5, undefined, { fontSize: 5 });

      doc.save(`${tituloDoc}.pdf`);
    }
  }

  const imprimirTabelaClassificacaoPorComissao = () => {

    if (dados.length == 0) {
      message.error('Não é possível imprimir a tabela sem dados.')
    } else {
      let dadosTabela: any[] = [];
      let totalVotos: number = 0;

      //Calcula o total de votos
      dados.map((d, index) => {
        totalVotos += d.quantidadeVotos
      })

      dados.map((d, index) => {

        dadosTabela.push({
          nomeParticipante: d.inscricao?.user?.nome,
          cpfParticipante: formatarCpf(d.inscricao?.user?.cpf),
          municipioParticipante: d.inscricao?.municipio,
          aprovado: d.historicoProcessos[0]?.desenho?.inscricao?.concurso?.classificacaoPorNota ? d.historicoProcessos[0]?.classificadoPopularPorNota ? 'Sim' : 'Não' : d.historicoProcessos[0]?.classificadoPopularPorComissao ? 'Sim' : 'Não',
          quantidadeVotos: d.quantidadeVotos,
          percentualVotos: totalVotos == 0 ? 0 : ((parseInt(d.quantidadeVotos) / totalVotos) * 100).toFixed(2) + "%",
        })
      })

      //Ordena por quantidade de votos e classifica
      dadosTabela.sort(function (a, b) { return b.quantidadeVotos - a.quantidadeVotos })

      dadosTabela.map((d, index) => {
        d.classificacaoVotacao = index + 1 + "º"
      })

      const doc = new jsPDF({ orientation: 'landscape', });
      let dataImpressao = moment(new Date()).format('DD_MM_YYYY');
      let dataHoraImpressao = moment(new Date()).format('DD/MM/YYYY HH:mm');

      doc.setTextColor(100, 100, 100);
      //Cabeçalho
      let tituloDoc, cabecalhoDoc1, cabecalhoDoc2, cabecalhoDoc3;
      cabecalhoDoc1 = "Concurso: " + dados[0]?.historicoProcessos[0]?.desenho.inscricao.concurso.titulo
      cabecalhoDoc2 = "Modalidade: " + dados[0]?.historicoProcessos[0]?.desenho.modalidadeDesenho.descricao
      cabecalhoDoc3 = "Categoria: " + dados[0]?.historicoProcessos[0]?.desenho.inscricao.categoria1.nome
      doc.setFontSize(10);
      doc.text(cabecalhoDoc1, 15, 10, undefined, { fontSize: 2 });
      doc.text(cabecalhoDoc2, 15, 15, undefined, { fontSize: 2 });
      doc.text(cabecalhoDoc3, 15, 20, undefined, { fontSize: 2 });

      //Titulo
      tituloDoc = "Desenhos Avaliados e Classificados_" + dataImpressao
      doc.setFontSize(20);
      doc.text("Desenhos Avaliados e Classificados", doc.internal.pageSize.width - 200, 30);

      //Tabela
      moment.locale('pt-br');
      autoTable(doc, {
        margin: { vertical: 35 },
        theme: 'grid',
        styles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
        },
        head: [['Nome Participante', 'CPF Participante', 'Município Participante', 'Aprovado para Votação', 'Votos Popular', 'Percentual de Votos']],
        body: dadosTabela,
        columns: [
          { dataKey: 'nomeParticipante' },
          { dataKey: 'cpfParticipante' },
          { dataKey: 'municipioParticipante' },
          { dataKey: 'aprovado' },
          { dataKey: 'quantidadeVotos' },
          { dataKey: 'percentualVotos' },
        ],
        columnStyles: {
          0: { cellWidth: 90, halign: 'left' },
          1: { cellWidth: 40 },
          2: { cellWidth: 40, halign: 'left' },
          3: { cellWidth: 30 },
          4: { cellWidth: 30 },
          5: { cellWidth: 30 },
        },

      })

      //Footer
      doc.setFontSize(13);
      doc.text('Impresso ' + dataHoraImpressao, doc.internal.pageSize.width - 180, doc.internal.pageSize.height - 5, undefined);

      doc.save(`${tituloDoc}.pdf`);
    }
  }

  const pesquisar = (values: any) => {
    formatarFiltrosUnderlineParaObjeto(values);
    setLoading(true);
    DesenhoService.postFiltroDesenhosAvaliados(values).then((response: RespostaWebAPI<any>) => {
      if (response.success) {
        if (!concursoSelecionado?.classificacaoPorNota) {
          response.data.sort(function (a: any, b: any) { return parseInt(b.quantidadeVotos) - parseInt(a.quantidadeVotos) })
        }
        setDados(response.data)
      }
      else {
        setDados([])
      }
    }).finally(() => setLoading(false))
  }

  return (
    <>
      <Header />
      <Consulta
        colunasTable={colunas}
        dadosTable={dados}
        scrollTable={{ x: 100, y: 400 }}
        pageSizeTable={15}
        legendaStatus={concursoSelecionado?.classificacaoPorNota ? htmlLegendaStatusClassificacaoPorNota : htmlLegendaStatusClassificacaoPorComissao}
        rowKeyTable={"inscricaoID"}
        filtros={opcoesDeFiltros}
        pesquisar={pesquisar}
        form={form}
        loading={loading}
        botoes={concursoSelecionado?.classificacaoPorNota ? botoesClassificacaoPorNota : botoesClassificacaoPorComissao}
        rowClassName={(record: any) => {
          return setStyleRowClassificado(record);
        }}
        indicesFiltrosPreSelecionados={[0, 1, 2, 3, 4]}
      />
      <Footer />
      <ModalClassificarPorNotaComponent
        visivel={modalClassificarPorNota}
        setVisivelFalse={() => setModalClassificarPorNota(false)} />
    </>
  )
}

export default DesenhosAvaliadosPage;