import React, { ReactElement, useState } from 'react';
import { Row, Col, Empty } from 'antd';
import { Table, Button, Typography, Form, Select } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined, SearchOutlined, StopOutlined, UserOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import Styles from './consulta.module.less'
import GlobalStyles from '../../../styles/globalStyle.module.scss'
import { useFiltroServiceFactory } from '../../../services/Filtro-service/filtro-service';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';

interface ConsultaComponentProps {
    colunasTable: any
    dadosTable: any
    scrollTable: any
    pageSizeTable: any
    rowKeyTable: any
    filtros: FiltroConsultaModel[]
    pesquisar: any
    loading: boolean,
    indicesFiltrosPreSelecionados?: any[]
    form: any
    botoes?: { botao: any, colSpan: number }[]
    legendaStatus?: any
    expandable?: any
    onExpand?:any
    rowClassName?:any
}

const ConsultaComponent: React.FC<ConsultaComponentProps> = (props) => {

    const { Text } = Typography;
    const filtroService = useFiltroServiceFactory(props.form, props.filtros, props.indicesFiltrosPreSelecionados);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

    const onTableRowExpand = (expanded: any, record: any) => {
        const keys = [];
        if(props.onExpand == 'Julgador'){
            if (expanded) {
                keys.push(record.julgadorID); 
            }
        }
        if(props.onExpand == 'Inscricao'){
            if (expanded) {
                keys.push(record.inscricaoID); 
            }
        }
      
        setExpandedRowKeys(keys);
    }

    return (
        <div>
            <div className={GlobalStyles.container}>
                <fieldset className={Styles.fieldset_consulta}>
                    <Row align="middle" justify="center"  >
                        <Col span={24} >
                            <Form name="consulta" form={props.form} onFinish={props.pesquisar}
                                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                            >
                                <Row >
                                    {filtroService.obterFiltrosSelecionados()?.map((filtro: FiltroConsultaModel) => { return filtro?.reactElementHtml })}
                                    <Col span={6}>
                                        <Row>
                                            <Col span={20}>
                                                <Form.Item label="Filtros" name="filtroSelecionado">
                                                    <Select placeholder="Selecione" style={{ width: '100%' }}>
                                                        {filtroService.obterFiltros()?.map((item: any, index: number) => {
                                                            return <Select.Option key={index} value={item.name}>{item.label}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4} >
                                                <Form.Item label="&nbsp;">
                                                    <Button type="dashed" icon={<PlusOutlined />}
                                                        onClick={() => filtroService.adicionarFiltroSelecionado()} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={3}>
                                                <Button id="pesquisar" style={{ marginRight: 8 }} htmlType="submit" type="primary" icon={<SearchOutlined />}> Pesquisar </Button>
                                            </Col>
                                            <Col span={3}>
                                                <Button style={{ marginLeft: 0 }} onClick={() => { props.form.resetFields() }} danger htmlType="button">
                                                    Limpar Filtros
                                                </Button>
                                            </Col>
                                            {props.botoes?.map((item, index) => {

                                                return <Col key={index} span={item?.colSpan}>
                                                    {item?.botao}
                                                </Col>
                                            })
                                            }

                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    

                </fieldset>
                <br />
                {props.legendaStatus}

                <Row align="middle" justify="center" >
                    <Col span={24}>
                        <Table bordered
                            rowClassName={props.rowClassName}
                            expandable={props.expandable}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={onTableRowExpand}
                            className="ant-table-var-local"
                            dataSource={props.dadosTable}
                            columns={props.colunasTable}
                            scroll={props.scrollTable}
                            rowKey={props.rowKeyTable}
                            locale={{emptyText:<Empty description="Sem registro!" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}}
                            size={'small'}
                            pagination={
                                { pageSize: props.pageSizeTable }}
                            loading={props.loading}
                            sticky
                            footer={pageData => {
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <br />
                                            <Text >{pageData?.length} de {props?.dadosTable?.length} resultado(s)</Text>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />

                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default ConsultaComponent;

