import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'

interface IModuloModel {
    moduloID: number,
    nome: string,
    navegarURL: string,
    ativo: boolean,
    possuiMenu: boolean,
}

interface IModuloFiltroModel {
    nome?: string,
    ativo?: number,
}

const ModuloService = {

    get: async (): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Modulo}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    getComFiltro: async (moduloFiltroModel: IModuloFiltroModel): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Modulo}/comfiltros?nome=${moduloFiltroModel?.nome}&ativo=${moduloFiltroModel.ativo}`,)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    getPorPerfil: async (perfilID: any): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Modulo}/porperfil?perfilID=${perfilID}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    getByID: async (): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Modulo}/getdominios`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    post: async (moduloModel: IModuloModel): Promise<RespostaWebAPI<any>> => {
        return Api.post(`${OperacoesWebAPI.Modulo}`, moduloModel)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    put: async (moduloModel: IModuloModel): Promise<RespostaWebAPI<any>> => {
        return Api.put(`${OperacoesWebAPI.Modulo}`, moduloModel)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    delete: async (moduloID : number): Promise<RespostaWebAPI<any>> => {
        return Api.delete(`${OperacoesWebAPI.Modulo}?id=${moduloID}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    }
}

export default ModuloService;
export type { IModuloModel }