import Categorias from '../../secure/pages/Categorias/categorias';
import { OperacoesWebAPI } from '../../utils/global';
import Api from '../index'

const CategoriaService = {


    getByConcursoID: async (concursoID: number): Promise<any> => {
        return await Api.get(`/categoria/getbyconcursoid?concursoid=${concursoID}`).then((res: any) => {
            return res.data
        });
    },
    post: async (categoria: any): Promise<any> => {
        return await Api.post(`/categoria`, categoria)
            .then((res: any) => {
                return res.data
            });
    },
    postComFiltros: async (filtros: any): Promise<any> => {
        return await Api.post(`/categoria/postcomFiltros`, filtros)
            .then((res: any) => {
                return res.data
            });
    },
    get: (): Promise<any> => {
        return Api.get('/categoria').then((res) => { return res.data })
    },

    put:async(categoria:any):Promise<any> => {
        return  await Api.put(`/categoria`, categoria)
        .then((res:any) => {
            return res.data});
    },

    getbycategoriaid : async (categoriaID:number):Promise<any> => {    
        return await Api.get(`/${OperacoesWebAPI.Categoria}/getbycategoriaid?categoriaid=${categoriaID}`)
        .then((res:any)=>  {        
            return res.data});
    },
    
}


export default CategoriaService;