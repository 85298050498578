import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message, notification, Checkbox, Tooltip, Popconfirm } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import Styles from '../root.module.scss';
import "./style.module.less";
import { InfoCircleOutlined } from '@ant-design/icons';
import HistoricoProcessoService from '../../../../services/HistoricoProcesso-Service/historicoProcesso-service';


const { Option } = Select;

interface FormCategoria {
    parentHandle: any,
    inscricao: any
}
const FormCategoria: React.FC<FormCategoria> = (props: FormCategoria) => {
    let formRef = React.createRef<FormInstance>();
    const [erros, setErros] = useState<any>();
    const [form] = Form.useForm();
    const [tipoInst, setTipoInst] = useState<"SOCIEDADECIVIL" | "ESCOLA" | "">("");
    const [mask, setMask] = useState("(99) 99999-9999");
    const [check, setCheck] = useState(false);
    const [required, setRequired] = useState(true);
    const [visivelConfirmDesclassificar, setVisivelConfirmDesclassificar] = useState(false)
    const [visivelConfirmProximo, setVisivelConfirmProximo] = useState(false)
    const [naoPossuiVinculo, setNaoPossuiVinculo] = useState<boolean>();
    const [podeDesclassificar, setPodeDesclassificar] = useState(false)
    const textoCheck = <span>Caso não estude e nem trabalhe, selecione essa opção.</span>
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.inscricao?.telefoneInstituicaoVinculacao.replace("_", "").length === 10)
            setMask("(99) 9999-9999");
        form.setFieldsValue({
            naoPossuiVinculo: props.inscricao?.naoPossuiVinculo === false ? setNaoPossuiVinculo(false) : setNaoPossuiVinculo(true),
            categoria: props.inscricao?.categoria,
            nomeInstituicaoVinculacao: props.inscricao?.nomeInstituicaoVinculacao,
            setorPublico: props.inscricao?.setorPublico,
            telefoneInstituicaoVinculacao: props.inscricao?.telefoneInstituicaoVinculacao,
            cnpjInstituicaoVinculacao: props.inscricao?.cnpjInstituicaoVinculacao
        })

        HistoricoProcessoService.podeDesclassificarPorDados(props?.inscricao?.inscricaoID).then((res) =>
            setPodeDesclassificar(res))
    }, [])

    const proximaTab = () => {
        props.parentHandle("4");
    }

    const onFinish = async (values: any) => {

        proximaTab()

    };

    const voltarTab = () => {
        props.parentHandle("2");

    }

    const onReset = () => {
        formRef.current!.resetFields();
    };

    const checkNaoPossui = (e: any) => {
        setRequired(!e.target.checked)
        if (e.target.checked) {
            formRef.current!.setFieldsValue({
                nomeInstituicaoVinculacao: null,
                telefoneInstituicaoVinculacao: null,
                categoria: "SEMVINCULO",
                setorPublico: false,
                cnpjInstituicaoVinculacao: null,
                serieEscola: null,
                cursoUniversidade: null,
            })
        }
        else {
            formRef.current!.setFieldsValue({
                categoria: "Selecione",
            })
        }
    };
    const desclassificarPorDados = async () => {

        setLoading(true);
        HistoricoProcessoService.desclassificarPorDados(props.inscricao.inscricaoID).then(() => {
            message.success("inscrição desclassificada com sucesso")
        })
            .finally(() => { proximaTab(), setLoading(false) })
    }


    return (
        <>
            <Form className={Styles.formularioCategoria} ref={formRef} form={form} name="control-ref" onFinish={onFinish} style={{ padding: '35px' }}>


                <Form.Item
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 12 }}
                    name="naoPossuiVinculo"
                    valuePropName="checked"
                    style={{ width: '30%', marginTop: '10px' }}
                >
                    <Checkbox disabled checked={check} onChange={(e) => { checkNaoPossui(e) }}> <span className={Styles.checkbox}>Não possuo Vínculo</span> <Tooltip trigger="click" overlay={textoCheck} overlayClassName={Styles.Tooltip} placement="right" ><InfoCircleOutlined style={{ color: '#1890ff', width: '100%' }} /> </Tooltip></Checkbox>

                </Form.Item>

                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="categoria"
                    label="Tipo Categoria"
                    style={{ width: '30%' }}
                >

                    <Input
                        defaultValue='Selecione'
                        style={{ padding: '0.25em', width: '100%' }}
                        disabled
                    >

                    </Input>
                </Form.Item>


                {naoPossuiVinculo === false ?

                    (<><Form.Item
                        name="nomeInstituicaoVinculacao"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Nome da Instituição"
                        rules={[{ required: required, message: "Campo Obrigatório" }]}
                        hidden={!required}
                        style={{ width: '30%' }}
                    >
                        <Input className={Styles.item} disabled placeholder="Informe o nome da instituição"
                            title='Informe o nome da instituição' style={{ width: '100%' }} />
                    </Form.Item>

                        <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            name="setorPublico"
                            label="Setor"
                            hidden={!required}
                            style={{ width: '30%' }}
                        >
                            <Select
                                disabled
                                defaultValue="Selecione"
                                showArrow={false}
                                style={{ width: '100%' }}
                            >
                                <Option value={true}><p style={{ color: 'black' }}>Pública</p></Option>
                                <Option value={false}><p style={{ color: 'black' }}>Privado</p></Option>
                            </Select>
                        </Form.Item><Form.Item
                            name="telefoneInstituicaoVinculacao"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            label="Telefone Instituição"
                            hidden={!required}
                            style={{ width: '30%' }}
                        >
                            <MaskedInput className={Styles.inputDisabled} mask={mask} disabled
                                onBlur={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask("(99) 9999-9999");
                                    }
                                }}
                                onFocus={e => {
                                    if (e.target.value.replace("_", "").length === 14) {
                                        setMask("(99) 99999-9999");
                                    }
                                }}
                                style={{ width: '100%' }} />
                        </Form.Item><Form.Item
                            labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                            name="cnpjInstituicaoVinculacao"
                            label="CNPJ"
                            hidden={!required}
                            style={{ width: '30%' }}
                        >
                            <MaskedInput mask="99.999.999/9999-99" placeholder={"__.___.___/____-__"} className={Styles.inputDisabled} disabled style={{ width: '100%' }} />
                        </Form.Item>

                    </>)
                    : null}

                <div className={Styles.buttonsArea}>
                    <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                        Voltar
                    </Button>
                    {podeDesclassificar ?
                        <>
                            <Popconfirm
                                title={<><p>Desclassificar participante por dados incorretos, incompletos ou que não</p>
                                    <p> atendem às especificações técnicas ou quaisquer outras disposições deste regulamento?</p></>}
                                visible={visivelConfirmDesclassificar}
                                placement="topLeft"
                                onConfirm={() => { desclassificarPorDados(), setVisivelConfirmDesclassificar(false) }}

                                onCancel={() => { setVisivelConfirmDesclassificar(false) }}>

                                <Button loading={loading} danger type="primary" className={Styles.proximo} onClick={() => { setVisivelConfirmDesclassificar(true) }} >
                                    Desclassificar
                                </Button>

                            </Popconfirm>
                            <Popconfirm
                                title={<><p>Os dados da parte de inscrição do participante estão corretos?</p><p>Se sim clique em continuar</p></>}
                                visible={visivelConfirmProximo}
                                placement="topLeft"
                                okText="Próximo"
                                cancelText="voltar"
                                onConfirm={() => { proximaTab(), setVisivelConfirmProximo(false) }}
                                onCancel={() => { setVisivelConfirmProximo(false) }}>
                                <Button type="primary" className={Styles.proximo} onClick={() => { setVisivelConfirmProximo(true) }} >
                                    Próximo
                                </Button>
                            </Popconfirm>
                        </>
                        :
                        <Button type="primary" className={Styles.proximo} onClick={() => { proximaTab() }} >
                            Próximo</Button>
                    }
                </div>
            </Form>

        </>
    )
}

export default FormCategoria;
