import { Button, Col, Empty, Form, Input, InputNumber, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { FiltroConsultaModel } from '../../../../features/Models/filtro-model';
import InscricaoService from '../../../../services/Inscricao-Service/inscricao-service';
import Consulta from '../../../components/Consulta/consulta-component'
import Header from '../../../components/Header/header-component';
import { useHistory } from 'react-router-dom';
import FiltroConsulta from '../../../components/FiltroConsulta/filtroConsulta-component';
import ButtonDeleteFiltro from '../../../components/FiltroConsulta/ButtonDeleteFiltro/buttonDeleteFiltro-component'
import EnumeradorService from '../../../../services/Enumerador-Service/enumerador-service';
import Footer from '../../../components/Footer/footer-component';
import { EnumeradorModel } from '../../../../features/Models/enumerador-model'
import ModalEmailAceiteResponsavelComponent from '../../../components/ModalEmailAceiteResponsavel/modalEmailAceiteResponsavel-component';
import { maskCel } from '../../../../utils/masks';
import moment from 'moment';
import { FiltroSelectEnumComponent } from '../../../components/FiltroSelectEnum/filtroSelectEnum-component';
import ModalInscricoesIncompletasComponent from '../../../components/ModalInscricoesIncompletas/modalInscricoesIncompletas-component';
import { InfoCircleOutlined } from '@ant-design/icons'

const InscricaoConsultarPage: React.FC<any> = () => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [dados, setDados] = useState<any[]>([]);
  const history = useHistory();
  const [modalEmailAceite, setModalEmailAceite] = useState(false);
  const [modalInscricoesIncompletas, setModalInscricoesIncompletas] = useState(false);
  const [situacoesConcurso, setSituacoesConcurso] = useState<EnumeradorModel[]>([])

  useEffect(() => {
    EnumeradorService.get('ESituacaoConcurso').then((response) => { setSituacoesConcurso(response.data) })
      .finally(() => setLoading(false))
    pesquisar({})
  }, [])


  const SelectFormularioInscricaoCompleto: React.FC<any> = (props) => {
    const [Options, setOptions] = useState<any>([]);
    const [OptionsSelecao, setOptionsSelecao] = useState<any>([]);

    const onSelect = (value: any) => {
      if (value == true) {
        setOptionsSelecao([{ value: null, texto: "Selecione" }, { value: true, texto: "Sim" }, { value: false, texto: "Não" }])
        setOptions([{ value: null, texto: "Selecione" }, { value: true, texto: "Sim" }, { value: false, texto: "Não" }])
        form.setFieldsValue({ aprovadoSelecao: null })
        form.setFieldsValue({ aceiteResponsavel: null })
      }
      else {
        setOptionsSelecao([])
        setOptions([])
        form.resetFields(["aprovadoSelecao"])
      }
    }

    const onSelectAprovado = (value: any) => {
      if (value == null) {
        setOptions([{ value: null, texto: "Selecione" }, { value: true, texto: "Sim" }, { value: false, texto: "Não" }])
        form.setFieldsValue({ aceiteResponsavel: null })
      }
      else {
        setOptions([])
        form.resetFields(["aceiteResponsavel"])
      }
    }

    const onSelectAceite = (value: any) => {
      if (value == null) {
        setOptionsSelecao([{ value: null, texto: "Selecione" }, { value: true, texto: "Sim" }, { value: false, texto: "Não" }])
        form.setFieldsValue({ aprovadoSelecao: null })
      }
      else {
        setOptionsSelecao([])
        form.resetFields(["aprovadoSelecao"])
      }
    }

    return (
      <>
        <FiltroConsulta removerFiltro={props.removerFiltro} label={"Situação da inscrição"} required={false} key={456}
          name={"formularioInscricaoCompleto"}
          component={
            <Select onSelect={onSelect} defaultValue={null} placeholder="selecione" >
              <Select.Option value={null}>Selecione</Select.Option>
              <Select.Option value={true}>Finalizada</Select.Option> <Select.Option value={false}>Em Aberto</Select.Option>
            </Select>
          } />
        <Col span={6}>
          <Row>
            <Col span={20}>
              <Form.Item
                rules={[{ required: false }]}
                label={<>Classificados Fase de Seleção &nbsp; <Tooltip trigger="click" placement='bottomRight' title='Inscrições que se enquadram para fase de Seleção.'>
                  <InfoCircleOutlined style={{ fontSize: '15px' }} />
                </Tooltip> </>}
                name={"aprovadoSelecao"}>
                <Select
                  onSelect={onSelectAprovado}
                  notFoundContent={<Empty style={{ color: 'black' }}
                    description={<p>Selecione 'Finalizada' no filtro <b>'Situação da inscrição'</b> para aparecer as opções desse filtro"</p>} />}
                >
                  {OptionsSelecao.map((item: any, index: number) => <Select.Option key={index} value={item.value}>{item.texto}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="&nbsp;">
                <ButtonDeleteFiltro nomeFiltro={"formularioInscricaoCompleto"} removerFiltro={props.removerFiltro} />
              </Form.Item>
            </Col>
          </Row>
        </Col >
        <Col span={6}>
          <Row>
            <Col span={20}>
              <Form.Item
                rules={[{ required: false, message: 'Campo obrigatório!' }]}
                label={<>Aceite do Representante Legal &nbsp; <Tooltip trigger="click" placement='bottomRight' title='Somente menores de 18 anos'>
                  <InfoCircleOutlined style={{ fontSize: '15px' }} />
                </Tooltip> </>}
                name={"aceiteResponsavel"}>
                <Select
                  onSelect={onSelectAceite}
                  notFoundContent={<Empty style={{ color: 'black' }}
                    description={<p>Selecione 'Finalizada' no filtro <b>'Situação da inscrição'</b> para aparecer as opções desse filtro"</p>} />}
                >
                  {Options.map((item: any, index: number) => <Select.Option key={index} value={item.value}>{item.texto}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="&nbsp;">
                <ButtonDeleteFiltro nomeFiltro={"formularioInscricaoCompleto"} removerFiltro={props.removerFiltro} />
              </Form.Item>
            </Col>
          </Row>
        </Col >
      </>
    );
  }

  const opcoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'user_Nome',
      label: 'Nome do participante',
      componentChild: <Input placeholder="Informe o nome participante" ></Input>,
      required: false
    },
    {
      name: 'concurso_Nome',
      label: 'Nome do concurso',
      componentChild: <Input placeholder="Informe o nome do concurso" ></Input>,
      required: false
    },
    {
      name: 'municipio',
      label: 'Município do participante',
      componentChild: <Input placeholder="Informe o município" ></Input>,
      required: false
    },
    {
      name: 'telefone1',
      label: 'Telefone do participante',
      componentChild: <InputNumber
        style={{ width: '100%' }}
        onKeyDown={(event) => {
          if (event.keyCode === 8 || event.keyCode === 9 ||
            event.keyCode === 46 ? false : isNaN(Number(event.key))) {
            event.preventDefault()
          }
        }}
        placeholder="Informe o telefone" ></InputNumber>,
      required: false
    },
    {
      name: 'user_Email',
      label: 'E-mail do participante',
      componentChild: <Input placeholder="Informe o e-mail" ></Input>,
      required: false
    },
    {
      name: 'concursoID',
      label: 'Número do concurso',
      componentChild: <InputNumber placeholder="Informe o número do concurso" min={1} max={10} style={{ width: '100%' }} />,
      required: false
    },
    {
      name: 'concurso_Situacao',
      label: 'Situação do concurso',
      componentChild: <></>,
      reactElementHtml: <FiltroSelectEnumComponent name="concurso_Situacao" label="Situação do concurso" tipoEnumerador={"ESituacaoConcurso"} />,
      required: false
    },
    {
      name: 'formularioInscricaoCompleto',
      label: 'Situação da inscrição',
      componentChild: <></>,
      reactElementHtml: <SelectFormularioInscricaoCompleto />,
      required: false
    },
  ]

  let colunas: any = [
    {
      title: 'Participante',
      children: [

        {
          title: 'Nome', dataIndex: 'nome', width: 200,
          defaultSortOrder: 'ascend',
          sorter: (a: any, b: any) => a.user.nome.localeCompare(b.user.nome),
          showSorterTooltip: false,
          render: (text: any, record: any) => (record?.user?.nome)
        },
        {
          title: 'E-mail', dataIndex: 'email', width: 300, align: 'center',
          render: (text: any, record: any) => (record?.user?.email)
        },
        {
          title: 'Telefone', dataIndex: 'telefone1', width: 150, align: 'center',
          render: (text: any, record: any) => (
            maskCel(record?.telefone1)
          )
        },
        {
          title: 'Município', dataIndex: 'municipio', width: 150, align: 'center',
        },
        {
          title: 'Situação da Inscrição', dataIndex: 'formularioInscricaoCompleto', width: 150, align: 'center',
          render: (text: any, record: any) => (<p style={{ margin: '0' }}>{record.formularioInscricaoCompleto ? 'Finalizada' : 'Em Aberto'}</p>)
        },
        {
          title: 'Data da Inscrição', dataIndex: 'dataHoraInclusao', width: 150, align: 'center',
          defaultSortOrder: 'descend',
          sorter: (a: any, b: any) => moment(a.dataHoraInclusao).unix() - moment(b.dataHoraInclusao).unix(),
          showSorterTooltip: false,
          render: (text: any, record: any) => (<p style={{ margin: '0' }}>{moment(record.dataHoraInclusao).format('DD/MM/YYYY')}</p>)
        },
        {
          title: 'Aceite do Representante Legal', dataIndex: 'aceiteResponsavel', width: 150, align: 'center',
          render: (text: any, record: any) => (<p style={{ margin: '0' }}>{record.aceiteResponsavel ? 'Sim' : record.cpfResponsavel != null && record.aceiteResponsavel != null ? 'Não' : '-'}</p>)
        },
        {
          title: 'Data Aceite do Representante Legal', dataIndex: 'aceiteResponsavel', width: 150, align: 'center',
          render: (text: any, record: any) => (<p style={{ margin: '0' }}>{record.aceiteResponsavel ? moment(record.dataHoraAceiteResponsavel).format('DD/MM/YYYY') : '-'}</p>)
        },
      ]
    },
    {
      title: 'Concurso', children: [
        {
          title: 'Nome', dataIndex: 'nomeConcurso', width: 200, align: 'center',
          render: (text: any, record: any) => (record?.concurso?.titulo)
        },
        {
          title: 'Situação', dataIndex: 'situacaoConcurso', width: 150, align: 'center',
          render: (text: any, record: any) => (
            situacoesConcurso.filter(s => s.enum == record?.concurso?.situacao)[0]?.texto)
        },
      ]
    },
    {
      title: 'Ações', dataIndex: '', width: 150, align: 'center',
      render: (text: any, record: any) => (
        <>
          <Button onClick={() => history.push(`/inscricao/visualizar/${record.inscricaoID}`)}>
            Visualizar </Button>
        </>
      ),
      fixed: 'right' as 'right',
    }
  ]

  const pesquisar = (values: any) => {
    setLoading(true);
    InscricaoService.postFiltroInscricao(values).then((response) => {
      setDados(response.data)
    })
      .finally(() => setLoading(false))
  }

  const botoes = [
    {
      botao:
        <Button style={{ marginLeft: 0 }}
          onClick={() => { setModalEmailAceite(true) }}
          htmlType="button">
          Enviar e-mail para Representante Legal
        </Button>,
      colSpan: 7,
    },
    {
      botao:
        <Button style={{ marginLeft: 0 }}
          onClick={() => { setModalInscricoesIncompletas(true) }}
          htmlType="button">
          Enviar e-mail Inscrições Incompletas
        </Button>,

      colSpan: 8,
    },
  ]

  return (
    <>
      <Header />
      <Consulta
        form={form}
        colunasTable={colunas}
        dadosTable={dados}
        filtros={opcoesDeFiltros}
        loading={loading}
        pageSizeTable={15}
        pesquisar={pesquisar}
        rowKeyTable={"inscricaoID"}
        scrollTable={{ x: 100, y: 400 }}
        botoes={botoes}
        indicesFiltrosPreSelecionados={[0, 1, 2, 3, 4, 6]}
      />
      <Footer />
      <ModalEmailAceiteResponsavelComponent
        visivel={modalEmailAceite}
        setVisivelFalse={() => setModalEmailAceite(false)} />
      <ModalInscricoesIncompletasComponent
        visivel={modalInscricoesIncompletas}
        setVisivelFalse={() => setModalInscricoesIncompletas(false)} />
    </>
  )
}

export default InscricaoConsultarPage