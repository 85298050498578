import { Button, Form, Input, message, Modal, Select, Spin, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import { FiltroConcurso } from '../../components/FiltroConcurso/filtroConcurso-component';
import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';
import Styles from './modalidade-page.module.less';
import { formatarFiltrosUnderlineParaObjeto, useFiltroServiceFactory } from '../../../services/Filtro-service/filtro-service';
import ModalidadeDesenhoService from '../../../services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import { RespostaWebAPI } from '../../../features/Models/respostawebapi-model';
import ConsultaComponent from '../../components/Consulta/consulta-component';
import FormItem from 'antd/lib/form/FormItem';
import MotivoDesclassificacaoService from '../../../services/MotivoDesclassificacao-Service/motivoDesclassificacao-service';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import { convertCompilerOptionsFromJson } from 'typescript';

const ModalidadePage: React.FC<any> = () => {

    const [dados, setDados] = useState<any[]>([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [ehEdicao, setEhEdicao] = useState<boolean>()
    const [formModalCadastro] = Form.useForm()
    const [selectEnumSituacao, setSelectEnumSituacao] = useState<any>([])
    const [modal, setModal] = useState<boolean>(false)
    const [ModalidadeSelecionado, setModalidadeSelecionado] = useState<any>()
    const [concursos, setConcursos] = useState<any[]>([]);

    useEffect(() => pesquisar({}), [])

    useEffect(() => {

        ConcursoService.get().then((resp) => setConcursos(resp.data))
        pesquisar({})
    }, [])

    const PreencherModal = (modalidade: any) => {
        setLoading(true);
        ModalidadeDesenhoService.getByModalidadeID(modalidade.modalidadeDesenhoID).then((resp) => {
            formModalCadastro.setFieldsValue({
                descricao: modalidade.descricao,
                concursoID: modalidade.concurso.concursoID,
                modalidadeDesenhoID: resp.modalidadeDesenhoID,
                contentTypes: modalidade.contentTypes
            })
            setModalidadeSelecionado(resp.data); 
        })
        .finally(()=>setLoading(false))

    }

    const onFinish = (values: any) => {
        setLoading(true)

        if (ehEdicao == true) {
            let modalidade = {
                modalidadeDesenhoID: ModalidadeSelecionado[0].modalidadeDesenhoID,
                concursoID: values.concursoID,
                descricao: values.descricao,
                contentTypes: values.contentTypes
            }

            ModalidadeDesenhoService.put(modalidade).then(() => {
                setLoading(true)
                setModal(false)
                message.success('Modalidade atualizado com sucesso')
                pesquisar({})
                formModalCadastro.resetFields()
            }).catch((error: any) =>
                message.error(error?.response?.data?.error || 'Erro na conexão do Servidor'))
                .finally(() => setLoading(false))
        } else {
            ModalidadeDesenhoService.post(values)

                .then(() => {
                    setLoading(true)
                    setModal(false)
                    message
                        .success('Modalidade Cadastrada com Sucesso')
                    pesquisar({})
                    formModalCadastro.resetFields()
                })
                .catch((error) => {
                    message
                        .error(error?.response?.data?.error || 'Erro na conexão do Servidor');
                })
                .finally(() => setLoading(false))
        }
    }



    let colunas: any = [

        {
            title: 'Descrição', dataIndex: 'descricao', width: 200, align: 'left',
            render: (text: any, record: any) => (record?.descricao)
        },
        {
            title: 'Tipo Arquivo', dataIndex: 'contentTypes', width: 200, align: 'center',
            render: (text: any, record: any) => (record?.contentTypes)
        },
        {
            title: 'Concurso', dataIndex: 'concurso', width: 200, align: 'center',
            render: (text: any, record: any) => (record?.concurso?.titulo)
        },
        {
            title: 'Ativo', dataIndex: 'ativo', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
            render: (text: any, record: any) => (record?.ativo == true ? 'Sim' : 'Não'),
        },
        {
            title: 'Ações', dataIndex: 'acoes', width: 200, align: 'center',
            defaultSortOrder: 'ascend',
            render: (text: any, record: any) => (
                <>
                    <Button type='primary' onClick={() => { setEhEdicao(true); setModal(true); PreencherModal(record) }}>Editar</Button>
                    {record?.ativo == true ?
                        <>
                            <Button onClick={() => (null)} style={{ width: '80px', background: 'red', color: 'white' }} >Inativar</Button>
                        </>
                        :
                        <Button onClick={() => (null)} style={{ width: '80px', background: 'green', color: 'white' }} >Ativar</Button>
                    }
                </>
            ),
        },
    ]

    const opcoesDeFiltros: FiltroConsultaModel[] = [
        {
            name: 'descricao',
            label: 'Descrição',
            componentChild: <Input placeholder="Insira uma Descrição" ></Input>,
            required: false
        },
        {
            name: 'Concurso_ConcursoID', label: 'Concurso', componentChild: <></>,
            reactElementHtml: <FiltroConcurso
                key={23} label="Concurso" name="Concurso_ConcursoID" required={false} />, required: false
        }

    ]

    const pesquisar = (values: any) => {

        formatarFiltrosUnderlineParaObjeto(values);
        setLoading(true);
        ModalidadeDesenhoService.postcomfiltros(values).then((response: RespostaWebAPI<any>) => {
            if (response.success) {
                setDados(response.data)
            }
            else {
                setDados([])
            }
        }).finally(() => setLoading(false))
    }

    const btnNovoModalidade = () => {
        return <Button type='primary' style={{ marginBottom: '15px' }} onClick={() => setModal(true)} >Nova Modalidade</Button>
    }

    return (
        <>
            <Header />
            <ConsultaComponent
                colunasTable={colunas}
                dadosTable={dados}
                scrollTable={{ x: 100, y: 400 }}
                pageSizeTable={15}
                rowKeyTable={"modalidadeID"}
                filtros={opcoesDeFiltros}
                pesquisar={pesquisar}
                form={form}
                loading={loading}
                indicesFiltrosPreSelecionados={[0, 1]}
                legendaStatus={btnNovoModalidade()}
            />
            <Modal visible={modal} onCancel={() => { setModal(false); formModalCadastro.resetFields(); setEhEdicao(false) }}
                footer={[

                ]} title={ehEdicao == true ? 'Editar Modalidade' : 'Novo Modalidade'} width={'50%'}>
                <Spin spinning={loading}>
                    <Form form={formModalCadastro} onFinish={onFinish}>
                        <Form.Item name="descricao" wrapperCol={{ span: 24 }} label='Descrição' rules={[{ required: true, message: 'Campo Descrição é obrigatório' }]}>
                            <Input placeholder='Informe a Descrição da Modalidade' />
                        </Form.Item>
                        <Form.Item name='concursoID' wrapperCol={{ span: 24 }} label='Concurso' rules={[{ required: true, message: 'Campo concurso é obrigatório' }]}>
                            <Select placeholder='Selecione o Corcurso da Modalidade'>
                                {concursos.map((concurso: any, index: any) => {
                                    return <Select.Option key={index} value={concurso.concursoID}>{concurso.titulo}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name="contentTypes" label='Tipo Arquivo' rules={[{ required: true, message: 'Tipo Arquivo é obrigatório' }]}>
                            <Input placeholder='Informe o Tipo Arquivo da Modalidade' />
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <FormItem>
                                <Button onClick={() => { setModal(false); formModalCadastro.resetFields(); setEhEdicao(false) }} style={{ marginRight: '15px' }}>Voltar</Button> </FormItem>
                            <FormItem>  <Button htmlType='submit' type='primary' >{ehEdicao == true ? 'Salvar' : 'Cadastrar'} </Button>    </FormItem>
                        </div>
                    </Form> </Spin>
            </Modal>
            <Footer />
        </>
    )
}



export default ModalidadePage;