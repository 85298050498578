import React, { useState, useEffect } from 'react';
import { Empty, Select } from 'antd';
import 'antd/dist/antd.css';
import FiltroConsulta from '../FiltroConsulta/filtroConsulta-component';
import CategoriaService from '../../../services/Categoria-Service/categoria-service';

interface FiltroCategoriaProps {
  removerFiltro?: any
  label: string,
  name: string,
  required: boolean,
  key: number
}


export const FiltroCategoria: React.FC<FiltroCategoriaProps> = (props) => {
  const [categorias, setCategorias] = useState<any>()
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    CategoriaService.get().then((response) => {
      setCategorias(response.data)
    }).finally(() => setLoading(false))
  }, [])


  return (
    <>
      <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={props.required} key={props.key}
        name={props.name}
        component={
          <Select
            loading={loading} placeholder="Selecione"
          >
            {categorias?.map((item: any, index: number) => {
              return <Select.Option key={index} value={item?.categoriaID}>{item?.nome}</Select.Option>
            })}
          </Select>
        } />
    </>
  );
}