import React, { useState, useEffect } from 'react';
import { Row, Table, Col, Empty } from 'antd';
import { Form, Select, Checkbox, message } from 'antd';
import PerfisArray, { EPerfilCodigo } from '../../../enum/perfil.enum';
import Header from '../../components/Header/header-component';
import Footer from '../../components/Footer/footer-component';
import ModuloService from '../../../services/Modulo-Service/modulo-service';
import PermissaoService from '../../../services/Permissao-Service/permissao-service';
import Style from './permissao-page.module.less'
import UserService from '../../../services/User-Service/user-service';

const PermissaoPage: React.FC<any> = (props) => {

    interface Permissao {
        perfilID: number,
        moduloID: number,
        cadastrar: boolean,
        consultar: boolean,
        editar: boolean,
        excluir: boolean

    }

    const defaultCheckedList = ['Apple', 'Orange'];
    const [perfilid, setPerfilid] = useState<number>();
    const [moduloid, setModuloid] = useState<number>(0);
    const [modulos, setModulos] = useState<any[]>([]);
    const [permissoes, setPermissoes] = useState<Permissao[]>([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();


    useEffect(() => {
        getModulos();
    }, [])

    const getModulos = async () => {
        setLoading(true);
        ModuloService.getComFiltro({ ativo: 1, nome: "" })
            .finally(() => {
                setLoading(false);
            })
            .then((response) => {
                setModulos(response.data);
            })
            .catch(response => {
                message.error('Falha na requisição');
            })
    }

    const columns = [
        {
            title: "Perfil", dataIndex: "perfilID", key: "perfil", width: 400,
            render: (id: number, record: any) => (<p>{PerfisArray.filter((item) => id === item.codigo)[0].nome}</p>)
        },
        {
            title: "Consultar", dataIndex: "consultar", key: "url", width: 50,
            render: (t: any, record: any) => (<Checkbox checked={record.consultar} onChange={(e: any) => { onNativeChange(e, record.perfilID, "Consultar") }}
                disabled={ehPerfilADM(record.perfilID)} />)
        },
        {
            title: "Cadastrar", dataIndex: "cadastrar", key: "url", width: 50,
            render: (t: any, record: any) => (<Checkbox checked={record.cadastrar} onChange={(e: any) => { onNativeChange(e, record.perfilID, "Cadastrar") }}
                disabled={ehPerfilADM(record.perfilID)} />)
        },
        {
            title: "Editar", dataIndex: "editar", key: "url", width: 50,
            render: (t: any, record: any) => (<Checkbox checked={record.editar} onChange={(e: any) => { onNativeChange(e, record.perfilID, "Editar") }}
                disabled={ehPerfilADM(record.perfilID)} />)
        },
        {
            title: "Excluir", dataIndex: "excluir", key: "url", width: 50,
            render: (t: any, record: any) => (<Checkbox checked={record.excluir} onChange={(e: any) => { onNativeChange(e, record.perfilID, "Excluir") }}
                disabled={ehPerfilADM(record.perfilID)} />)
        },

        {
            title: "Todos", key: "url", width: 50,
            render: (url: any, record: any) => (<Checkbox checked={
                record.excluir &&
                record.editar &&
                record.cadastrar &&
                record.consultar
            }
                onChange={(e: any) => { onNativeChange(e, record.perfilID, "Todos") }}
                disabled={ehPerfilADM(record.perfilID)} />)

        },

    ]
    const ehPerfilADM = (perfilID: number) => {
        if (perfilID === EPerfilCodigo.AdministradorGeralDesenvolvimento) {
            return true;
        }
        if (perfilID === EPerfilCodigo.AdministradorGeralHomologacao) {
            return true;
        }
        if (perfilID === EPerfilCodigo.AdministradorGeralProducao) {
            return true;
        }
        return false;
    }

    const onNativeChange = async (e: any, perfilid: number, acao: any) => {

        // if (props.permissao[0].permissaoAcesso?.editar === false) {
        //     message.warn("Usuário não possui permissão de edição");
        //     return;
        // }
        let permissaoResponse: any = null;

        setPerfilid(perfilid);
        setLoading(true);
        PermissaoService.getPorModuloPerfil({ moduloID: moduloid, perfilID: perfilid })
            .then(response => {
                if (response.data)
                    permissaoResponse = response.data;
            })
            .finally(() => {

                if (permissaoResponse != null) {
                    if (e.target.checked) {
                        if (acao == 'Consultar')
                            permissaoResponse.consultar = true;
                        if (acao == 'Cadastrar')
                            permissaoResponse.cadastrar = true;
                        if (acao == 'Editar')
                            permissaoResponse.editar = true;
                        if (acao == 'Excluir')
                            permissaoResponse.excluir = true;
                        if (acao == 'Todos') {
                            permissaoResponse.consultar = true;
                            permissaoResponse.cadastrar = true;
                            permissaoResponse.editar = true;
                            permissaoResponse.excluir = true;
                        }
                    }
                    else {
                        if (acao == 'Consultar')
                            permissaoResponse.consultar = false;
                        if (acao == 'Cadastrar')
                            permissaoResponse.cadastrar = false;
                        if (acao == 'Editar')
                            permissaoResponse.editar = false;
                        if (acao == 'Excluir')
                            permissaoResponse.excluir = false;
                        if (acao == 'Todos') {
                            permissaoResponse.consultar = false;
                            permissaoResponse.cadastrar = false;
                            permissaoResponse.editar = false;
                            permissaoResponse.excluir = false;
                        }
                    }

                    PermissaoService.put(permissaoResponse)
                        .finally(() => {
                            carregarPermissoesModulo();
                            setLoading(false)

                            ModuloService.getPorPerfil(sessionStorage.getItem('grupoID')).then((response) => {
                                sessionStorage.setItem('modulos', JSON.stringify(response.data));
                            })
                        })
                        .then((data) => {

                        });
                }
            })


    }

    const carregarPermissoesModulo = async () => {
        setLoading(true);
        PermissaoService.getComFiltro({ moduloID: moduloid, perfilID: 0, permissaoID: 0 })
            .finally(() => {

                setLoading(false);
            })
            .then(response => {
                criarPermissoesPadrao(response.data, moduloid);
                setPermissoes(response.data);
            });
    }

    const selecionarModulo = async (id: number) => {
        setModuloid(id)
        setLoading(true);

        PermissaoService.getComFiltro({ moduloID: id, perfilID: 0, permissaoID: 0 })
            .then(response => {
                setPermissoes(response.data);
                criarPermissoesPadrao(response.data, id);
            })
            .finally(() => {

                setLoading(false);
            })
    }

    const criarPermissoesPadrao = async (permissoes: any, moduloid: any) => {

        await PerfisArray.forEach((perfil: any) => {
            var query = permissoes.find((x: any) => x.perfilID == perfil.codigo && x.moduloID == moduloid);
            // Caso o perfil não tenha nenhuma permissão cadastrada neste modulo, insere um registro sem as permissões.                   
            if (query == undefined) {

                if (UserService.ehAdministrador(perfil.codigo) == true) {

                    let permissaoAdm = {
                        permissaoID: 0,
                        moduloID: moduloid,
                        perfilID: perfil.codigo,
                        consultar: true,
                        cadastrar: true,
                        editar: true,
                        excluir: true
                    }
                    PermissaoService.post(permissaoAdm)
                        .finally(() => { })
                        .then(() => {
                            PermissaoService.getComFiltro({ moduloID: moduloid, perfilID: 0, permissaoID: 0 })
                                .then(response => {
                                    setPermissoes(response.data);
                                })

                        })
                } else{

                let permissao = {
                    permissaoID: 0,
                    moduloID: moduloid,
                    perfilID: perfil.codigo,
                    consultar: false,
                    cadastrar: false,
                    editar: false,
                    excluir: false
                }
                PermissaoService.post(permissao)
                    .finally(() => { })
                    .then(() => {
                        PermissaoService.getComFiltro({ moduloID: moduloid, perfilID: 0, permissaoID: 0 })
                            .then(response => {
                                setPermissoes(response.data);
                            })
                    });
            }
        }
        });

    }

    return (
        <>
            <Header />
            <br />
            <div className={Style.container}>
                <fieldset className={Style.fieldset_consulta}>
                    <Form
                        form={form}
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        size="middle"
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>

                                <Form.Item name="moduloID" label="Módulo">

                                    <Select
                                        showSearch
                                        placeholder="Selecione"
                                        optionFilterProp="children"
                                        onChange={(moduloid: number) => { selecionarModulo(moduloid) }}
                                    >
                                        {modulos.map((e: any, index: number) => {
                                            return <Select.Option key={index} value={e.moduloID}>{e.nome}</Select.Option>
                                        })}

                                    </Select>

                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </fieldset>
                <br />
                <Row>
                    <Col className="gutter-row" span={24}>
                        <Table
                            locale={{emptyText:<Empty description="Sem registro!" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}}
                            className="ant-table-var-local"
                            columns={columns}
                            dataSource={permissoes}
                            rowKey={"permissaoID"}
                            scroll={{ y: 300 }}
                            pagination={{ pageSize: 6 }}
                            bordered={true}
                            loading={loading}
                            size={'small'}
                            sticky>

                        </Table>
                    </Col>

                </Row>
            </div>
            <Footer />
        </>);
}

export default PermissaoPage;