import { Input, message, Modal, Select, Spin } from 'antd';
import Consulta from '../../components/Consulta/consulta-component';
import React, { useEffect, useState } from 'react'
import { CategoriaModel } from '../../../features/Models/categoria-model';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import CategoriaService from '../../../services/Categoria-Service/categoria-service'
import ConcursoService from '../../../services/Concurso-Service/concurso-service'
import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';
import { Form } from 'antd';
import { Button } from 'antd';
import FiltroConsulta from '../../components/FiltroConsulta/filtroConsulta-component';
import { formatarFiltrosUnderlineParaObjeto } from '../../../services/Filtro-service/filtro-service';
import { FiltroConcurso } from '../../components/FiltroConcurso/filtroConcurso-component';


interface CategoriasProps {
  removerFiltro?: any
  label: string,
  name: string,
  required: boolean,
  key: number
}

const Categorias: React.FC<any> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [concursos, setConcursos] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [categorias, setCategorias] = useState<Array<CategoriaModel>>([]);
  const [spin, setSpin] = useState<boolean>(false)
  const [formModal] = Form.useForm()
  const { TextArea } = Input;

  const [ehEdicao, setEhEdicao] = useState<boolean>()
  const [CategoriaSelecionado, setCategoriaSelecionado] = useState<any>()

  useEffect(() => {
    setLoading(true);

    ConcursoService.get().then((resp) => setConcursos(resp.data))

    CategoriaService.get()
      .then((resp) => { setCategorias(resp.data)})
      .finally(() => setLoading(false))

  }, []);


  const opcoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'Concurso_concursoID', label: 'Nome do concurso', componentChild: <></>,
      reactElementHtml: <FiltroConcurso
        key={23} label="Nome do Concurso" name="Concurso_concursoID" required={false} />, required: false
    },
    {
      name: 'nome',
      label: 'Nome da Categoria',
      componentChild: <Input placeholder="Informe o nome da categoria" ></Input>,
      required: false
    },
    {
      name: 'descricao',
      label: 'Descrição',
      componentChild: <Input placeholder="Informe descrição da categoria" ></Input>,
      required: false
    },
    {
      name: 'situacao',
      label: 'Situação',
      componentChild:
        <Select loading={loading} placeholder="Selecione" >
          <Select.Option value="Ativo">Ativo</Select.Option>
          <Select.Option value="Inativo">Inativo</Select.Option>
        </Select>,
      required: false
    }
  ]

  let colunas: any = [
    {
      title: 'Nome', dataIndex: 'nome', width: 150, align: 'left',
      render: (text: any, record: any) => (record?.nome)
    },
    {
      title: 'Descrição', dataIndex: 'descricao', width: 250, align: 'center',
      render: (text: any, record: any) => (record?.descricao || '-')
    },
    {
      title: 'Concurso', dataIndex: '', width: 300, align: 'center',
      render: (text: any, record: any) => (record?.concurso?.titulo)
    },
    {
      title: 'Ações', dataIndex: '', width: 150, align: 'center',
      render: (text: any, record: any) => (
        <>
          <Button type='primary' onClick={() => { setEhEdicao(true); setModal(true); pesquisarCategoria(record?.categoriaID) }}>Editar</Button>
          {record?.ativo == true ?
            <>
              <Button onClick={() => (null)} style={{ width: '80px', background: 'red', color: 'white' }} >Inativar</Button>
            </>
            :
            <Button onClick={() => (null)} style={{ width: '80px', background: 'green', color: 'white' }} >Ativar</Button>
          }
        </>
      ),
      fixed: 'right' as 'right',
    }

  ]

  const pesquisar = (values: any) => {
    formatarFiltrosUnderlineParaObjeto(values)
    setLoading(true);
    CategoriaService.postComFiltros(values).then((response: any) => {
      if (response.success) {
        setCategorias(response.data)
      } else {
        setCategorias([])
      }
    })
      .finally(() => setLoading(false))
  }


  const botaoCadastrar = (
    <Button type='primary' style={{ marginBottom: '20px' }} onClick={() => setModal(true)}>Nova Categoria</Button>
  )


  const pesquisarCategoria = (categoriaID: number) => {
    setLoading(true)
    CategoriaService.getbycategoriaid(categoriaID).then((resp) => {
      onFill(resp.data)
      setCategoriaSelecionado(resp.data)
    })
      .finally(() => setLoading(false))
  }

  const onFinish = (values: any) => {

    setLoading(true)

    if (ehEdicao == true) {
      let categoria = {
        categoriaID: CategoriaSelecionado.categoriaID,
        nome: values.nome,
        descricao: values.descricao,
        concursoID: values.concursoID,
        dataHoraInclusao: CategoriaSelecionado.dataHoraInclusao,
        dataHoraAlteracao: CategoriaSelecionado.dataHoraAlteracao,
        usuarioInclusao: CategoriaSelecionado.usuarioInclusao,
        usuarioAlteracao: CategoriaSelecionado.usuarioAlteracao,
        ativo: CategoriaSelecionado.ativo
      }

      CategoriaService.put(categoria).then(() => {
        setLoading(true)
        setModal(false)
        message.success('Concurso atualizado com sucesso')
        pesquisar({})
        formModal.resetFields()
      }).catch((error) =>
        message.error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor'))
        .finally(() => setLoading(false))
    } else {
      CategoriaService.post(values)
        .then(() => {
          setLoading(true)
          setModal(false)
          message
            .success('Categoria Cadastrado com Sucesso')
          pesquisar({})
          formModal.resetFields()
        })
        .catch((error) => {
          message
            .error(error?.response?.data?.error[0] || 'Erro na conexão do Servidor');
        })
        .finally(() => setLoading(false))
    }
  }

  const onFill = (categoria: any) => {
    formModal.setFieldsValue({
      nome: categoria.nome,
      descricao: categoria.descricao,
      categoriaID: categoria.categoriaID,
      concursoID: categoria.concursoID,
    })
      }

  return (

    <Spin spinning={spin}>
      <Header />
      <Consulta
        legendaStatus={botaoCadastrar}
        colunasTable={colunas}
        dadosTable={categorias}
        scrollTable={{ x: 100, y: 400 }}
        pageSizeTable={15}
        rowKeyTable={"categoriaID"}
        filtros={opcoesDeFiltros}
        pesquisar={pesquisar}
        form={form}
        loading={loading}
        indicesFiltrosPreSelecionados={[0, 1, 2]}
      />
      <Modal visible={modal} onCancel={() => { setModal(false); formModal.resetFields(); setEhEdicao(false) }} footer={[]} title={ehEdicao == true ? 'Editar Categoria' : 'Nova Categoria'}>
        <Form onFinish={onFinish} form={formModal}>
          <Form.Item name='nome' wrapperCol={{ span: 24 }} label='Nome' rules={[{ required: true, message: 'Campo nome é obrigatório' }]}>
            <Input placeholder='Informe o nome da categoria'></Input>
          </Form.Item>
          <Form.Item name='descricao' wrapperCol={{ span: 24 }} label='Descrição' rules={[{ required: true, message: 'Campo descrição é obrigatório' }]}>
            <TextArea rows={4} placeholder='Digite a descrição' />
          </Form.Item>
          <Form.Item name='concursoID' wrapperCol={{ span: 24 }} label='Concurso' rules={[{ required: true, message: 'Campo concurso é obrigatório' }]}>
            <Select placeholder='Selecione'>
              {concursos.map((concurso: any, index: any) => {
                return <Select.Option key={index} value={concurso.concursoID}>{concurso.titulo}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item>
              <Button onClick={() => { setModal(false); formModal.resetFields() }} style={{ marginRight: '15px' }}>Voltar</Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' type='primary' >{ehEdicao == true ? 'Editar' : 'Cadastrar'}</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Footer />
    </Spin>
  )
}

export default Categorias;
