import React, { useEffect, useState } from 'react'

import Styles from './visualizar.module.less'
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import FormUpload from './FormUpload/FormUpload-component';
import { Spin } from 'antd';
import FormContato from './FormContato/formContato-component';
import InscricaoService from '../../../services/Inscricao-Service/inscricao-service';
import FormCategoria from './FormCategoria/formCategoria-component';
import FormDados from './FormDados/formDados-component';

interface InscricaoVisualizarSelecao {
  inscricao:any  
  atualizarLegenda:any
}

const InscricaoVisualizarSelecao: React.FC<InscricaoVisualizarSelecao> = (props) => {
  const [tab, setTab] = useState<string>("1");
  const [loading, setLoading] = useState<boolean>(false)
  const [user, setUser] = useState(Object)
  const [inscricao, setInscricao] = useState(Object)

  useEffect(() => {
        setInscricao(props.inscricao)
  }, [])

  const { TabPane } = Tabs;
  const handleTab = (childData: string) => {    
    setTab(childData);
    return tab;
  }

  return (
    <>
          
      <Spin spinning={loading}>
        <div className={Styles.content}>
          <div className={Styles.tab} >
            <Tabs activeKey={tab} centered size="large" tabBarStyle={{ borderBottom: 'none' }} className={Styles.TabHeader} onChange={handleTab}>
              <TabPane tab="Dados" key="1" >
                <div className={Styles.content}>
                  <FormDados parentHandle={handleTab} inscricao={inscricao} />
                </div>
              </TabPane>
              <TabPane tab="Contato" key="2">
                <div className={Styles.content}>
                  <FormContato parentHandle={handleTab} inscricao={inscricao} />
                </div>
              </TabPane>
              <TabPane tab="Categoria" key="3">
                <div className={Styles.content}>
                  <FormCategoria parentHandle={handleTab} inscricao={inscricao} />
                </div>
              </TabPane>
              <TabPane tab="Desenhos" key="4" >
                <FormUpload parentHandle={handleTab} atualizarLegenda={props.atualizarLegenda} inscricao={inscricao} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Spin>
       
    </>
  )

}

export default InscricaoVisualizarSelecao