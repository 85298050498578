import { HistoricoProcessoModel } from './../../features/Models/historicoProcesso-model';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'
import MotivoDesclassificacaoService from '../MotivoDesclassificacao-Service/motivoDesclassificacao-service';
import { pink } from '@material-ui/core/colors';


const HistoricoProcessoService = {

    getByConcursoID: async (concursoID: number): Promise<any> => {
        return await Api.get(`/historicoProcesso/getbyconcursoid?concursoID=${concursoID}`).then((res: any) => {
            return res.data
        });
    },
    getByDesenhoID: async (desenhoID: number): Promise<RespostaWebAPI<HistoricoProcessoModel>> => {
        return await Api.get(`/historicoProcesso/getbydesenhoid?desenhoID=${desenhoID}`).then((res: any) => {
            return res.data
        });
    },
    getByInscricaoID: async (inscricaoID: number): Promise<any> => {
        return await Api.get(`/historicoProcesso/getbyinscricaoid?inscricaoid=${inscricaoID}`).then((res: any) => {
            return res.data
        });
    },
    podeDesclassificarPorDados: async (inscricaoID: number): Promise<boolean> => {
        return await Api.get(`/historicoProcesso/getbyinscricaoid?inscricaoid=${inscricaoID}`).then((res: any) => {
            return res.data.data?.every((res: any) => res.situacaoSelecaoENUM == "ASelecionar")
        });
    },
    get: async (): Promise<any> => {
        return await Api.get(`/historicoProcesso`).then((res: any) => {
            return res.data
        });
    },
    post: async (modalidadeDesenhoID: number, inscricaoID: number, formData: any): Promise<any> => {
        return await Api.post(`historicoProcesso?inscricaoID=${inscricaoID}&modalidadeDesenhoID=${modalidadeDesenhoID}`, formData)
            .then((res) => { return res.data })
    },
    put: async (historicoProcessoModel: HistoricoProcessoModel): Promise<any> => {
        return await Api.put(`historicoProcesso/atualizarhistorico`, historicoProcessoModel).then((res) => { return res })
    },
    desclassificar: async (historicoProcessoID: number, motivos: any[], cpfOrganizador: string): Promise<any> => {
        const motivosJSON = JSON.stringify(motivos);
        return await Api.post(`historicoProcesso/desclassificar?historicoProcessoId=${historicoProcessoID}&cpfOrganizador=${cpfOrganizador}`,
            motivosJSON
        ).then((res) => { return res })
    },
    desclassificarPorDados: async (inscricaoID: number): Promise<any> => {
        let pessoa = JSON.parse(sessionStorage.getItem('usuarioLogado') || '')
        return await Api.put(`historicoProcesso/desclassificarpordados?inscricaoID=${inscricaoID}&cpfOrganizador=${pessoa.cpf}`,
        ).then((res) => { return res })
    },
    delete: async (desenhoID: string): Promise<any> => {
        return await Api.delete(`historicoProcesso?id=${parseInt(desenhoID)}`).then((res) => { return res.data })
    },
    getByJulgadorConcursoID: async (julgadorID: number, concursoID: number): Promise<any> => {
        return await Api.get(`historicoProcesso/getbyjulgadorconcursoid?julgadorID=${julgadorID}&concursoID=${concursoID}`)
            .then((res) => { return res.data })
    },
    getByJulgadorID: async (julgadorID: number): Promise<any> => {
        return await Api.get(`historicoProcesso/getbyjulgadorid?julgadorID=${julgadorID}`)
            .then((res) => { return res.data })
    },
    getMotivosDesclassificacao: async (historicoProcessoID: number): Promise<any> => {
        return await Api.get(`historicoProcesso/getHistoricoMotivoDesclassificacao?historicoProcessoID=${historicoProcessoID}`)
            .then((res) => { return res.data });
    },
    postClassificarDesclassificarParticipantePorComissao: async (historicoProcessoID: number): Promise<any> => {
        var usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado') || '{}')
        const body = JSON.stringify({ historicoProcessoID: historicoProcessoID, usuarioLogadoNome: usuarioLogado.nomeUsuario, usuarioLogadoCPF: usuarioLogado.cpf });
        return await Api.post(`historicoProcesso/classificarDesclassificarParticipantePorComissao?historicoProcessoID=${historicoProcessoID}&usuarioLogadoNome=${usuarioLogado.nomeUsuario}&usuarioLogadoCPF=${usuarioLogado.cpf}`, body)
            .then((res) => { return res.data })
    },
}

export default HistoricoProcessoService;