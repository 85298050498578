import { Tabs, Spin, Card, Input, Row, Col, message, Form, Layout, Result } from 'antd';
import GlobalStyles from '../../../styles/globalStyle.module.scss'
import styles from './julgador-component.module.less'
import React, { useState } from 'react';
import { Button } from 'antd';
import { useEffect } from 'react';
import { RespostaWebAPI } from '../../../features/Models/respostawebapi-model';
import { List } from 'antd';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import { useHistory } from 'react-router-dom';
import HistoricoProcessoService from '../../../services/HistoricoProcesso-Service/historicoProcesso-service';


const JulgadorComponent: React.FC<any> = () => {
  const [julgadorID, setJulgadorID] = useState<number>();
  const [concursos, setConcursos] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [desenhos, setDesenhos] = useState<any[]>([])
  const history = useHistory();

  useEffect(() => {
    let usuario = JSON.parse(sessionStorage.getItem("usuarioLogado") || "");
    ConcursoService.getbycpfjulgador(usuario?.cpf).then((res: RespostaWebAPI<any>) => {

      if (res.success) {
        if (res.data != null && res?.data?.concursos.length > 0) {
          sessionStorage.setItem('julgadorID', res?.data?.julgador?.julgadorID)
          setJulgadorID(res?.data?.julgador?.julgadorID);
          setConcursos(res?.data.concursos);
          
          HistoricoProcessoService.getByJulgadorID(parseInt(res?.data?.julgador?.julgadorID)).then((resp) => {
            setDesenhos(resp?.data)
          })
        }
      }
    })
    
  }, [])
  
 

  const verificarDesenhos = () => {
    return desenhos.find(d => d.situacaoAvaliacaoENUM == 'EmAvaliacao')
  }

  return (
    <>
      <div className={`${GlobalStyles.container} ${styles.marginTop}`}>
        <Spin spinning={false}>
          { verificarDesenhos() ?
            <Card title={<b>A comissão organizadora atribiu desenhos para você avaliar nos seguintes concursos:</b>}
              color='gray'
              bordered={true}
              bodyStyle={{ backgroundColor: '#ececec' }}
              style={{ width: '100%', }}>
              <div
                style={{
                  width: '100%',
                  maxHeight: 400,
                  overflow: 'auto',
                  padding: '0 16px',
                }}>
                <List
                  dataSource={concursos}
                  renderItem={item => (
                    <List.Item key={item.concursoID}>
                      <List.Item.Meta />
                      <Card style={{ width: '100%', }} title={item.titulo}>
                        <Button type="primary" style={{ width: '100%' }}
                          onClick={() => { history.push(`listagem-avaliacao/${item.concursoID}/julgador/${julgadorID}`) }}
                        >  Avaliar desenhos</Button>
                      </Card>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
            : 
            <Result  
              title={"Você não possui desenhos para avaliar"}
            />
          }
        </Spin>
      </div>
    </>
  );
}


export default JulgadorComponent;