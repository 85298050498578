import React, { JSXElementConstructor, ReactElement } from "react";
import { useEffect, useState } from "react";
import { FiltroConsultaModel } from "../../features/Models/filtro-model";
import FiltroConsulta from "../../secure/components/FiltroConsulta/filtroConsulta-component";
import { temValor } from "../../utils/global";

const useFiltroServiceFactory = (form: any, opicoesfiltros: FiltroConsultaModel[], indicesFiltrosPreSelecionados?: number[]) => {

    const [filtrosSelecionados, setFiltrosSelecionados] = useState<FiltroConsultaModel[]>([]);
    const [filtros, setFiltros] = useState<FiltroConsultaModel[]>([]);

    useEffect(() => { definirFiltros(opicoesfiltros) }, [])

    useEffect(() => {       
        preSelecionarFiltros(indicesFiltrosPreSelecionados)        
    }, [filtros])

    const adicionarFiltroSelecionado = () => {
        if (form.getFieldValue('filtroSelecionado') != null && !filtroJaFoiSelecionado(form.getFieldValue("filtroSelecionado"))) {
            let filtro = filtros.filter((item: any) => { return item.name == form.getFieldValue('filtroSelecionado') })[0];
            passarSelectParaOProximoFiltro(filtro)
            setFiltrosSelecionados([...filtrosSelecionados, filtro]);
        }
    }

    const removerFiltroSelecionado = (name: string) => {
        form.resetFields([name])
        setFiltrosSelecionados(filtros => filtros.filter((item: any) => { return item.name !== name }));
    }

    const obterFiltrosSelecionados = (): FiltroConsultaModel[] => {
        return filtrosSelecionados;
    }

    const definirFiltros = (filtros: FiltroConsultaModel[]) => {
        setFiltros(filtros?.map((filtro, index) => {
            if (temValor(filtro.reactElementHtml)) {               
                filtro.reactElementHtml = 
                React.cloneElement(
                filtro.reactElementHtml as ReactElement<any, string | JSXElementConstructor<any>>
                 , {removerFiltro: removerFiltroSelecionado,form})
                
            }
            else {                
                filtro.reactElementHtml = <FiltroConsulta
                    label={filtro.label}
                    name={filtro.name}
                    removerFiltro={removerFiltroSelecionado}
                    key={index}
                    component={
                        filtro.componentChild}
                        required={filtro.required}
                ></FiltroConsulta>
            }
            return filtro
        }
        ))
    }

    const limparFiltros = () => {
        form.resetFields();
    }

    const obterFiltros = (): FiltroConsultaModel[] => {
        return filtros;
    }

    const preSelecionarFiltros = (indicesFiltros: number[] = []) => {
        if(indicesFiltros.length > 0)
            setFiltrosSelecionados(filtros.filter((filtro,index) => indicesFiltros.includes(index) || filtro.required))
        else 
        setFiltrosSelecionados(filtros.filter((filtro) => filtro.required))
    }

    const passarSelectParaOProximoFiltro = (filtroSelecionadoAtualmente: FiltroConsultaModel) => {        
        let indexAtual = filtros.findIndex((filtro)=> filtro.name == filtroSelecionadoAtualmente.name)
        let indexProximo = indexAtual + 1 >= filtros.length ? 0 : indexAtual + 1
        form.setFieldsValue({ filtroSelecionado: filtros.find((item, index) => { return index === indexProximo })?.name })                        
    }

    const filtroJaFoiSelecionado = (filtro: string) => {
        return filtrosSelecionados.filter((item: any) => { return item.name == filtro }).length !== 0;
    }

    return {
        adicionarFiltroSelecionado,
        removerFiltroSelecionado,
        obterFiltrosSelecionados,
        obterFiltros,
        limparFiltros,
       
    }
}


const  formatarFiltrosUnderlineParaObjeto =  (filtrosComValores:any)=> {
 for (let value in filtrosComValores) {
      if (value.includes("_")) {
        let nomesAtributos = value.split("_")

        let objeto = filtrosComValores
        nomesAtributos.forEach((element, index) => {
          if (index < nomesAtributos.length - 1
          ) {

            if (objeto[element] == undefined) {
              objeto[element] = new Object()
            }
            objeto = objeto[element]
          }
          else {
            objeto[element] = filtrosComValores[value]
          }
        });

      }
    }    
}
export { useFiltroServiceFactory, formatarFiltrosUnderlineParaObjeto };

