import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import FiltroConsulta from '../FiltroConsulta/filtroConsulta-component';

interface FiltroConcursoProps {
    removerFiltro?: any
    label: string,
    name: string,    
    required: boolean,
    key:number

}

export const FiltroConcurso: React.FC<FiltroConcursoProps> = (props) => {
    const [concursos, setConcursos] = useState<any>()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        ConcursoService.get().then((response) => setConcursos(response.data))
            .finally(() => setLoading(false))
    }, [])
    const emiteEventoComValorSelecionado = ((concursoID: any) => {
        document.dispatchEvent(new CustomEvent('concursoID', { detail: { concursoID: concursoID } }))
    })
    return (
        <>
            <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={props.required} key={props.key}
                name={props.name}
                component={
                    <Select loading={loading} placeholder="Selecione" onSelect={(concursoID: any) => {
                        emiteEventoComValorSelecionado(concursoID)
                    }}>
                        {concursos?.map((item: any, index: number) => {
                            return <Select.Option key={index} value={item?.concursoID}> {item?.titulo}</Select.Option>
                        })}
                    </Select>
                } />
        </>
    );
}