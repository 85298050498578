export class Global {

}

export enum OperacoesWebAPI {
    User = 'user',
    Login = 'login',
    Inscricao = 'inscricao',
    Modulo = 'modulo',
    Permissao = 'permissao',
    Enumerador = 'enumerador',
    Concurso = 'concurso',
    Julgador = 'julgador',
    Criterio = 'criterio',
    Modalidade = 'ModalidadeDesenho',
    Categoria = 'categoria',
}


export const temValor = (objeto: any): boolean => { return objeto != undefined && objeto != null }