import React from 'react'
import Footer from '../../components/Footer/footer-component'
import Header from '../../components/Header/header-component'
import { Form, Input, Table, Progress, InputNumber, message, Button, Row, Col, List, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Consulta from '../../components/Consulta/consulta-component'
import Style from './julgadores-page.module.less'
import { UserOutlined } from '@ant-design/icons';
import LoteService from '../../../services/Lote-Service/lote-service';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import JulgadorService from '../../../services/Julgador-Service/julgador-service';
import { maskCPF } from '../../../utils/masks';
import moment from 'moment';

const JulgadoresPage: React.FC<any> = (props) => {

    const [form] = Form.useForm();
    const [dados, setDados] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [dadosLegenda, setDadosLegenda] = useState<any>();
    const [numeroDesenhos, setNumeroDesenhos] = useState<{ loteID: number, valorInput: number }>({ loteID: 0, valorInput: 0 });
    const [julgadoresAtribuidos, setJulgadoresAtribuidos] = useState<any[]>([]);
    const { Text } = Typography;

    const [colunas, setColunas] = useState(
        [{
            title: 'Nome do Julgador', dataIndex: 'nome', width: 250, align: 'center',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.user.nome.localeCompare(b.user.nome),
            showSorterTooltip: false,
            render: (text: any, record: any) => (record?.user?.nome)
        },
        {
            title: 'CPF Julgador', dataIndex: 'cpf', width: 150, align: 'center',
            render: (text: any, record: any) => maskCPF(record?.user?.cpf)
        },
        {
            title: 'Atribuir Lote', dataIndex: '', width: 100, align: 'center',
            render: (text: any, record: any) => (
                <Form form={form} >
                    <Form.Item
                        name={"quantidadeDesenhos" + record.julgadorID}
                    >
                        <InputNumber
                            className={record.julgadorID} min={0} max={20} defaultValue={0} type="number" style={{ 'width': '30%' }}                             
                            controls={true} onBlur={(value) => distribuirDesenhos(record, value.currentTarget.value)} />
                    </Form.Item>
                </Form>
            )
        },])

    useEffect(() => {
        setLoading(true)
        LoteService.Getlegenda().then((resp) => { setDadosLegenda(resp.data) }).finally(() => setLoading(false))
        JulgadorService.getJulgadores().then((resp) => {
            setDados(resp)
        })

    }, [])



    const pesquisar = (values: any) => {
        setLoading(true);
        values.user_CPF = values.user_CPF?.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')

        JulgadorService.postFiltroJulgador(values).then((response) => {
            setDados(response?.data)
        })
            .finally(() => setLoading(false))
    }

    const headerPanel = (
        <div className={Style.headerPanel}>
            <div className={Style.legenda}>
                <ul>
                    <li>Total de desenhos: {dadosLegenda?.quantidadeDesenho} <UserOutlined /></li>
                    <li>Total de desenhos a distribuir: {dadosLegenda?.quantidadeDesenhoADistribuir} <UserOutlined /></li>
                    <li>Total de julgadores: {dadosLegenda?.quantidadeJulgadorAtivo} <UserOutlined /></li>
                    <li>Total de julgadores com lote atribuídos: {dadosLegenda?.quantidadeJulgadorLoteAtribuido} <UserOutlined /></li>
                </ul>
            </div>
            <div>
                <button className={Style.distButton} onClick={() => distribuirLotes(julgadoresAtribuidos)}>Confirmar Distribuição de Lotes</button>
            </div>
        </div>
    )

    const atualizarTabela = () => {
        JulgadorService.getJulgadores().then((resp) => {
            setDados(resp)
        })
    }

    const atualizarLegenda = async () => {
        await LoteService.Getlegenda().then((resp) => setDadosLegenda(resp.data))
    }

    const distribuirLotes = (julgadores: any) => {
        setLoading(true)  
                        
        let promisePostLote = julgadores?.reduce((promisse: any, julgador: any) => {
            return promisse.finally(() => new Promise<any>((resolve, reject) => {
                LoteService.post(julgador.concursoId, julgador.cpfJulgador, julgador.quantidadeDesenhos)
                    .then((resp) => resolve(true))
                    .catch((ex) => reject(ex))
            }))
        }, Promise.resolve());
        promisePostLote.then(() => {            
            message.success('Lote(s) distribuído(s) com sucesso')
            atualizarLegenda()
            atualizarTabela()           
             zerarInputNumbersTabela()
        })
            .catch((ex: any) => { message.error(ex.response.data.error[0]) })
            .finally(() => setLoading(false))
    }

    const zerarInputNumbersTabela = () => {
        form.resetFields()       
    }

    const distribuirDesenhos = async (record: any, qtd: any) => {  
        
        for (let i = 0; i < julgadoresAtribuidos.length; i++) {
            if (julgadoresAtribuidos[i].cpfJulgador == record.user.cpf) {
                julgadoresAtribuidos.splice(i, 1);
            }
        }
        julgadoresAtribuidos.push({
            concursoId: process.env.REACT_APP_CONCURSO_ESTATICO,
            cpfJulgador: record.user.cpf,
            quantidadeDesenhos: qtd
        });        
        atualizarLegenda()
    }

    const opcoesDeFiltros: FiltroConsultaModel[] = [
        {
            name: 'user_Nome',
            label: 'Nome',
            componentChild: <Input placeholder="Informe o Nome do Julgador" ></Input>,
            required: false
        },
        {
            name: 'user_CPF',
            label: 'CPF',
            componentChild: <Input placeholder="Informe o CPF do Julgador" ></Input>,
            required: false
        },

    ]



    const onChangeNumber = (value: any, loteID: any) => {
        setNumeroDesenhos({ loteID, valorInput: parseInt(value.target.value) })


    }




    const calcularProgresso = (value: any) => {
        var desenhosTotal = value.desenhos?.length
        var desenhosAvaliar = value.desenhos?.filter((d: any) => d.historicoProcessos.find((h: any) => h.situacaoAvaliacaoENUM == 'Avaliado')).length
        return Math.round((desenhosAvaliar / desenhosTotal) * 100);
    }

    const calcularDesenhosAAvaliar = (value: any) => {
        var desenhosTotal = value.desenhos?.length
        var desenhosAvaliar = value.desenhos?.filter((d: any) => d.historicoProcessos.find((h: any) => h.situacaoAvaliacaoENUM == 'Avaliado')).length
        return desenhosTotal - desenhosAvaliar;
    }

    const calcularDesenhosAvaliados = (value: any) => {
        var desenhosTotal = value.desenhos?.length
        var desenhosAvaliar = value.desenhos?.filter((d: any) => d.historicoProcessos.find((h: any) => h.situacaoAvaliacaoENUM == 'Avaliado')).length
        return desenhosAvaliar + "/" + desenhosTotal;
    }

    const validarDistribuicao = (value: any) => {
        var desenhosTotal = value.desenhos?.length
        var desenhosAvaliados = value.desenhos?.filter((d: any) => d.historicoProcessos.find((h: any) => h.situacaoAvaliacaoENUM == 'Avaliado')).length
        return (Math.round((desenhosAvaliados / desenhosTotal) * 100) == 100 || desenhosTotal == 0);
    }

    const redistribuirDesenho = (value: any) => {

        if (value.loteID != numeroDesenhos.loteID) {
            message.error("Sem desenho(s) para redistribuir")
            return
        }
        setLoading(true)
        LoteService.DesvincularLotesJulgador(value.concursoID, numeroDesenhos.valorInput, value.julgadorID, value.loteID).then((resp) => {
            if (resp.success) {             
                message.success('Desenho(s) desvinculado(s) com sucesso')
                atualizarLegenda()
            }            
        }).finally(() => {
            JulgadorService.getJulgadores().then((resp) => {
                setDados(resp)
            })
        })
            .catch((error) => {
                if (error.response != undefined) {
                    error.response.data.error.map((a: any) => {
                        message.error(a)
                    })
                }
                else {
                    message.error('Erro na conexão com servidor')
                }
            }).finally(() => setLoading(false))
    }

    return (
        <>
            <Header />
            <Consulta
                expandable={{
                    expandedRowRender: (record: any) =>
                        <>
                            <div className={Style.content}>
                                <Row align="middle" justify="center" className={Style.lista}>
                                    <Col span={24}>
                                        {
                                            record.lotes.length > 0 ?
                                                <>
                                                    <h3 className={Style.titleLote}>Lotes</h3>
                                                    <List
                                                        itemLayout="vertical"
                                                        size="large"
                                                        pagination={{
                                                            onChange: page => {
                                                            },
                                                            pageSize: 3,
                                                        }}
                                                        dataSource={record.lotes}
                                                        footer={(pageData: any) => {
                                                            return (
                                                                <>
                                                                    <Table.Summary.Row>
                                                                        <br />
                                                                        <Text >{pageData.length} de {record.lotes.length} resultado(s)</Text>
                                                                    </Table.Summary.Row>
                                                                </>
                                                            );
                                                        }}

                            renderItem={(item: any, index: any) => (
                              <List.Item
                                key={item.loteID}
                              >
                                <Row>
                                  <Col span={4}>
                                    <h4><b>Lote:</b> {btoa(item.loteID + "" + index)}</h4>
                                    <p><b>Desenhos:</b>
                                      {item.desenhos?.map((a: any, index: any) => {
                                        return <> <br /> {btoa(index + moment(a?.desenho?.dataHoraInclusao).format("DDMMYYYY") + a?.desenhoID).replace("==", "")}</>
                                      })
                                      }</p>
                                  </Col>
                                  <Col span={4} className={Style.coluna}>
                                    <p style={{ marginBottom: 0 }}><b>Progresso:</b></p><br />
                                    <Progress width={50} percent={calcularProgresso(item)} type="circle" size="small" status='active' />

                                                                    </Col>
                                                                    <Col span={4} className={Style.coluna}>
                                                                        <p><b>Desenhos Avaliados:</b><br />
                                                                            {calcularDesenhosAvaliados(item)}
                                                                        </p>
                                                                    </Col>
                                                                    <Col span={4} className={Style.coluna}>
                                                                        <p><b>Data Lote:</b><br />
                                                                            {moment(item.dataHoraInclusao).format('DD/MM/YYYY')}
                                                                        </p>
                                                                    </Col>
                                                                    <Col span={8} >
                                                                        <p style={{ marginBottom: 0, textAlign: 'center' }}><b>Redistribuir Desenhos:</b></p><br />
                                                                        <>
                                                                            <div className={Style.colunaDistribuir}>
                                                                                <div className={Style.distribuir}>
                                                                                    <Input min={0} max={calcularDesenhosAAvaliar(item)} defaultValue={0} type="number" onChange={(value) => onChangeNumber(value, item.loteID)} disabled={validarDistribuicao(item)} className={Style.input}></Input>
                                                                                    <Button type='primary' disabled={validarDistribuicao(item)} onClick={() => redistribuirDesenho(item)}> Redistribuir Desenhos</Button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    </Col>
                                                                </Row>

                                                            </List.Item>
                                                        )}
                                                    />
                                                </>
                                                : <p className={Style.textLote}>
                                                    <b>Sem registro de lotes atribuídos</b>
                                                </p>
                                        }

                                    </Col>
                                </Row>
                            </div>
                        </>
                    // <VisualizarLote colunasTable={colunasLote} rowKeyTable={"loteID"} dadosTable={record.lotes} loading={loading} />
                }}
                legendaStatus={headerPanel}
                form={form}
                colunasTable={colunas}
                dadosTable={dados}
                scrollTable={{ x: 100, y: 400 }}
                pageSizeTable={15}
                rowKeyTable={"julgadorID"}
                loading={loading}
                filtros={opcoesDeFiltros}
                pesquisar={pesquisar}
                indicesFiltrosPreSelecionados={[0, 1]}
                onExpand={"Julgador"}
            />

            <Footer />
        </>
    )
}
export default JulgadoresPage