
import Style from './header-component.module.less';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import logoGov from '../../../assets/img/Logo_governo negativo.svg'



const Header: React.FC<any> = () => {
    var usuario = JSON.parse(sessionStorage.getItem('usuarioLogado') || '{}')
    var grupo = sessionStorage.getItem('grupo')
    var geoEstrutura = sessionStorage.getItem('geoEstrutura')
    const history = useHistory();
    const [rota, setRota] = useState("");

    useEffect(() => {
        let string = history.location.pathname.substring(1);
        let finalDaPalavra = string.search('/') != -1 ? string.search('/') : string.length;
        verificarPermissaoRota(string.substring(0, finalDaPalavra).toUpperCase());

    }, [])


    const verificarPermissaoRota = (rota: string) => {
        let menus: any[] = JSON.parse(sessionStorage.getItem('modulos') || '[]');
        if (menus.length > 0) {
            let menu: any = menus.find(m => m.navegarURL.trim().toLowerCase().replace(' ', '').includes(rota.trim().replace(' ', '').toLowerCase()));

            if (menu == null) {

                history.replace('/error', { mensagem: "Usuário não possui permissão ao módulo." })
            }
            else {
                setRota(menu?.nome?.toUpperCase()?.replace('-', " "))
            }
        }
        else if (history.location.pathname !== '/error') {
            history.replace('/error', { mensagem: "Usuário não possui permissão ao módulo." })
        }
    }

    return (
        <div className={Style.header}>
            <nav id={Style.customNav} className={`${Style.navbar} navbar-dark bg-dark`}>
                <div>
                    <img className={Style.logo_msgov} src={logoGov} />
                </div>

                <div>
                    <form className="form-mobile-info form-inline" id="form_header">
                        <div><span>{rota}</span></div>
                        {/* <button id="btn-novo" className="custom-btn btn btn-success" type="submit">
                        <a href="cadastro.html">+ Novo</a>
                    </button> */}
                    </form>
                </div>

                {/* <div className="imigrantess-logo">   
                     <img src={logo-header} className="logoHeader"/>                     
                </div> */}
                <div className={Style.tituloSistema}>

                </div>
                {/* <div className="info_perfil">
                    <span>{usuario.nomeUsuario}
                        <br />{grupo}
                        <br /> {geoEstrutura}</span>
                </div> */}
                {/* <div className="btn-perfil-info">
                    <button className="btn custom-info-btn" id="info-mob" type="button" data-toggle="collapse" data-target="#info-mobile">
                        <span>Usuário</span>
                        <div className="collapse" id="info-mobile">
                            <div>
                                <span>{usuario.nomeUsuario}
                                    <br />{grupo}
                                    <br /> {geoEstrutura}</span>
                            </div>
                        </div>
                    </button>
                </div> */}
            </nav>
        </div>
    );
}

export default Header;