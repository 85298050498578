import { Select } from "antd"
import { useEffect, useState } from "react"
import EnumeradorService from "../../../services/Enumerador-Service/enumerador-service"
import FiltroConsulta from "../FiltroConsulta/filtroConsulta-component"

interface FiltroSelectEnumProps {
    tipoEnumerador: any,
    removerFiltro?: any,
    label:any,
    name:any

}
export const FiltroSelectEnumComponent: React.FC<FiltroSelectEnumProps> = (props) => {

    useEffect(() => {
      setLoading(true)
      EnumeradorService.get(props.tipoEnumerador).then((response: any) => { setEnums(response.data) })
        .finally(() => setLoading(false))

    }, [])


    const [enums, setEnums] = useState<any>();
    const [loading, setLoading] = useState(false);

    return (
      <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={false} key={456}
        name={props.name}
        component={
          <Select loading={loading} defaultValue={""} placeholder="selecione" >
            <Select.Option value="">Selecione</Select.Option>
            {enums?.map((item: any, index: number) => <Select.Option key={index} value={item.enum}>{item.texto}</Select.Option>)}
          </Select>
        } />
    );

  }