import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message, notification, Checkbox, Tooltip, Popconfirm } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import Styles from '../root.module.scss';
import "./style.module.less";
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface FormCategoria {
    parentHandle: any,
    inscricao: any
}
const FormCategoria: React.FC<FormCategoria> = (props: FormCategoria) => {
    let formRef = React.createRef<FormInstance>();
    const [erros, setErros] = useState<any>();
    const [form] = Form.useForm();
    const [tipoInst, setTipoInst] = useState<"SOCIEDADECIVIL" | "ESCOLA" | "">("");
    const [mask, setMask] = useState("(99) 99999-9999");
    const [check, setCheck] = useState(false);
    const [required, setRequired] = useState(true);

    const [naoPossuiVinculo, setNaoPossuiVinculo] = useState<boolean>();

    const textoCheck = <span>Caso não estude e nem trabalhe, selecione essa opção.</span>

    useEffect(() => {
        if (props.inscricao?.telefoneInstituicaoVinculacao.replace("_", "").length === 10)
            setMask("(99) 9999-9999");
        form.setFieldsValue({
            naoPossuiVinculo: props.inscricao?.naoPossuiVinculo === false ? setNaoPossuiVinculo(false) : setNaoPossuiVinculo(true),
            categoria: props.inscricao?.categoria,
            nomeInstituicaoVinculacao: props.inscricao?.nomeInstituicaoVinculacao,
            setorPublico: props.inscricao?.setorPublico,
            telefoneInstituicaoVinculacao: props.inscricao?.telefoneInstituicaoVinculacao,
            cnpjInstituicaoVinculacao: props.inscricao?.cnpjInstituicaoVinculacao
        })

    }, [])

    const proximaTab = () => {
        props.parentHandle("4");
    }


    const onFinish = async (values: any) => {

        proximaTab()

    };

    const voltarTab = () => {
        props.parentHandle("2");

    }

    const onReset = () => {
        formRef.current!.resetFields();
    };

    const checkNaoPossui = (e: any) => {
        setRequired(!e.target.checked)
        if (e.target.checked) {
            formRef.current!.setFieldsValue({
                nomeInstituicaoVinculacao: null,
                telefoneInstituicaoVinculacao: null,
                categoria: "SEMVINCULO",
                setorPublico: false,
                cnpjInstituicaoVinculacao: null,
                serieEscola: null,
                cursoUniversidade: null,
            })
        }
        else {
            formRef.current!.setFieldsValue({
                categoria: "Selecione",
            })
        }
    };


    return (
        <Form className={Styles.formulario} ref={formRef} form={form} name="control-ref" onFinish={onFinish} style={{ padding: '35px', marginBottom: '5em' }}>
            <fieldset >

                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="naoPossuiVinculo"
                    valuePropName="checked"
                >
                    <Checkbox disabled checked={check} onChange={(e) => { checkNaoPossui(e) }}> Não possuo Vínculo &nbsp;<Tooltip trigger="click" overlay={textoCheck} overlayClassName={Styles.Tooltip} placement="right" ><InfoCircleOutlined style={{ color: '#1890ff' }} /> </Tooltip></Checkbox>

                </Form.Item>


                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="categoria"
                    label="Tipo Categoria"

                >

                    <Input
                        defaultValue='Selecione'
                        style={{ padding: '0.25em' }}
                        disabled
                    >


                    </Input>
                </Form.Item>
            </fieldset>
            {naoPossuiVinculo === false ?

                <fieldset>
                    <Form.Item
                        name="nomeInstituicaoVinculacao"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Nome da Instituição"
                        rules={[{ required: required, message: "Campo Obrigatório" }]}
                        hidden={!required}
                    >
                        <Input className={Styles.item} disabled placeholder="Informe o nome da instituição"
                            title='Informe o nome da instituição' />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="setorPublico"
                        label="Setor"
                        hidden={!required}

                    >
                        <Select
                            disabled
                            defaultValue="Selecione"
                            showArrow={false}

                        >
                            <Option value={true} ><p style={{ color: 'black' }}>Pública</p></Option>
                            <Option value={false} ><p style={{ color: 'black' }}>Privado</p></Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="telefoneInstituicaoVinculacao"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Telefone Instituição"
                        hidden={!required}

                    >
                        <MaskedInput className={Styles.inputDisabled} mask={mask} disabled
                            onBlur={e => {
                                if (e.target.value.replace("_", "").length === 14) {
                                    setMask("(99) 9999-9999");
                                }
                            }}
                            onFocus={e => {
                                if (e.target.value.replace("_", "").length === 14) {
                                    setMask("(99) 99999-9999");
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        name="cnpjInstituicaoVinculacao"
                        label="CNPJ"
                        hidden={!required}
                    >
                        <MaskedInput mask="99.999.999/9999-99" placeholder={"__.___.___/____-__"} className={Styles.inputDisabled} disabled />
                    </Form.Item>
                </fieldset>

                : null}

            <div
                className={Styles.buttonsArea}
            >
                <Button type="primary" className={Styles.proximo} onClick={voltarTab}>
                    Voltar
                </Button>
                <Button type="primary" className={Styles.proximo} onClick={proximaTab} >
                    Próximo
                </Button>

            </div>

        </Form>
    )
}

export default FormCategoria;
