import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'


const CriterioService = {

    get: (): Promise<any> => {
        return Api.get(OperacoesWebAPI.Criterio).then((res) => { return res.data })
    },

    getbycategoriaid: async (categoriaID: number): Promise<any> => {
        return await Api.get(`/${OperacoesWebAPI.Criterio}/getbycategoriaid?categoriaID=${categoriaID}`)
            .then((res: any) => {
                return res.data
            });
    },

    postComFiltros: async(model:any): Promise<any> => {
        return Api.post(`/${OperacoesWebAPI.Criterio}/postcomfiltros`,model)
        .then((axiosResponse:AxiosResponse<RespostaWebAPI<any>>) => {        
            return axiosResponse.data
        })    
    },
}

export default CriterioService;