import { Button, Col, DatePicker, Empty, Form, Input, InputNumber, Row, Select, Collapse, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import InscricaoService from '../../../services/Inscricao-Service/inscricao-service';
import Consulta from '../../components/Consulta/consulta-component'
import Header from '../../components/Header/header-component';
import { useHistory, useParams } from 'react-router-dom';
import FiltroConsulta from '../../components/FiltroConsulta/filtroConsulta-component';
import ButtonDeleteFiltro from '../../components/FiltroConsulta/ButtonDeleteFiltro/buttonDeleteFiltro-component'
import EnumeradorService from '../../../services/Enumerador-Service/enumerador-service';
import Footer from '../../components/Footer/footer-component';
import { EnumeradorModel } from '../../../features/Models/enumerador-model'
import { maskCel } from '../../../utils/masks';
import { BsCircleFill } from 'react-icons/bs'
import Styles from './selecao-page.module.less'
import { UserOutlined } from '@ant-design/icons';
import DesenhoService from '../../../services/Desenho-Service/desenho-service';
import { DesenhoModel } from '../../../features/Models/desenho-model';
import ModalidadeDesenhoService from '../../../services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import { ModalidadeModel } from '../../../features/Models/modalidade-model';
import { InscricaoModel } from '../../../features/Models/inscricao-model';
import InscricaoVisualizarSelecao from '../../components/VisualizarSelecao/inscricao-visualizar-selecao';
import { FiltroSelectEnumComponent } from '../../components/FiltroSelectEnum/filtroSelectEnum-component';

const SelecaoPage: React.FC<any> = (props) => {

  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [dados, setDados] = useState<any[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [situacoesInscricao, setSituacoesInscricao] = useState<EnumeradorModel[]>([])
  const [situacoesConcurso, setSituacoesConcurso] = useState<EnumeradorModel[]>([])
  const [desenhos, setDesenhos] = useState<DesenhoModel[]>([])
  const [inscricoes, setInscricoes] = useState<InscricaoModel[]>([])
  const [modalidades, setModalidades] = useState<ModalidadeModel[]>([])
  const [modalDesclassificacaoSelecao, setModalDesclassificacaoSelecao] = useState(false);
  const [legenda, setLegenda] = useState<DesenhoModel[]>([])
  const [loadingLegenda, setLoadingLegenda] = useState(false);
  let { id } = useParams<any>();

  useEffect(() => {
    DesenhoService.getByConcursoID(id).then((response) => {
      setDesenhos(response)
    })
  
    pesquisar({});
    ModalidadeDesenhoService.getByConcursoID(id).then((response) => {
      setModalidades(response.data)
    })
    atualizarLegenda()

    EnumeradorService.get('ESituacaoInscricao').then((response) => { setSituacoesInscricao(response.data) })
      .finally(() => setLoading(false))
    EnumeradorService.get('ESituacaoConcurso').then((response) => { setSituacoesConcurso(response.data) })
      .finally(() => setLoading(false))
  
      InscricaoService.postFiltroInscricoesFinalizadas({}, "").then((response) => {
        setInscricoes(response.data)
      })

  }, [])
  
  const atualizarLegenda = () => {
    setLoadingLegenda(true);
    DesenhoService.getQuantitativoDesenhos().then((response) => {
      setLegenda(response.data)
    })
      .finally(() => setLoadingLegenda(false))
  }
  
  const text2 = (
    <>
      <ul className={Styles.lista} style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', marginLeft: '-35px' }}>
        {modalidades.map((modalidade) => {
          return <>
            <li >
              <ul className={Styles.lista} style={{ marginBottom: '15px' }}>
                <li className={Styles.itemLista}>{modalidade.descricao}: {legenda.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.descricao == modalidade?.descricao ? legendaItem.quantitativo : 0); }, 0)} <UserOutlined /></li>
                <li className={Styles.itemLista}><BsCircleFill style={{ color: 'rgb(185, 178, 62)' }} /> {legenda?.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.tipo == "ASelecionar" && legendaItem?.descricao == modalidade.descricao ? legendaItem?.quantitativo : 0); }, 0)}</li>
                <li className={Styles.itemLista}><BsCircleFill style={{ color: 'green' }} /> {legenda?.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.tipo == "Aprovado" && legendaItem?.descricao == modalidade.descricao ? legendaItem?.quantitativo : 0); }, 0)}</li>
                <li className={Styles.itemLista}><BsCircleFill style={{ color: 'red' }} /> {legenda?.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.tipo == "Desclassificado" && legendaItem?.descricao == modalidade.descricao ? legendaItem?.quantitativo : 0); }, 0)}</li>
              </ul>
            </li>
          </>
        })}
      </ul>
    </>
  )

  const headerPanel = (
    <>
      <ul className={Styles.listaHeader}>
        <li className={Styles.itemLista}>Total de Inscrições: {inscricoes.length } <UserOutlined /></li>
        <li className={Styles.itemLista}>Total de desenhos: {legenda?.reduce((total: number, legendaItem: any) => { return total + legendaItem.quantitativo; }, 0)} <UserOutlined /></li>
        <li className={Styles.itemLista}>A selecionar: <BsCircleFill className={Styles.ASelecionar} /> {legenda?.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.tipo == "ASelecionar" ? legendaItem?.quantitativo : 0); }, 0)}</li>
        <li className={Styles.itemLista}>Aprovado : <BsCircleFill className={Styles.Aprovado} /> {legenda?.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.tipo == "Aprovado" ? legendaItem?.quantitativo : 0) }, 0)}</li>
        <li className={Styles.itemLista}>Desclassificado: <BsCircleFill className={Styles.Desclassificado} /> {legenda?.reduce((total: number, legendaItem: any) => { return total + (legendaItem?.tipo == "Desclassificado" ? legendaItem?.quantitativo : 0); }, 0)}</li>
      </ul>
    </>
  )

  const htmlLegendaStatus = (
    <Spin spinning={loadingLegenda}>
      <div className={Styles.status}>
        <Collapse
          bordered={false}
          expandIconPosition='right'
          style={{ borderRadius: '10px', backgroundColor: 'white', border: '1px solid #024135', width: '100%' }}>
          <Panel header={headerPanel} key="1">
            {text2}
          </Panel>
        </Collapse>
      </div>
    </Spin>
  )

  const opicoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'user_Nome',
      label: 'Nome do participante',
      componentChild: <Input placeholder="Informe o nome do participante" ></Input>,
      required: false
    },
    {
      name: 'municipio',
      label: 'Município do participante',
      componentChild: <Input placeholder="Informe o município" ></Input>,
      required: false
    },
    {
      name: 'user_Email',
      label: 'E-mail do participante',
      componentChild: <Input placeholder="Informe o e-mail" ></Input>,
      required: false
    },
    {
      name: 'telefone1',
      label: 'Telefone do participante',
      componentChild: <InputNumber
        style={{ width: '100%' }}
        onKeyDown={(event) => {
          if (event.keyCode === 8 || event.keyCode === 9 ||
            event.keyCode === 46 ? false : isNaN(Number(event.key))) {
            event.preventDefault()
          }
        }}
        placeholder="Informe o telefone" ></InputNumber>,
      required: false
    },
    {
      name: 'concurso_Nome',
      label: 'Nome do concurso',
      componentChild: <Input placeholder="Informe o nome do concurso" ></Input>,
      required: false
    },
    {
      name: 'situacaoDesenho',
      label: 'Situação do desenho',
      componentChild: <></>,
      reactElementHtml: <FiltroSelectEnumComponent name="situacaoDesenho" label="Situação do Desenho" tipoEnumerador={"ESituacaoSelecaoENUM"} />,
      required: false
    },

  ]

  let colunas: any = [

    {
      title: 'Nome do Participante', dataIndex: 'nome', width: 200, align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => a.user.nome.localeCompare(b.user.nome),
      showSorterTooltip: false,
      render: (text: any, record: any) => (record?.user?.nome)
    },
    {
      title: 'Telefone', dataIndex: 'telefone1', width: 200, align: 'center',
      render: (text: any, record: any) => (
        maskCel(record?.telefone1)
      )
    },
    {
      title: 'E-mail', dataIndex: 'email', width: 280, align: 'center',
      render: (text: any, record: any) => (record?.user?.email)
    },

    {
      title: 'Município', dataIndex: 'municipio', width: 200, align: 'center',
    },
    {
      title: ' Situação do Desenho', dataIndex: '', width: 150, align: 'center',
      render: (text: any, record: any) => <div style={{ display: 'flex', justifyContent: 'space-around' }}> {record?.desenhos?.map((desenho: any) => {
        return <BsCircleFill className={Styles[desenho?.historicoProcessos[0]?.situacaoSelecaoENUM]} />
      }
      )}
      </div>
    }
  ]

  const pesquisar = (values: any) => {    
    setLoading(true);
    InscricaoService.postFiltroInscricoesFinalizadas(values, values?.situacaoDesenho || "").then((response) => {
      setDados(response.data)
    })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Header />
      <Consulta
        expandable={{ expandedRowRender: (record: any) => <InscricaoVisualizarSelecao atualizarLegenda={atualizarLegenda} inscricao={record} /> }}
        onExpand={"Inscricao"}
        legendaStatus={htmlLegendaStatus}
        form={form}
        colunasTable={colunas}
        dadosTable={dados}
        filtros={opicoesDeFiltros}
        loading={loading}
        pageSizeTable={15}
        pesquisar={pesquisar}
        rowKeyTable={"inscricaoID"}
        scrollTable={{ x: 100, y: 400 }}
        indicesFiltrosPreSelecionados={[0, 1, 2, 3, 4, 5, 6]}
      />
      <Footer />

    </>
  )
}

export default SelecaoPage;