import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'

const EnumeradorService = {
    get:  (tipoEnumerador: string): Promise<RespostaWebAPI<any>> => {
        return  Api.get(`${OperacoesWebAPI.Enumerador}?tipoEnumerador=${tipoEnumerador}`)
        .then((axiosResponse:AxiosResponse<RespostaWebAPI<any>>) => {        
            return axiosResponse.data
        })    
    },        
}

export  default EnumeradorService;