import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'
import { FilterFilled } from '@ant-design/icons';

let inscricoes = [ 

     {inscricaoID: 1, dataNascimento: '2000-03-31T05:47:43.52', telefone1: "67981185075",uf: "ms",municipio: "string"},
     {inscricaoID: 2, dataNascimento: '2000-04-06T20:33:31.143',telefone1: "67992678885",uf: "ms",municipio: "Campo Grande"},
     {inscricaoID: 5, dataNascimento: '1998-03-14T00:00:00', telefone1: "67999183675", uf: "ms", municipio: "Campo Grande"},
     {inscricaoID: 7, dataNascimento: '1998-03-14T00:00:00', telefone1: "67999999999", uf: "ms", municipio: "Campo Grande"},
     {inscricaoID: 17, dataNascimento: '1998-03-14T00:00:00',telefone1: "67998561566", uf: "ms", municipio: "Campo Grande"},

]

const InscricaoService = {

     
    getComFiltrosTeste: async(model:any) => {
        return inscricoes
    },
    get : async ():Promise<any> => {    
        return await Api.get(`/inscricao`).then((res:any)=>  {        
            return res.data});
    },
    postFiltroInscricao: async(model:any): Promise<any> => {
        return Api.post(`${OperacoesWebAPI.Inscricao}/postFiltroInscricao`,model)
        .then((axiosResponse:AxiosResponse<RespostaWebAPI<any>>) => {        
            return axiosResponse.data
        })    
    },
    postFiltroInscricoesFinalizadas: async(model:any, situacaoDesenho: string): Promise<any>  => {
        return Api.post(`${OperacoesWebAPI.Inscricao}/postFiltroInscricoesFinalizadas?situacaoDesenho=${situacaoDesenho}`,model)
        .then((axiosResponse:AxiosResponse<RespostaWebAPI<any>>) => { 
            return axiosResponse.data
        })
    },
    getByInscricaoID:  (inscricaoID: number): Promise<any> => {
        return  Api.get(`inscricao/getbyinscricaoid?inscricaoID=${inscricaoID}`).then((res) => { return res.data })
    },
    getByConcursoID:  (concursoID: number): Promise<any> => {
        return  Api.get(`inscricao/getbyconcursoid?concursoID=${concursoID}`).then((res) => { return res.data })
    },
    EnviarEmailsConfirmacaoResponsavel: (concursoID: number) : Promise<any> => {
        return  Api.get(`${OperacoesWebAPI.Inscricao}/enviaremailsconfirmacaoresponsavel?concursoID=${concursoID}`).then((res) => { return res.data })
    },
    EnviarEmailsInscricoesIncompletas: (concursoID: number) : Promise<any> => {
        return  Api.get(`${OperacoesWebAPI.Inscricao}/enviaremailsinscricoesincompletas?concursoID=${concursoID}`).then((res) => { return res.data })
    }
}

export  default InscricaoService;