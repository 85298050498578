import Api from '../index'

const LoteService = {
    
    getByConcursoID : async (concursoID:number):Promise<any> => {    
        return await Api.get(`/lote/getbyconcursoid?concursoid=${concursoID}`).then((res:any)=>  {        
            return res.data});
    },
    getByJulgadorID : async (julgadorID:number):Promise<any> => {    
        return await Api.get(`/lote/getbyjulgadorid?julgadorid=${julgadorID}`).then((res:any)=>  {        
            return res.data});
    },
    post: async (concursoID: number, cpfOrganizador: string, quantidadeDesenhos: number): Promise<any> => {
        return await Api.post(`lote?concursoID=${concursoID}&cpfOrganizador=${cpfOrganizador}&quantidadeDesenhos=${quantidadeDesenhos}`)
        .then((res) => { return res.data })                                       
    },
    DesvincularLotesJulgador : async (concursoID: number, quantidadeDesenhos: number, julgadorID: number, loteID: number):Promise<any> => {    
        return await Api.put(`/lote/DesvincularLotesJulgador?concursoID=${concursoID}&quantidadeDesenhos=${quantidadeDesenhos}&julgadorID=${julgadorID}&loteID=${loteID}`).then((res:any)=>  {        
            return res.data});
    },
    Getlegenda: async():Promise<any> =>{
        return await Api.get (`/lote/getlegenda`).then((res:any) =>{
            return res.data;
    })
    },
    getByCpfJulgador : async (cpf:string):Promise<any> => {    
        return await Api.get(`/lote/getbycpfjulgador?cpfJulgador=${cpf}`).then((res:any)=>  {        
            return res.data});
    },
}

export default LoteService;