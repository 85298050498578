import React, { useState, useEffect } from 'react';
import { Row, Col, message, DatePicker } from 'antd';
import { Table, Button, Form, Select } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';



interface ButtonDeleteFiltroProps {
    removerFiltro: any
    nomeFiltro: string,
}

const ButtonDeleteFiltroComponent: React.FC<ButtonDeleteFiltroProps> = (props) => {
    return (
        <Button
            type="dashed"
            onClick={(() => (props.removerFiltro(props.nomeFiltro)))}
            icon={<MinusOutlined />} danger>
        </Button>
    )
}

export default ButtonDeleteFiltroComponent;

