import React, { useState, useEffect } from 'react';
import logoGoverno from '../../../assets/img/GOV-MS-horizontal-BRANCA.png'
import logoBrancaSGI from '../../../assets/img/Logomarca_SETDIG_Branco.png'
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { SettingOutlined, } from '@ant-design/icons';
import { AlignLeftOutlined, SolutionOutlined, UserOutlined, TeamOutlined, ApiOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import { element } from 'prop-types';
import Style from './footer-component.module.less'
import logo from '../../../assets/img/logoConcurso.png'
import mascote from '../../../assets/img/logoConcurso.png'



interface FooterProps {
    modalStatus?: any,
}

const Footer: React.FC<FooterProps> = (props) => {
    const [menuUsuario, setMenuUsuario] = useState(true);
    const [menuUsuarioHover, setMenuUsuarioHover] = useState(true);
    const [aberto, setAberto] = useState(false);
    const history = useHistory();
    const divRodape = useRef<HTMLDivElement>(null);
    const divMenuId = useRef<HTMLDivElement>(null);
    const divSubMenuUsuario = useRef<HTMLDivElement>(null);
    var usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado') || '{}')
    var geoEstruturaLogado = sessionStorage.getItem('geoEstrutura')
    var grupoLogado = sessionStorage.getItem('grupo')



    const onClickMenu = () => {
        let footer_height = divRodape.current?.clientHeight || 0

        if (!aberto) {
            setAberto(true)
            footer_height = footer_height - 5;

            divMenuId.current!.style.marginBottom = footer_height + 'px';
            divMenuId.current!.style.backgroundColor = '#343a40ff'

        } else {
            setAberto(false)
            divMenuId.current!.style.marginBottom = '-515px';
            divMenuId.current!.style.backgroundColor = '#343a4000'

        }
    }

    const renderMenus = (menus: any) => {
        menus = menus?.filter((m: any) => m.possuiMenu === true);

        return menus.map((item: any, index: number) => {
            return <li id="item_menu" key={index} className="menuItemLi" >
                <div className={Style.alinhar_centro}>
                    <a className={Style.link_menu} onClick={() => { history.push(`/${item.navegarURL}`) }} >
                        < SettingOutlined className={Style.icon} />
                        <span>{item.nome}</span>
                    </a>
                </div>
            </li>
        })
    }

    const setarHiddenMenuUsuario = () => {
        return new Promise<any>((resolve, reject) => {
            if (menuUsuario) {
                setMenuUsuario(false);
                setMenuUsuarioHover(true);
                resolve(menuUsuario);
            }
            else {
                setMenuUsuario(true);
                setMenuUsuarioHover(false);
                resolve(menuUsuario)
            }
        })
    }

    const setarHiddenMenuUsuarioHover = (hover: boolean) => {
        return new Promise<any>((resolve, reject) => {
            if (menuUsuario) {
                if (menuUsuarioHover) {
                    setMenuUsuarioHover(false);
                    resolve(menuUsuarioHover)
                }
                else {
                    setMenuUsuarioHover(true);
                    resolve(menuUsuarioHover)
                }
            }
            else {
                setMenuUsuarioHover(true);
                resolve(menuUsuarioHover)
            }
        })
    }

    const scrollBaixo = (value: any) => {
        if (value) {
            divMenuId.current?.scrollTo(0, 0)
        }
    }

    const logout = () => {
        sessionStorage.clear();
        history.push(`/Login`);
    }

    return (
        <div className="agrupador">
            <div id="menuId" ref={divMenuId} className={Style.menu_aberto}>
                <div className={Style.menu_item} id="menu-item">
                    <div id="right-colummn" className={Style.menu}>
                        <div className={Style.perfil}>
                            <ul onMouseOver={() => { setarHiddenMenuUsuarioHover(true).then((response: any) => { scrollBaixo(response) }) }} onMouseOut={() => { setarHiddenMenuUsuarioHover(false).then((response: any) => { scrollBaixo(response) }) }} onClick={() => { setarHiddenMenuUsuario().then((response: any) => { scrollBaixo(response) }) }} id="menu-child-ul" className={Style.perfil_info}>
                                <li id="item_perfil" className={Style.item_perfil} key={10} >
                                    <a className={`${Style.btn_perfil}`} data-toggle="collapse" >
                                        < UserOutlined className={Style.icon} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="left-column" className={Style.menu}>
                        <ul id="menu-child-ul" className={`menu-child-ul ${Style.menu_lista}`}>
                            <li>
                                <div hidden={menuUsuario} ref={divSubMenuUsuario} id="submenu_perfil" className={`submenu collapse show ${Style.submenu_perfil}`} >
                                    <ul id="submenu-child-ul" className={Style.submenu_child_ul}>
                                        <li className={Style.nome_usuario}>
                                            <span>{usuarioLogado.nomeUsuario}</span>
                                        </li>
                                        <li>
                                            < SolutionOutlined className={Style.icon} />
                                            <span> {geoEstruturaLogado + " / " + usuarioLogado.nomeDominio}</span>
                                        </li>
                                        <li>
                                            < TeamOutlined className={Style.icon} />
                                            <span> {grupoLogado}</span>
                                        </li>
                                        <li id="logoutItem" className={Style.logoutItem}>
                                            <a className={Style.danger} onClick={() => { logout() }} >
                                                < ApiOutlined className={Style.icon} />
                                                <span> Sair</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div hidden={menuUsuarioHover} ref={divSubMenuUsuario} id="submenu_perfil" className={`submenu collapse show ${Style.submenu_perfil_hover}`} >
                                    <ul id="submenu-child-ul" className={Style.submenu_child_ul}>
                                        <li className={Style.nome_usuario}>
                                            <span>{usuarioLogado.nomeUsuario}</span>
                                        </li>
                                        <li>
                                            < SolutionOutlined className={Style.icon} />
                                            <span> {geoEstruturaLogado + " / " + usuarioLogado.nomeDominio}</span>
                                        </li>
                                        <li>
                                            < TeamOutlined className={Style.icon} />
                                            <span> {grupoLogado}</span>
                                        </li>
                                        <li id="logoutItem" className={Style.logoutItem}>
                                            <a className={Style.danger} onClick={() => { logout() }} >
                                                < ApiOutlined className={Style.icon} />
                                                <span> Sair</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {renderMenus(JSON.parse(sessionStorage.getItem('modulos') || '[]'))}

                        </ul>
                    </div>
                </div>
            </div>
            <div id="rodape" ref={divRodape} className={Style.rodape}>
                <div id="rodape_menu" className={Style.rodape_menu}>
                    <div className={Style.menu_btn}>
                        <button onClick={onClickMenu} type="button" id="my_menu" className={`btn btn-info ${Style.btn_menu}`} >
                            <AlignLeftOutlined className={Style.icon_menu} />
                            <span>Menu</span>
                        </button>
                        <img src={mascote} style={{ height: 50 }} />
                    <p style={{ margin: 14 }}>Consurso Mascote MS</p>
                    </div>
                </div>
                <div className={Style.logos_grid}>
                    <div className={Style.sgi_logo}>
                        <img src={logoBrancaSGI} />
                    </div>
                    <div className={Style.governo_logo}>
                        <img src={logoGoverno} />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Footer;


