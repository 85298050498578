import axios from 'axios';
import Token from './Token-Service/token-service';
// require('dotenv/config');

var token = '';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_API,
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
  }
});

api.interceptors.request.use(
  async (request) => {

    var dataAtual = new Date();

    if (request.url.indexOf('id.ms.gov.br') == -1) {

      let dataDoToken = new Date(sessionStorage.getItem('timeRefresh'));
      if (dataAtual >= dataDoToken) {

        await Token().then(response => {
          token = response;

        }).finally(() => { })
        request.headers['Authorization'] = 'Bearer ' + token + "";

        dataAtual.setSeconds(dataAtual.getSeconds() + 300);

        sessionStorage.setItem('timeRefresh', dataAtual);
      }
      else {
        request.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem("Token") + "";
      }
    } else {
      dataAtual.setSeconds(dataAtual.getSeconds() + 300);
      sessionStorage.setItem('timeRefresh', dataAtual);
    }
    return request
  },
  error => {

  }
);

export default api;