import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Select, message, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import MaskedInput from 'react-input-mask';
import moment from 'moment';
import Styles from '../root.module.scss';



interface FormDados {
    parentHandle: any
    inscricao: any
}

const FormDados: React.FC<FormDados> = (props: FormDados) => {
    let formRef = React.createRef<FormInstance>();
    const [menor, setMenor] = useState<Boolean>(false);
    const [erros, setErros] = useState<any>();
    const [mask1, setMask1] = useState("(99) 99999-9999");
    const [mask2, setMask2] = useState("(99) 99999-9999");
    const [ehEdicao, setEhEdicao] = useState(false);
    const [form] = Form.useForm();

    useEffect(()=>{
        form.setFieldsValue({
            nomeCompleto:props.inscricao?.user?.nome,
            rg: props.inscricao?.rg,
            orgaoEmissor: props.inscricao?.orgaoEmissor,
            cpf: props.inscricao?.user?.cpf,
            dataNascimento : props.inscricao?.dataNascimento != undefined ? moment(props.inscricao.dataNascimento).format('DD/MM/YYYY') : null,
            email1: props.inscricao?.user?.email,
            email2: props.inscricao?.email2,
            cpfResponsavel: props.inscricao?.cpfResponsavel,
            nomeResponsavel: props.inscricao?.nomeResponsavel,
            emailResponsavel: props.inscricao?.emailResponsavel,
            grauParentescoResponsavel: props.inscricao?.grauParentescoResponsavel,
            numeroProtocolo: props.inscricao?.numeroProtocolo,
            telefoneResponsavel: props.inscricao?.telefoneResponsavel,
        })
        ehMenor()
        
       
    },[props.inscricao])


    const unMasked = (input: string) => {
        return input
            .replace(/\D/g, "");
    };

    

    const onFinish = (values: any) => {
        setErros([]);
        let user: any = sessionStorage.getItem("usuarioLogado");
        let frag = values.dataNascimento.split("/");
        let data = `${frag[1]}/${frag[0]}/${frag[2]}`;
        let formatedDate = moment(data).format();
        values.cpfResponsavel = values.cpfResponsavel == undefined ? null : unMasked(values.cpfResponsavel)
        values.dataNascimento = formatedDate;
        values.aceiteEdital = true;
    };

    const avancarTab = () =>{
        props.parentHandle("2") 
    }

    
    const ehMenor = () => {
        var dataNascimento = new Date(moment(formRef.current!.getFieldValue("dataNascimento"), 'DD/MM/YYYY').toString());
        var dataAtual = new Date();

         //Retorna a diferença entre hoje e a data de nascimento em anos.
        var ano = dataAtual.getFullYear() - dataNascimento.getFullYear();
        
        // Retorna a diferença de mês do mês de nascimento para o atual.
        var m = dataAtual.getMonth() - dataNascimento.getMonth();
        //Caso ainda não tenha ultrapassado o dia e o mês
     
            if (m < 0 || (m === 0 && dataAtual.getDate() < dataNascimento.getDate())) {
                ano--;
            }

        if (ano < 18) {
            setMenor(true);

        } else {
            setMenor(false);
        }
        
    }
    
    

    
    return (

        <Form autoComplete="off" ref={formRef} form={form} name="control-ref" className={Styles.formulario} onFinish={onFinish} style={{ padding: '35px' , marginBottom:'5em'}}>
            <fieldset >
                <h1 className={Styles.tituloResponsavel}><b>Dados Participante</b></h1>
                <hr style={{ marginBottom: '10px' }}></hr>
                <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="nomeCompleto"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Nome Completo"
                >
                    
                    <Input className={Styles.item} disabled />
                </Form.Item>

                <div className={Styles.groupItem}>
                    <Form.Item
                        style={{ marginTop: '-10px', width: '70%' }}
                        name="rg" label="RG"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        
                    >
                        <Input className={Styles.item} disabled />
                    </Form.Item>
                    <Form.Item
                        style={{ marginTop: '-10px', width: '30%' }}
                        name="orgaoEmissor"
                        label="Órg. Emissor"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        
                    >
                        <Input className={Styles.item} disabled/>
                    </Form.Item>
                </div>

                <div className={Styles.groupItem}>

                    <Form.Item
                        style={{ marginTop: '-10px', width: '50%' }}
                        name="cpf"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="CPF"
                    >
                        {/* <Input className={Styles.item} /> */}
                        <MaskedInput mask="999.999.999-99" className={Styles.inputDisabled} disabled />
                    </Form.Item>

                    <Form.Item
                        style={{ marginTop: '-10px', width: '50%' }}
                        name="dataNascimento"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Data Nascimento"

                        >
                        <MaskedInput mask="99/99/9999" className={Styles.inputDisabled} disabled />
                    </Form.Item >

                </div>

                <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="email1"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-mail 1"
                >
                    <Input className={Styles.item} disabled />
                </Form.Item>

                <Form.Item
                    style={{ marginTop: '-10px' }}
                    name="email2"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-mail 2"
                >
                    <Input type="email" className={Styles.item} disabled/>
                </Form.Item>

                <Form.Item
                    style={{ display: 'none' }}
                    name="userID"
                    
                >
                    <Input style={{ display: 'none' }} disabled />
                </Form.Item>
                
                <Form.Item className={Styles.dadosResponsavel}
                    noStyle
                >
                    
                    {
                        menor === true ?
                            <div>
                                <h1 className={Styles.tituloResponsavel}><b>Dados Representante Legal</b></h1>
                                <hr style={{ marginBottom: '5%' }}></hr>
                                <div className={Styles.groupItem}>

                                    <Form.Item
                                        style={{ marginTop: '-10px', width: '50%' }}
                                        name="cpfResponsavel"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        label="CPF Representante Legal"
                                        
                                    >
                                        <MaskedInput mask="999.999.999-99" className={Styles.inputDisabled} disabled/>
                                    </Form.Item>

                                    <Form.Item
                                        style={{ marginTop: '-10px', width: '50%' }}
                                        name="grauParentescoResponsavel"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        label="Grau de Parentesco"
                                        
                                    >
                                        <Input className={Styles.inputDisabled} disabled/>
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    style={{ marginTop: '-10px' }}
                                    name="nomeResponsavel"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    label="Nome do Representante Legal"
                                   
                                >
                                    <Input className={Styles.inputDisabled} disabled/>
                                </Form.Item>

                                <Form.Item
                                    style={{ marginTop: '-10px', width: '100%' }}
                                    name="telefoneResponsavel"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    label="Telefone do Representante Legal"
                                    

                                >
                                    <MaskedInput className={Styles.inputDisabled} alwaysShowMask={false} mask={mask2}
                                        onBlur={e => {
                                            if (e.target.value.replace("_", "").length === 14) {
                                                setMask2("(99) 9999-9999");
                                            }
                                        }}
                                        onFocus={e => {
                                            if (e.target.value.replace("_", "").length === 14) {
                                                setMask2("(99) 99999-9999");
                                            }
                                        }} disabled/>

                                </Form.Item>
                                
                                    <Form.Item
                                        style={{ marginTop: '-10px' }}
                                        name="emailResponsavel"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        label="Email do Representante Legal"
                                       
                                    >
                                        <Input type="email" className={Styles.inputDisabled} disabled/>
                                    </Form.Item>
                          
                        </div>
                        : null
                }
            </Form.Item>


            </fieldset>
            <div className={Styles.buttonsAreaDados}>
                <Button type="primary" className={Styles.proximo} htmlType="submit" onClick={avancarTab}>
                    Próximo
                </Button>
            </div>
        </Form >


    )
}

export default FormDados;
