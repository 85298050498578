import Consulta from '../../components/Consulta/consulta-component'
import { useState, useEffect } from 'react';
import { FiltroConsultaModel } from '../../../features/Models/filtro-model';
import EnumeradorService from '../../../services/Enumerador-Service/enumerador-service';
import FiltroConsulta from '../../components/FiltroConsulta/filtroConsulta-component';
import { Select, Form, Button } from 'antd';
import InscricaoService from '../../../services/Inscricao-Service/inscricao-service';
import Header from '../../components/Header/header-component';
import Footer from '../../components/Footer/footer-component';
import HistoricoProcessoService from '../../../services/HistoricoProcesso-Service/historicoProcesso-service';
import { useHistory, useParams } from 'react-router-dom';
import style from './listagem-avaliacao.module.css';
import moment from 'moment';
import { RespostaWebAPI } from '../../../features/Models/respostawebapi-model';
import { UserOutlined } from '@ant-design/icons';
import Item from 'antd/lib/list/Item';




const ListagemAvaliacao: React.FC<any> = (props) => {

  const [dados, setDados] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { concursoID, julgadorID } = useParams<any>();
  const history = useHistory();
  const [situacaoAvaliacaoENUM, setSituacaoAvaliacaoENUM] = useState<any[]>([])
  const [legenda, setLegenda] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true)
    EnumeradorService.get("ESituacaoAvaliacaoENUM").then((response: any) => { setSituacaoAvaliacaoENUM(response.data) })
      .finally(() => setLoading(false))

    pesquisar({});
  }, [])

  useEffect(()=>{
     HistoricoProcessoService.getByJulgadorConcursoID(julgadorID,concursoID).then((response: any) =>{ setLegenda(response.data)
      sessionStorage.setItem("concursoID",concursoID)
      sessionStorage.setItem("julgadorID",julgadorID)
    })
  },[])

  const SelectEnum: React.FC<any> = (props) => {
 

    useEffect(() => {
      setLoading(true)
      EnumeradorService.get(props.tipoEnumerador).then((response: any) => { setEnums(response.data) })
        .finally(() => setLoading(false))

    }, [])
    const [enums, setEnums] = useState<any>();
    const [loading, setLoading] = useState(false);

    return (
      <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={false} key={456}
        name={props.name}
        component={
          <Select loading={loading} defaultValue={""} placeholder="selecione" >
            <Select.Option value="">Selecione</Select.Option>
            {enums?.map((item: any, index: number) => <Select.Option key={index} value={item.enum}>{item.texto}</Select.Option>)}
          </Select>
        } />
    );

  }


  let colunas: any = [
    {
      title: 'ID do Desenho', dataIndex: 'desenhoID', width: 200, align: 'center',
      defaultSortOrder: 'ascend',
      render: (text: any, record: any) => (btoa(moment(record?.desenho?.dataHoraInclusao).format("DDMMYYYY") + record?.desenhoID)?.replace("==", ""))
    },
    {
      title: 'Modalidade', dataIndex: 'telefone1', width: 150, align: 'center',
      render: (text: any, record: any) => (record?.desenho?.modalidadeDesenho?.descricao)
    },
    {
      title: 'Categoria', dataIndex: 'email', width: 300, align: 'center',
      render: (text: any, record: any) => (record?.desenho?.inscricao?.categoria1?.nome)
    },

    {
      title: 'Situação', dataIndex: 'situacaoAvaliacaoENUM', width: 150, align: 'center',
      render: (text: any) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <p className={
            `${style.situacaoTableDesenho}
            ${style[text || 'Nenhuma']}`}>
            {situacaoAvaliacaoENUM?.filter((item) => item?.enum == text)[0]?.texto}</p>
        </div>
      ),
    },
    {
      title: 'Ações', dataIndex: '', width: 150, align: 'center',
      render: (text: any, record: any) => (
        <>
          {record?.situacaoAvaliacaoENUM != "Avaliado" ?
            <Button onClick={() => history.push(`/avaliacao/desenho/${record.desenhoID}`)}>
              Avaliar </Button>
            :
            null
          }
        </>
      ),
      fixed: 'right' as 'right',
    }

  ]


  const headerPanel = (
    <>
    <div className={style.headerpanel}>
      <div className={style.legenda}>
      <ul>
        <li>Total de desenhos avaliados: {legenda.filter((item:any) => item.situacaoAvaliacaoENUM == "Avaliado").length } <UserOutlined /></li>
        <li>Total de desenhos à serem avaliados: {legenda.filter((item:any) => item.situacaoAvaliacaoENUM == "EmAvaliacao").length + legenda.filter((item:any) => item.situacaoAvaliacaoENUM == "Em Avaliação").length } <UserOutlined /></li>
      </ul>
      </div>
    </div>
    </>
  )

  const opcoesDeFiltros: FiltroConsultaModel[] = [
    {
      name: 'situacao',
      label: 'Situação',
      componentChild: <></>,
      reactElementHtml: <SelectEnum name="situacao" label="Situação" tipoEnumerador={"ESituacaoAvaliacaoENUM"} />,
      required: false
    }
  ]
  const pesquisar = (filtros:any) => {    
    setLoading(true);
    HistoricoProcessoService.getByJulgadorConcursoID(julgadorID, concursoID).then((response: RespostaWebAPI<any>) => {
      if(response.success) {   
        if(filtros.situacao != undefined && filtros.situacao != "" && response.data != null && response.data.length > 0)
        response.data = response?.data?.filter((hp:any)=> hp.situacaoAvaliacaoENUM == filtros.situacao)        

        setDados(response.data)
      }
      else {
        setDados([])
      }
      
    })
      .finally(() => setLoading(false))
  }


  return (
    <>
      <Header />
      <Consulta
        legendaStatus={headerPanel}
        colunasTable={colunas}
        dadosTable={dados}
        scrollTable={{ x: 100, y: 400 }}
        pageSizeTable={15}
        rowKeyTable={"inscricaoID"}
        filtros={opcoesDeFiltros}
        pesquisar={pesquisar}
        form={form}
        loading={loading}
        indicesFiltrosPreSelecionados={[0]}
      />
      <Footer />

    </>
  )
}

export default ListagemAvaliacao;