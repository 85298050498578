import React, { useEffect, useState } from 'react'
import UserService from '../../../services/User-Service/user-service';
import Footer from '../../components/Footer/footer-component';
import Header from '../../components/Header/header-component';
import JulgadorComponent from '../../components/Julgador/julgador-component';
import Banner from '../../../assets/img/BannerConcursoEditado2.png'
import Styles from './home-page.module.scss'
import { DownloadOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

const HomePage: React.FC<any> = () => {
  const [grupoID, setGrupoID] = useState("");

  useEffect(() => {
    setGrupoID(sessionStorage.getItem("grupoID") || "");
  })

  const dowloadManualComissaoOrganizadora = () => {
    fetch('ManualComissaoOrganizadora.pdf').then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = fileURL;
        link.download = 'ManualComissaoOrganizadora.pdf';
        link.click();
        link.remove();
      })
    })
  }

  const dowloadManualComissaoJulgadora = () => {
    fetch('ManualComissaoJulgadora.pdf').then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = fileURL;
        link.download = 'ManualComissaoJulgadora.pdf';
        link.click();
        link.remove();
      })
    })
  }

  const dowloadManual = () => {
    fetch('ManualDeUso.pdf').then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = fileURL;
        link.download = 'ManualDeUso.pdf';
        link.click();
        link.remove();
      })
    })
  }

  return (
    <>
      <Header></Header>
      {UserService.ehComissaoJulgadora(grupoID) ?
        <JulgadorComponent></JulgadorComponent>
        :
        <>
          <div className={Styles.AreaBanner}>
            <div>
              <Row>
                <Col span={24}>
                  <img src={Banner} width={1000} height={280} />
                </Col>
              </Row>
              <Row style={{ margin: '10px' }}>
                <Col span={24} className={Styles.manuaisUso}>
                  <h2 style={{ fontSize: '25px' }}>
                    Manuais de Uso do Sistema:
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <button className={Styles.buttonDownload} onClick={() => { dowloadManualComissaoOrganizadora() }} >  Comissão Organizadora <DownloadOutlined /> </button>
                </Col>
                <Col span={8}>
                  <button className={Styles.buttonDownload} onClick={() => { dowloadManualComissaoJulgadora() }} >  Comissão Julgadora <DownloadOutlined /> </button>
                </Col>
                <Col span={8}>
                  <button className={Styles.buttonDownload} onClick={() => { dowloadManual() }} >  Área Pública <DownloadOutlined /> </button>
                </Col>
              </Row>
            </div>

          </div>

        </>
      }
      <Footer />
    </>
  )
}

export default HomePage;
