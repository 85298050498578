import React, { useEffect, useState } from 'react'
import Style from './modalDesclassificacaoSelecao-component.module.less';
import { Modal, Button, Form, message, Row, Col, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import { HistoricoProcessoModel } from '../../../features/Models/historicoProcesso-model';
import { MotivoDesclassificacaoModel } from '../../../features/Models/motivoDesclassificacao-model';
import HistoricoProcessoService from '../../../services/HistoricoProcesso-Service/historicoProcesso-service';
import MotivoDesclassificacaoService from '../../../services/MotivoDesclassificacao-Service/motivoDesclassificacao-service';
import { useParams } from 'react-router-dom';
import { RespostaWebAPI } from '../../../features/Models/respostawebapi-model';

interface ModalDesclassificacaoSelecaoComponentProps {
    visivel: boolean;
    desenhoID: number;
    concursoID: number;
    setVisivelFalse?: any;
    historicoProcesso?: HistoricoProcessoModel,
    consultarDesenhos:any
}

const ModalDesclassificacaoSelecaoComponent: React.FC<ModalDesclassificacaoSelecaoComponentProps> = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);    
    const [motivoDesclassificacaos, setMotivoDesclassificacaos] = useState<MotivoDesclassificacaoModel[]>()    
    let motivosDesclassificacaoID: Array<number> = new Array<number>();
    let { id } = useParams<any>();

    useEffect(() => {
        MotivoDesclassificacaoService.getByMotivoDesclassificacaoPorDados(id,false).then((response) => {
            setMotivoDesclassificacaos(response.data)
        })

    }, [visible])

    useEffect(() => {
        setVisible(props.visivel)
        form.resetFields();
    }, [props.visivel])

    const checkValue = (value: any) => {
        let motivoDesclassificacaoID = parseInt(value.target.id.slice(6));
        if (value.target.checked) {
            motivosDesclassificacaoID.push(   motivoDesclassificacaoID)                   
        }
        else {
            motivosDesclassificacaoID.map((item: any, index: number) => {
                if (motivoDesclassificacaoID == item.motivoDesclassificacaoID) {
                    motivosDesclassificacaoID.splice(index, 1);
                }
            })
        }
    }

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            await HistoricoProcessoService.getByDesenhoID(props.desenhoID).then(async (resp: RespostaWebAPI<HistoricoProcessoModel>) => {
                if (resp.success) {
                    if(motivosDesclassificacaoID.length == 0) {
                        message.error("selecione ao menos um motivo")
                        return;
                    }
                    let historicoProcesso = resp.data                
                    let pessoa = JSON.parse(sessionStorage.getItem('usuarioLogado') || '')                                     
                    setLoading(true)
                    await HistoricoProcessoService.desclassificar(
                        historicoProcesso.historicoProcessoID || 0,
                        motivosDesclassificacaoID,
                        pessoa.cpf)
                        .then(() => {props.setVisivelFalse(), props.consultarDesenhos(),message.success("Desenho desclassificado com sucesso")})
                        .finally(() => setLoading(false))
                }
            })
        }
        catch (error) {
            setLoading(false)
            props.setVisivelFalse();
        }
    };
    return (
        <Modal
            visible={props.visivel}
            title={<h3 >Motivo(s) da desclassificação</h3>}
            onCancel={props.setVisivelFalse} footer={null}>
            <Form name="basic" form={form}
                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                onFinish={onFinish}>
                {motivoDesclassificacaos?.map((item: any, index: number) =>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                id={item.motivoDesclassificacaoID}
                                name={item.motivoDesclassificacaoID}
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Checkbox onChange={(e) => { checkValue(e) }}> {item.descricao}</Checkbox>

                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className={Style.button} span={12}>
                        <Button onClick={props.setVisivelFalse} className="button-pre-cadastro" type="primary" htmlType="button">
                            Voltar
                        </Button>
                    </Col>
                    <Col className={Style.button} span={12}>
                        <Form.Item>
                            <Button className="button-pre-cadastro" danger type="primary" htmlType="submit">
                                Desclassificar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    );
};

export default ModalDesclassificacaoSelecaoComponent;