import { OperacoesWebAPI } from '../../utils/global';
import { AxiosResponse } from 'axios';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'

interface IPermissaoModel {
    permissaoID: number,
    moduloID: number,
    perfilID: number,
    consultar: boolean,
    cadastrar: boolean,
    editar: boolean,
    excluir: boolean,
}

interface IPermissaoFiltroModel {
    permissaoID?: number,
    moduloID?: number,
    perfilID?: number,
}

const PermissaoService = {

    get: async (): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Permissao}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    getComFiltro: async (permissaoFiltroModel: IPermissaoFiltroModel): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Permissao}?permissaoid=${permissaoFiltroModel.permissaoID}&moduloid=${permissaoFiltroModel.moduloID}&perfilid=${permissaoFiltroModel.perfilID}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    getPorModuloPerfil: async (permissaoFiltroModel: IPermissaoFiltroModel): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Permissao}/getbymoduloperfil?moduloID=${permissaoFiltroModel.moduloID}&perfilID=${permissaoFiltroModel.perfilID}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    getByID: async (): Promise<RespostaWebAPI<any>> => {
        return Api.get(`${OperacoesWebAPI.Permissao}/getdominios`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },

    post: async (permissaoModel: IPermissaoModel): Promise<RespostaWebAPI<any>> => {
        return Api.post(`${OperacoesWebAPI.Permissao}`, permissaoModel)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    put: async (permissaoModel: IPermissaoModel): Promise<RespostaWebAPI<any>> => {
        return Api.put(`${OperacoesWebAPI.Permissao}`, permissaoModel)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    delete: async (permissaoID: number): Promise<RespostaWebAPI<any>> => {
        return Api.delete(`${OperacoesWebAPI.Permissao}?id=${permissaoID}`)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    }
}

export default PermissaoService;
export type { IPermissaoModel }