import { AxiosResponse } from 'axios';
import { HistoricoProcessoModel } from '../../features/Models/historicoProcesso-model';
import { RespostaWebAPI } from '../../features/Models/respostawebapi-model';
import Api from '../index'

const DesenhoService = {

    getByInscricaoID: async (inscricaoID: number): Promise<any> => {
        return await Api.get(`/desenho/getbyinscricaoid?inscricaoid=${inscricaoID}`).then((res: any) => {
            return res.data
        });
    },
    getByConcursoID: async (concursoID: number): Promise<any> => {
        return await Api.get(`/desenho/getbyconcursoid?concursoid=${concursoID}`).then((res: any) => {
            return res.data
        });
    },
    getByDesenhoID: async (desenhoID: number): Promise<any> => {
        return await Api.get(`/desenho/getbydesenhoid?desenhoid=${desenhoID}`).then((res: any) => {
            return res.data
        });
    },
    get: async (): Promise<any> => {
        return await Api.get(`/desenho`).then((res: any) => {
            return res.data
        });
    },
    post: async (modalidadeDesenhoID: number, inscricaoID: number, formData: any): Promise<any> => {
        return await Api.post(`desenho?inscricaoID=${inscricaoID}&modalidadeDesenhoID=${modalidadeDesenhoID}`, formData)
            .then((res) => { return res.data })
    },
    delete: async (desenhoID: string): Promise<any> => {
        return await Api.delete(`desenho?id=${parseInt(desenhoID)}`).then((res) => { return res.data })
    },
    downloadDesenhoBase64ById: async (desenhoID: number): Promise<any> => {
        return await Api.get(`/desenho/downloaddesenhobase64byid?desenhoID=${desenhoID}`).then((res: any) => {
            return res.data
        });
    },
    downloadDiarioBase64ById: async (diarioID: number): Promise<any> => {
        return await Api.get(`/desenho/downloaddiariodebordobase64byid?diarioDeBordoID=${diarioID}`).then((res: any) => {
            return res.data
        });
    },
    getDesenhosComBase64: async (inscricaoID: number): Promise<any> => {
        return await Api.get(`/desenho/getdesenhoscombase64?inscricaoID=${inscricaoID}`).then((res: any) => {
            return res.data;
        })
    },
    desclassificarDesenho: async (historico: HistoricoProcessoModel): Promise<any> => {
        const body = JSON.stringify(historico);
        return await Api.post(`/desenho/deslassificarDesenho`, body).then((res: any) => {
            return res.data;
        })
    },
    getQuantitativoDesenhos: async (): Promise<any> => {
        return await Api.get(`/desenho/getquantitativodesenhos`).then((res: any) => {
            return res.data;
        })
    },
    postAvaliarDesenho: async (listAvaliacao: Array<any>): Promise<any> => {
        const body = JSON.stringify(listAvaliacao);
        return await Api.post(`/desenho/avaliardesenho`, body).then((res: any) => {
            return res.data;
        })
    },
    postFiltroDesenhosAvaliados: async (model: any): Promise<any> => {
        return Api.post(`desenho/postfiltrosdesenhosavaliados`, model)
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
    classificarPorNota: async (concursoID: any): Promise<any> => {
        return Api.post(`desenho/classificarPorNota?concursoID=${concursoID}` )
            .then((axiosResponse: AxiosResponse<RespostaWebAPI<any>>) => {
                return axiosResponse.data
            })
    },
}

export default DesenhoService;