import React, { useState, useEffect } from 'react';
import { Row, Input, Table, Col, Popconfirm, Empty } from 'antd';
import { Form, Button, Select, Space, message } from 'antd';
import Header from '../../components/Header/header-component';
import Footer from '../../components/Footer/footer-component';
import ModuloService from '../../../services/Modulo-Service/modulo-service';
import { useAppDispatch, useAppSelector } from '../../../features/hooks';
import { moduloModalState, setModalEditar, setModalCadastrar } from '../../../features/slices/modulo/moduloModal-slice';
import Style from './modulo-page.module.less'
import ModalModulo from '../../components/ModalModulo/modalModulo-component';

const ModuloPage: React.FC<any> = (props) => {
    let modulo = useAppSelector(moduloModalState);
    const dispatch = useAppDispatch();

    const [Modulos, setModulos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {

        if (modulo.visibleModalCadastrar === false ||
            modulo.visibleModalEditar === false) {
            getModulos();
        }
    }, [modulo])

    const novoModulo = async (values: any) => {

        // if (props.permissao[0].permissaoAcesso?.cadastrar === false) {
        // message.warn("Usuário não possui permissão de cadastro");
        // return;
        // }
        dispatch(setModalCadastrar({
            visibleModalCadastrar: true
        }))

    }

    const editarModulo = (values: any) => {

        // if (props.permissao[0].permissaoAcesso?.editar === false) {
        // message.warn("Usuário não possui permissão de edição");
        // return;
        // }

        dispatch(setModalEditar({
            moduloID: values.moduloID,
            nome: values.nome,
            navegarURL: values.navegarURL,
            ativo: values.ativo,
            possuiMenu: values.possuiMenu,
            visibleModalCadastrar: false,
            visibleModalEditar: true,
        }))
    }


    const excluirModulo = async (id: any) => {
        setLoading(true);
        await ModuloService.delete(id).then(response => {

            message.success('Modulo excluído com sucesso!');
            ModuloService.getPorPerfil(sessionStorage.getItem('grupoID')).then((response) => {
                sessionStorage.setItem('modulos', JSON.stringify(response.data));
            })
        }).catch(() => {
            message.error('Falha na requisição');
        }).finally(() => {
            getModulos();
            setLoading(false);
        })
    }

    const getModulos = async () => {
        ModuloService.get()
            .then(response => {
                setModulos(response.data);
            })
            .catch(response => {
                message.error('Falha na requisição');
            })

    }

    const columns = [
        { title: "Nome", dataIndex: "nome", key: "nome", width: 300 },
        { title: "URL", dataIndex: "navegarURL", key: "url", width: 300 },
        {
            title: "Status", dataIndex: "ativo", key: "status",
            render: (record: any) => (
                <p>{record ? 'ativo' : 'inativo'}</p>
            ),
            width: 200
        },
        {
            title: "Possui Menu", dataIndex: "possuiMenu", key: "possuiMenu",
            render: (record: any) => (
                <p>{record ? 'Sim' : 'Não'}</p>
            ),
            width: 200
        },

        {
            title: 'Ações',
            key: 'action',
            render: (record: any) => (
                <Space size="middle">
                    {/* {props.permissao[0].permissaoAcesso?.editar ? */}
                    <Button htmlType="button" onClick={() => editarModulo(record)}>Editar</Button>
                    {/* : null} */}
                    {/* {props.permissao[0].permissaoAcesso?.excluir ? */}
                    <Popconfirm key={record.moduloID} placement="top" title="Você tem certeza que deseja excluir esse modulo?" onConfirm={() => { excluirModulo(record.moduloID); }} okText="Sim" cancelText="Não">
                        <Button type="primary" key={record.moduloID} danger >  Excluir </Button>
                    </Popconfirm>
                    {/* : null */}
                    {/* } */}
                </Space>
            ),
            fixed: 'right' as 'right',
            width: 200
        }]

    const pesquisar = () => {
        let filtros = form.getFieldsValue();
        let newParams = {
            'nome': filtros.nome ? filtros.nome : "",
            'ativo': filtros.ativo,
        }
        ModuloService.getComFiltro(newParams)
            .then(response => {
                setModulos(response.data);
            })
            .catch(response => {
                message.error('Falha na requisição');
            })
    }

    return (
        <>
            <Header />
            <br />
            <div className={Style.container}>
                <fieldset className={Style.fieldset_consulta}>
                    <Form
                        form={form}
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        size="middle"
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>

                                <Form.Item name="nome" label="nome">
                                    <Input placeholder="Digite o nome do Módulo" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="ativo" label="Status" initialValue={0}>
                                    <Select placeholder="Selecione">
                                        <Select.Option value={0}> Selecione </Select.Option>
                                        <Select.Option value={1}> Ativo </Select.Option>
                                        <Select.Option value={2}> Inativo </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item>
                                    {/* {props.permissao[0].permissaoAcesso?.cadastrar ? */}
                                    <Button type="ghost" htmlType="button" onClick={novoModulo}>Novo Módulo</Button>
                                    {/* : null */}
                                    {/* } */}
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button onClick={pesquisar} className={Style.button_filtrar} type="primary" htmlType="submit">
                                        Filtrar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </fieldset>
                <br />
                <Row>
                    <Col className="gutter-row" span={24}>
                        <Table
                            locale={{emptyText:<Empty description="Sem registro!" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}}
                            className="ant-table-var-local"
                            columns={columns}
                            dataSource={Modulos}
                            scroll={{ x: 900,  y: 300  }}
                            rowKey={"moduloID"}
                            size={'small'}                              
                            bordered={true}>

                        </Table>
                    </Col>
                </Row>
            </div>
            <ModalModulo />

            <Footer />
        </>);
}

export default ModuloPage;