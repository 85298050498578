import React, { useState, useEffect } from 'react';
import { Empty, Select } from 'antd';
import 'antd/dist/antd.css';
import FiltroConsulta from '../FiltroConsulta/filtroConsulta-component';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';

interface FiltroClassificadoPopularPorConcursoProps {
  removerFiltro?: any
  label: any,
  name: string,
  required: boolean,
  key: number
}

export const FiltroClassificadoPopularPorConcurso: React.FC<FiltroClassificadoPopularPorConcursoProps> = (props) => {
  const [categorias, setCategorias] = useState<any>()
  const [loading, setLoading] = useState(false);
  const [concursoSelecionado, setConcursoSelecionado] = useState<any>()
  useEffect(() => {
    esperarSelecaoFiltroConcurso();
  }, [])

  const esperarSelecaoFiltroConcurso = () => {
    document.addEventListener("concursoID", (e: any) => {
      setLoading(true);
      ConcursoService.getbyconcursoid(e?.detail?.concursoID).then((response) => {                   
        setConcursoSelecionado(response)
      }).finally(() => setLoading(false))
    })
  }
  return (
    <>
      <FiltroConsulta removerFiltro={props.removerFiltro} label={props.label} required={props.required} key={props.key}
        name={concursoSelecionado != undefined ? concursoSelecionado.classificacaoPorNota ? `${props.name}classificadoPopularPorNota` : `${props.name}classificadoPopularPorComissao` : "autoName"}
        component={
          <Select
            notFoundContent={<Empty style={{ color: 'black' }}
              description={<p>Selecione algum valor no filtro <b>'Concurso'</b> para aparecer as opções desse filtro. Caso o concurso não tenha categorias esse filtro não tera valores</p>} />}
            loading={loading} placeholder="Selecione"
          >            
            
            {concursoSelecionado != undefined ? <>
              <Select.Option value={""}>Selecione</Select.Option>
              <Select.Option value={true}>Sim</Select.Option>
              <Select.Option value={false}>Não</Select.Option>
            </> : null
            }
          </Select>
        } />
    </>
  );
}