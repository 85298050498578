import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Input, message, DatePicker, Row, Col, Select, ConfigProvider, AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import ConcursoService from '../../../services/Concurso-Service/concurso-service';
import InscricaoService from '../../../services/Inscricao-Service/inscricao-service';
import DesenhoService from '../../../services/Desenho-Service/desenho-service';

interface ModalClassificarPorNotaComponentProps {
    visivel: boolean
    setVisivelFalse: any
}
const ModalClassificarPorNotaComponent: React.FC<ModalClassificarPorNotaComponentProps> = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [concursos, setConcursos] = useState<any>()

    useEffect(() => {
        ConcursoService.get().then((response) => setConcursos(response.data))
    }, [])

    useEffect(() => {
        form.resetFields();
    }, [props.visivel])

    const onFinish = (values: any) => {
        setLoading(true);
        try {
            DesenhoService.classificarPorNota(values?.concursoID).then(() => {
                message.success("Desenhos classificados com sucesso!")
                props.setVisivelFalse();
            })
                .catch((err) => {
                    message.error('Falha na requisição')
                })
                .finally(() => setLoading(false))

        }
        catch {
            setLoading(false)
            props.setVisivelFalse();
        }
    };

    return (
        <Modal
            visible={props.visivel} className="NoneUpperCase"
            title={<p>Classificar as avaliações por nota</p>}
            onCancel={props.setVisivelFalse} footer={null}>
            <Form name="basic" form={form}
                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                onFinish={onFinish}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={24}>
                        <label>
                            <p>Selecionar abaixo o concurso que deseja classificar por nota.
                            </p>

                        </label>
                        <Form.Item
                            label={"Concurso"}
                            name="concursoID"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, informe este campo!',
                                }]} >
                            <Select loading={loading} placeholder="Selecione">
                                {concursos?.map((item: any, index: number) => {
                                    return <Select.Option key={index} value={item?.concursoID}>{item?.concursoID} - {item?.titulo}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button className="button-pre-cadastro" type="primary" htmlType="submit">
                        Classificar
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    );
}

export default ModalClassificarPorNotaComponent;