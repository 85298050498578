import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, notification, Row, Col, Popover, Modal, Checkbox, Tooltip, Empty } from 'antd';
import { FormInstance } from 'antd/es/form';
import { CheckOutlined, FileOutlined, HistoryOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Upload, Popconfirm, message, Spin, Table } from 'antd';
import style from './FormUpload-component.module.scss';
import Styles from '../root.module.scss'
import ModalidadeDesenhoService from '../../../../services/ModalidadeDesenho-Service/modalidadeDesenho-service';
import DesenhoService from '../../../../services/Desenho-Service/desenho-service';
import { DesenhoModel } from '../../../../features/Models/desenho-model'
import pdfPlaceHolder from '../../../../assets/img/pdfPlaceHolder.png'
import ModalDesclassificacaoSelecaoComponent from '../../ModalDesclassificacaoSelecao/modalDesclassificacaoSelecao-component'
import HistoricoProcessoService from '../../../../services/HistoricoProcesso-Service/historicoProcesso-service';
import { JsxElement } from 'typescript';


interface FormUpload {
    parentHandle: any,
    inscricao: any,
    atualizarLegenda: any
}

const FormUpload: React.FC<FormUpload> = (props: FormUpload) => {

    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [fileDesenho, setFileDesenho] = useState<any>();
    const [fileDiarioDeBordo, setFileDiarioDeBordo] = useState<any>();
    const [loadingArquivos, setLoadingArquivos] = useState(false);
    const [modalidadeDesenho, setModalidadeDesenho] = useState<any>([]);
    const [modalidadeDesenhoSelecionada, setModalidadeDesenhoSelecionada] = useState<any>();
    const [typePermitido, setTypePermitido] = useState(false);
    let { id } = useParams<any>();
    const [desenhoTabela, setDesenhoTabela] = useState(0);
    const [textoUpload, setTextoUpload] = useState<any>();
    const [textoDiario, setTextoDiario] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)
    const [dados, setDados] = useState<{ key: number, descricaoModalidade: string, diarioDeBordo: any, desenho: DesenhoModel }[]>([]);
    const [visibilityConfirmAprovarDesenho, setVisibilityConfirmAprovarDesenho] = useState(false)        
    const [dowloadDiario, setDowloadDiario] = useState<{ desenhoID: number, fezDownload: boolean }>({ desenhoID: 0, fezDownload: false });
    const [dowloadDesenho, setDowloadDesenho] = useState<{ desenhoID: number, fezDownload: boolean }>({ desenhoID: 0, fezDownload: false });
    const fezDowloadDesenho = (desenhoID:any)=> {        
        return dowloadDesenho.desenhoID == desenhoID && dowloadDesenho.fezDownload
    }
    const fezDowloadDiario = (desenhoID:number)=> {
        return dowloadDiario.desenhoID == desenhoID && dowloadDiario.fezDownload
    }
    useEffect(() => {
       
            consultarDesenhos();
        
    }, [])

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    let formRef = React.createRef<FormInstance>();

    const voltarTab = () => {
        props.parentHandle("3");

    }
    const consultarDesenhos = () => {
        setLoading(true)
        props.atualizarLegenda()
        DesenhoService.getDesenhosComBase64(props.inscricao?.inscricaoID)
            .then(resp => {
                let arquivosResponse: any[] = [];
                resp.data.map((d: any) => {

                    arquivosResponse.push({
                        key: d.desenhoID,
                        descricaoModalidade: d.modalidadeDesenho.descricao,
                        diarioDeBordo: d.diarioDeBordo,
                        desenho: d,

                    })

                })
                setDados(arquivosResponse)

            }).finally(() => {
                setLoading(false)

            })
    }

    const textToolTipBaixar = <p>Clique no desenho para baixa-lo</p>
    function RenderSituacao( props: any){
        const [motivos,setMotivos] = useState<any>();
        const situacao = props.record?.desenho?.historicoProcessos[0]?.situacaoSelecaoENUM;
        useEffect(() => {
            HistoricoProcessoService.getMotivosDesclassificacao(props.record?.desenho?.historicoProcessos[0]?.historicoProcessoID).then(t => {
                setMotivos(t);
            }).catch().finally();

        }, []);

        const textMotivos = motivos?.data?.map((m:any) => m.motivoDesclassificacao.descricao);
        const textToolTip = <ul>{textMotivos?.map((t:any)=> <li>{t}</li>)}</ul>          

       return(
            <>
            {situacao == 'Desclassificado'?
                <>
                <Spin spinning={loading}/>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Tooltip placement="rightTop" overlayStyle={{backgroundColor:'red', width:'500px'}} title={textToolTip}>
                    <p className={
                        `${style.situacaoTableDesenho}
                        ${style[situacao || 'Nenhuma']}`}
                    >   
                        {situacao}
                    </p>
                    </Tooltip>
                </div>
                </>
                :
                <>
                <Spin spinning={loading}/>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p className={
                        `${style.situacaoTableDesenho}
                        ${style[situacao || 'Nenhuma']}`}
                    >   
                        {situacao}
                    </p>
                </div>
                </>
            }
            
            </>
        );
    }
    const colunas: any = [
        {
            title: 'Modalidade',
            dataIndex: 'descricaoModalidade',
            key: 'descricaoModalidade',
            width: '30%',
            align: 'left',
        },
        {
            title: 'Desenho',
            dataIndex: '',
            align: 'center',
            key: 'x',
            render: (text: any, record: any) => (
                <>
                    {record.desenho.contentType == "application/pdf" ?
                        <>
                            <Tooltip placement='top' overlay={textToolTipBaixar}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button className={fezDowloadDesenho(record?.desenho?.desenhoID) ? style.buttonAberto : style.buttonAbrir}
                                        onClick={(e: any) => { baixarArquivoDesenho(record.key, record.desenho.contentType, record.desenho.nomeArquivo) }}
                                    >
                                        <img
                                            className={style.colunaDesenhoTabelaDesenho}
                                            src={pdfPlaceHolder}
                                            width={100}
                                            height={100}
                                        />
                                        {fezDowloadDesenho(record?.desenho?.desenhoID) ? <><FileOutlined /> <CheckOutlined style={{ color: ' rgb(40 167 143)' }} /></> : <FileOutlined />}</Button>

                                </div>
                            </Tooltip>
                        </> :
                        <>
                            <Tooltip placement='top' overlay={textToolTipBaixar}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button className={fezDowloadDesenho(record?.desenho?.desenhoID) ? style.buttonAberto : style.buttonAbrir}
                                        onClick={(e: any) => { baixarArquivoDesenho(record.key, record.desenho.contentType, record.desenho.nomeArquivo) }}
                                    >
                                        <img
                                            className={style.colunaDesenhoTabelaDesenho}
                                            src={`data:${record.desenho.contentType};base64,${record.desenho.base64}`}
                                            width={100}
                                            height={100}

                                        />
                                        {fezDowloadDesenho(record?.desenho?.desenhoID) ? <><FileOutlined /> <CheckOutlined style={{ color: ' rgb(40 167 143)' }} /></> : <FileOutlined />}</Button>

                                </div>
                            </Tooltip>
                        </>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button className={fezDowloadDiario(record?.desenho?.desenhoID) ? style.buttonAberto : style.buttonAbrir} onClick={(e: any) => { baixarArquivoDiarioDeBordo(record.key, record.diarioDeBordo.contentType, record.diarioDeBordo.nomeArquivo) }} style={{ marginTop: '10px', fontSize: '12px' }}>Baixar diário de bordo &nbsp;
                            {fezDowloadDiario(record?.desenho?.desenhoID) ? <><FileOutlined /> <CheckOutlined style={{ color: ' rgb(40 167 143)' }} /></> : <FileOutlined />}</Button>
                    </div>

                </>
            ),
            width: '20%'
        },
        {
            title: 'Situação',
            dataIndex: '',
            align: 'center',
            render: (record: any) => (<RenderSituacao record={record}/>),
            width: '20%',
        },
        {
            title: 'Ações',
            dataIndex: '',
            align: 'center',
            render: (record: any) => (

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {record?.desenho?.historicoProcessos[0]?.situacaoSelecaoENUM == 'ASelecionar' ?
                        <>
                            <Popconfirm title="Dejesa aprovar esse desenho?"
                                visible={visibilityConfirmAprovarDesenho} onConfirm={() => aprovarDesenho(record?.desenho?.desenhoID)}
                                onCancel={() => { setVisibilityConfirmAprovarDesenho(false) }}>
                                <Tooltip placement='top' title="É necessário realizar o download do diário de bordo e do desenho." >
                                    <Button
                                        style={{
                                            borderRadius: '0px', fontWeight: 'normal',
                                            height: '21.98px', padding: '0', paddingLeft: 10, paddingRight: 10
                                        }}
                                        disabled={!(fezDowloadDesenho(record?.desenho?.desenhoID) && 
                                            fezDowloadDiario(record?.desenho?.desenhoID))}
                                        onClick={() => setVisibilityConfirmAprovarDesenho(true)}
                                        loading={loading} type="primary" className={Styles.finalizar} htmlType="submit">
                                        <div style={{ fontWeight: 'normal' }}>Aprovar</div>
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip placement='top' title="É necessário realizar o download do diário de bordo e do desenho." >
                                <Button loading={loading} danger
                                    type='primary'
                                    style={{
                                        height: '21.98px', padding: 0, paddingLeft: 10, paddingRight: 10, borderRadius: '0px'
                                    }}
                                    disabled={!(fezDowloadDesenho(record?.desenho?.desenhoID) && 
                                        fezDowloadDiario(record?.desenho?.desenhoID))}
                                    onClick={() => { desclassificarDesenho(record?.desenho?.desenhoID) }}>

                                    <div style={{ fontWeight: 'normal' }}>Desclassificar</div>
                                </Button>
                            </Tooltip>
                        </>
                        : <></>}
                </div>
            ),
            width: '15%'
        }
    ]

    const aprovarDesenho = async (desenhoID: number) => {
        if (!fezDowloadDesenho(desenhoID) || !fezDowloadDiario(desenhoID)) {
            message.error("É necessÉ necessário realizar o download do diário de bordo e do desenho.")
        }
        else {
            await HistoricoProcessoService.getByDesenhoID(desenhoID).then((resp) => {
                let pessoa = JSON.parse(sessionStorage.getItem('usuarioLogado') || '')
                setLoading(true);
                resp.data.cpfSelecao = pessoa.cpf
                resp.data.situacaoSelecaoENUM = "Aprovado"
                resp.data.situacaoAvaliacaoENUM = "Avaliar"
                HistoricoProcessoService.put(resp?.data).then(() => {
                    consultarDesenhos();
                    message.success("Desenho aprovado com sucesso")
                })
                    .finally(() => setLoading(false))
            }).finally(() => { setVisibilityConfirmAprovarDesenho(false) })
        }
    }
    const desclassificarDesenho = (desenhoID: number) => {
        if (!fezDowloadDesenho(desenhoID) || !fezDowloadDiario(desenhoID)) {
            message.error("É necessÉ necessário realizar o download do diário de bordo e do desenho.")
        }
        else {
            setVisible(true), setDesenhoTabela(desenhoID)
        }
    }

    const baixarArquivoDesenho = (desenhoID: number, contentType: string, nomeArquivo: string) => {
        setLoadingArquivos(true);

        DesenhoService.downloadDesenhoBase64ById(desenhoID)
            .then((response: any) => {

                if (response !== null && contentType != null) {
                    var link = document.createElement('a');
                    link.innerHTML = 'Download file';
                    link.download = nomeArquivo;
                    link.href = 'data:' + contentType + ';base64,' + response?.result
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err: any) => {
                message.error('Falha na requisição')

            }).finally(() => {
                setLoadingArquivos(false);
                setDowloadDesenho({desenhoID:desenhoID,fezDownload:true});                
            })

    }
    const baixarArquivoDiarioDeBordo = (diarioID: number, contentType: string, nomeArquivo: string) => {
        setLoadingArquivos(true);

        DesenhoService.downloadDiarioBase64ById(diarioID)
            .then((response: any) => {

                if (response !== null && contentType != null) {
                    var link = document.createElement('a');
                    link.innerHTML = 'Download file';
                    link.download = nomeArquivo;
                    link.href = 'data:' + contentType + ';base64,' + response?.result
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err: any) => {
                message.error('Falha na requisição')

            }).finally(() => {
                setLoadingArquivos(false);                
                setDowloadDiario({desenhoID:diarioID,fezDownload:true});                           
            })

    }

    const handleUploadDesenho = (e: any) => {
        setFileDesenho(e.fileList[0]);
    }

    const handleUploadDiarioDeBordo = (e: any) => {
        setFileDiarioDeBordo(e.fileList[0]);
    }


    const onFinish = (values: any) => {


    };

    const selecionarModalidade = (modalidadeID: any) => {
        var modalidade = modalidadeDesenho.find((e: any) => e.modalidadeDesenhoID == modalidadeID)

        setModalidadeDesenhoSelecionada(modalidade);

        let ContentTypes = modalidade.contentTypes;

        let arquivo = ContentTypes.split("/");

        setTextoDiario(<span style={{ fontSize: '15px' }}>&#8226; Instrumento utilizado para registrar os acontecimentos mais importantes no desenvolvimento de
            suas atividades.  Diário de bordo, é um documento com reflexões e comentários sobre o andamento do trabalho que se está desenvolvendo. (Arquivo no formato PDF)</span>)
        setTextoUpload(<span style={{ fontSize: '15px' }}>&#8226; Formato de arquivo permitido: {arquivo[1]}</span>)

    };

    return (
        <Spin spinning={loading}>
            <ModalDesclassificacaoSelecaoComponent concursoID={id} desenhoID={desenhoTabela} setVisivelFalse={() => setVisible(false)} visivel={visible} consultarDesenhos={consultarDesenhos} />
            <div className={Styles.formulario} style={{}}>
                <div className={Styles.dadosParticipante} style={{ margin: '0', marginBottom: '-25px' }}>
                    <h4 className={Styles.tituloResponsavel} ><b>Envio dos desenhos e diário de bordo</b></h4>
                </div>

                <Form

                    form={form}
                    {...layout}
                    ref={formRef}
                    name="control-ref" >

                    <Form.Item name="modalidadeDesenho"
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                        style={{ fontWeight: "bold" }}
                    >

                    </Form.Item>

                    {modalidadeDesenhoSelecionada ? <Row className={`${Styles.flex} ${Styles.justifyContentSpaceBetween} ${Styles.alignItemsCenter}`}>
                        <Col span={8}>
                            <Form.Item name="arquivo"
                                rules={[{ required: true, message: 'Por favor anexe uma arquivo com o desenho!' }]}
                            >
                                <Upload beforeUpload={(e => {
                                    var valid = e.type != "" && modalidadeDesenhoSelecionada.contentTypes.includes(e.type);
                                    if (!valid) {
                                        message.error(`${e.name} não esta em um formato permitido`)
                                        return Upload.LIST_IGNORE;
                                    }

                                    setTypePermitido(valid)
                                    return !valid;
                                })}
                                    showUploadList={fileDesenho ? true : false} onChange={(e) => { handleUploadDesenho(e) }} name="arquivo" maxCount={1} className={style.UploadName}>
                                    <Tooltip overlay={textoUpload} placement="bottomLeft">
                                        <Button ghost type="primary" icon={<UploadOutlined />}>Anexar desenho</Button>
                                    </Tooltip>
                                </Upload>
                            </Form.Item>
                        </Col>

                        <Col span={10} offset={6}>
                            <Form.Item
                                rules={[{ required: true, message: 'Por favor anexe uma arquivo com o diário de bordo!', }]}
                                name="diarioDeBordo" >
                                <Upload beforeUpload={e => {
                                    var valid = e.type != "" && e.type == 'application/pdf';
                                    if (!valid) {
                                        message.error(`${e.name} não esta em um formato permitido`)
                                        return Upload.LIST_IGNORE;
                                    }

                                    return !valid;
                                }}
                                    showUploadList={fileDiarioDeBordo ? true : false} onChange={(e) => handleUploadDiarioDeBordo(e)} name="diarioDeBordo" maxCount={1} className={style.UploadName}>
                                    <Tooltip overlay={textoDiario} placement="bottomLeft">
                                        <Button ghost type="primary" icon={<UploadOutlined />}>Anexar diário de bordo</Button>
                                    </Tooltip>
                                </Upload>
                            </Form.Item>
                        </Col>

                    </Row> : null}


                </Form>
                <Spin spinning={loadingArquivos}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Table 
                            locale={{emptyText:<Empty description="Sem registro!" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}}
                            dataSource={dados} 
                            columns={colunas} 
                            pagination={{ pageSize: 1 }} 
                            scroll={{ x: 100 }} 
                            style={{ width: '70%' }} 
                        />
                    </div>
                </Spin>
                <div className={Styles.buttonsArea} style={{ marginLeft: '0' }}>
                    <Button type="primary" className={Styles.proximo} onClick={voltarTab} >
                        Voltar
                    </Button>



                </div>
                <br />

            </div>
        </Spin>
    )
}

export default FormUpload;
