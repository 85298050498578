import React, { useState, useEffect } from 'react';
import { Row, Col, message, DatePicker } from 'antd';
import { Table, Button, Form, Select } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import ButtonDeleteFiltro from './ButtonDeleteFiltro/buttonDeleteFiltro-component';



interface FiltroConsultaProps {
    removerFiltro?: any
    label: string,
    name: string,
    component: any,
    required:boolean

}

const FiltroConsulta: React.FC<FiltroConsultaProps> = (props) => {

    return (
        <Col span={6}>
            <Row>
                <Col span={20}>
                    <Form.Item
                       rules={[{ required: props.required, message: 'Campo obrigatório!' }]}

                        label={props.label}
                        name={props.name}>
                        {props.component}
                    </Form.Item>
                </Col>
                {props.required?null:
                <Col span={4}>
                    <Form.Item label="&nbsp;">
                    <ButtonDeleteFiltro nomeFiltro={props.name} removerFiltro={props.removerFiltro}/>
                    </Form.Item>
                </Col>
                }
            </Row>
        </Col>
    )
}

export default FiltroConsulta;

